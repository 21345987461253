"use client";

import { fetchUserWalletBalance } from "@hopper-b2b/api";
import {
  HeaderNavigationMenuItemType,
  HeaderNavigationMoreCtaType,
  PATH_HOME,
  PATH_TRIPS,
  apiConfig,
  getLanguagePath,
  supportedLanguageNames,
} from "@hopper-b2b/hopper-utils";
import { TranslationLanguage, useI18nContext } from "@hopper-b2b/i18n";
import { FiatPrice } from "@hopper-b2b/types";
import {
  isAtHotelBrand,
  useEnableWallet,
  useIsExtraSmallScreen,
  useIsMediumScreen,
  useIsSmallScreen,
  useOpenAuthModal,
  useSessionContext,
} from "@hopper-b2b/utilities";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  Toolbar,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { HopperAssets } from "@hopper-assets";
import { ReactComponent as HopperLogoLarge } from "../../../assets/icons/hopper_logo_large.svg";
import { ReactComponent as HopperLogoSmall } from "../../../assets/icons/hopper_logo_small.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as HamburgerIcon } from "../../../assets/icons/hamburger.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

import SignInHopperAvatar from "../../../assets/icons/signin-bunny-avatar.png";
import { RootProps } from "../../Root";
import {
  HEADER_MENU_POPPER_ID,
  HeaderMenu,
  HeaderMenuItemType,
} from "../HeaderMenu";
import { useLanguageContext } from "../LanguageContext";
import { LanguageSelect } from "../LanguageSelect";
import { NavigationMenu } from "../NavigationMenu";
import { NavigationMenuItem } from "../NavigationMenuItem";
import styles from "./Header.module.scss";
import { HappyBunny } from "@hopper-b2b/ui";
import { useAuthProvider } from "@hopper-b2b/hopper-auth";
import { AtHotelXHopperLogo, Image } from "@hopper-b2b/ui-core";

export type HeaderProps = Omit<
  RootProps,
  "googleIdentityClientId" | "onLanguageSelect"
>;

export const Header = ({
  headerNavigation,
  showLanguageSelect,
  rightActions = [],
}: HeaderProps) => {
  const sessionContext = useSessionContext();
  const { t, formatFiatCurrency } = useI18nContext();

  const { state } = useAuthProvider();

  const isSignedIn = state?.sessionInfo?.userScope?.isSignedIn;

  const openAuthModal = useOpenAuthModal();

  const {
    supportedLanguages,
    currentLang,
    onChange: onLanguageSelect,
  } = useLanguageContext();

  const [walletBalance, setWalletBalance] = useState<FiatPrice | undefined>(
    undefined
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const menuOpen = !!anchorEl;

  useEffect(() => {
    if (sessionContext?.isLoggedIn) {
      fetchUserWalletBalance(apiConfig).then((res) => {
        setWalletBalance(res.creditBalance.fiat);
      });
    } else {
      setWalletBalance(undefined);
    }
  }, [sessionContext?.isLoggedIn]);

  const homePath =
    !showLanguageSelect || (showLanguageSelect && onLanguageSelect)
      ? PATH_HOME
      : `${PATH_HOME}${currentLang ?? ""}`;

  const isExtraSmall = useIsExtraSmallScreen();
  const isSmall = useIsSmallScreen();
  const isMedium = useIsMediumScreen();

  const enableWallet = useEnableWallet();

  const showFindBookingAction = !isSmall;
  const showWalletAction = !isSmall && walletBalance && enableWallet;
  const showRightActions =
    !isExtraSmall && rightActions && rightActions.length > 0;

  const walletBalanceString =
    walletBalance && formatFiatCurrency(walletBalance);

  const showNavigationMenu =
    !isMedium && headerNavigation && headerNavigation.navigation;

  const showMoreActions =
    !isMedium && headerNavigation && headerNavigation.more;
  const showCtaActions = headerNavigation && headerNavigation.cta;
  const showLanguageAction = !isSmall && showLanguageSelect;

  // Construct Account Menu Items
  const accountMenuItems: HeaderMenuItemType[] = [];
  if (
    (!showFindBookingAction && !sessionContext?.isLoggedIn) ||
    !showRightActions
  ) {
    const accountMenuChildren = [];
    if (!showFindBookingAction && !sessionContext?.isLoggedIn) {
      accountMenuChildren.push({ text: t?.("findBooking"), link: PATH_TRIPS });
    }
    if (!showWalletAction && enableWallet && walletBalance) {
      accountMenuChildren.push({
        text: walletBalanceString,
        link: "",
        disabled: true,
      });
    }
    if (!showRightActions) {
      accountMenuChildren.push(...rightActions);
    }
    accountMenuItems.push({
      text: " ",
      children: accountMenuChildren,
    });
  }

  // Construct Navigation Menu Items
  const navigationMenuItems: HeaderMenuItemType[] = !showNavigationMenu
    ? headerNavigation?.navigation ?? []
    : [];

  // Construct More Menu Items
  const moreMenuItems: HeaderMenuItemType[] = [];
  if (
    (!showMoreActions && headerNavigation?.more) ||
    (!showCtaActions && headerNavigation?.cta)
  ) {
    const moreMenuChildren = [];
    if (!showMoreActions && headerNavigation?.more) {
      moreMenuChildren.push(
        ...headerNavigation.more.map((item: HeaderNavigationMoreCtaType) => ({
          text: item.text,
          link: item.link,
        }))
      );
    }

    if (!showCtaActions && headerNavigation?.cta) {
      moreMenuChildren.push({
        text: headerNavigation.cta.headline,
        link: headerNavigation.cta.link,
      });
    }
    moreMenuItems.push({
      text: t?.("moreHeader"),
      children: moreMenuChildren,
    });
  }

  // Construct Language Menu Items
  const languageMenuItems: HeaderMenuItemType[] =
    !showLanguageAction && showLanguageSelect
      ? [
          {
            text: t?.("languages"),
            children: supportedLanguages
              ?.map(
                (lang: TranslationLanguage): HeaderMenuItemType | undefined => {
                  const text = supportedLanguageNames[lang];

                  if (!text) return null;

                  return onLanguageSelect
                    ? {
                        text,
                        onClick: onLanguageSelect,
                      }
                    : {
                        text,
                        link: getLanguagePath(lang),
                      };
                }
              )
              .filter(Boolean),
          },
        ]
      : [];

  const headerMenuItems = [
    ...accountMenuItems,
    ...navigationMenuItems,
    ...moreMenuItems,
    ...languageMenuItems,
  ];

  const handleClick = (event) => {
    const header = event.currentTarget.closest("header");

    setAnchorEl(anchorEl ? null : isSmall ? header : event.currentTarget);
  };

  const onMenuClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleItemClick = useCallback(
    () => (window.location.href = `${PATH_HOME}${PATH_TRIPS}`),
    []
  );

  return (
    <>
      <AppBar
        position="sticky"
        variant="elevation"
        elevation={menuOpen && isSmall ? 0 : 1}
        color="inherit"
        className={styles.header}
      >
        <Container maxWidth="xl" className={styles.fullHeight}>
          <Toolbar className={styles.fullHeight}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className={styles.fullHeight}
            >
              <Grid item className={styles.logo}>
                <Link href={homePath} underline="hover">
                  {isAtHotelBrand() ? (
                    <Box
                      component={AtHotelXHopperLogo}
                      width={180}
                      height={32}
                    />
                  ) : isExtraSmall ? (
                    <HopperLogoSmall height={32} width={32} />
                  ) : (
                    <HopperLogoLarge height={32} width={120} />
                  )}
                </Link>
              </Grid>

              {isExtraSmall && <Grid item xs />}

              {(showNavigationMenu || showMoreActions || showCtaActions) && (
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    {showNavigationMenu &&
                      headerNavigation.navigation.map(
                        (item: HeaderNavigationMenuItemType, index: number) => (
                          <Grid key={item.identifier ?? index} item>
                            {item.children ? (
                              <NavigationMenu {...item} />
                            ) : (
                              <NavigationMenuItem {...item} />
                            )}
                          </Grid>
                        )
                      )}
                    {showMoreActions &&
                      headerNavigation.more.map(
                        (cta: HeaderNavigationMoreCtaType, index: number) => (
                          <Grid key={cta.identifier ?? index} item>
                            <Button id={cta.identifier} href={cta.link}>
                              {cta.text}
                            </Button>
                          </Grid>
                        )
                      )}
                    {showCtaActions && headerNavigation.cta && (
                      <Grid item>
                        <Button
                          variant={"contained"}
                          disableElevation={true}
                          color="primary"
                          href={headerNavigation.cta.link}
                        >
                          {headerNavigation.cta.headline}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item xs />

              {(showFindBookingAction ||
                showWalletAction ||
                showRightActions ||
                showLanguageAction) && (
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    {showFindBookingAction && !sessionContext?.isLoggedIn && (
                      <Grid item>
                        <Button
                          onClick={handleItemClick}
                          startIcon={<SearchIcon />}
                        >
                          {t?.("findBooking")}
                        </Button>
                      </Grid>
                    )}

                    {showWalletAction && !!walletBalance ? (
                      <Grid item>
                        <Chip
                          style={{ paddingLeft: "8px" }}
                          variant="outlined"
                          avatar={
                            <Image
                              src={
                                HopperAssets.Ds.Thumb["Entry-Point"].Wallet_2x
                              }
                            />
                          }
                          label={walletBalanceString}
                        />
                      </Grid>
                    ) : null}

                    {showRightActions &&
                      rightActions?.map((action, index) => (
                        <Grid key={index} item>
                          <Button
                            href={action.link}
                            onClick={action.onClick}
                            startIcon={action.icon}
                          >
                            {action.text}
                          </Button>
                        </Grid>
                      ))}

                    {showLanguageAction && (
                      <Grid item>
                        <LanguageSelect />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {headerMenuItems && headerMenuItems.length > 0 && (
                <Grid item className={styles.headerMenu}>
                  {!showRightActions && (
                    <Box className={styles.avatar}>
                      <img
                        onClick={isSignedIn ? handleClick : openAuthModal}
                        src={isSignedIn ? HappyBunny : SignInHopperAvatar}
                        alt=""
                        height={32}
                        width={32}
                      />
                    </Box>
                  )}
                  <IconButton
                    onClick={handleClick}
                    aria-describedby={HEADER_MENU_POPPER_ID}
                    aria-label={menuOpen ? "close menu" : "open menu"}
                    size="large"
                  >
                    {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
                  </IconButton>
                  {!isSmall && (
                    <HeaderMenu
                      open={menuOpen}
                      anchorEl={anchorEl}
                      onClose={onMenuClose}
                      headerMenuItems={headerMenuItems}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {isSmall && (
        <HeaderMenu
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={onMenuClose}
          headerMenuItems={headerMenuItems}
        />
      )}
    </>
  );
};
