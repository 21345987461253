export enum TripFilter {
  NO_FILTER = "NoFilter",
  AND_FILTER = "AndFilter",
  NON_STOP_FILTER = "NonStopFilter",
  SHORT_LAYOVER_FILTER = "ShortLayoverFilter",
  NO_LOW_COST_CARRIER_FILTER = "NoLowCostCarrierFilter",
}

export enum SliceStopCountFilter {
  DEFAULT = "DEFAULT",
  ANY_NUMBER = "ANY_NUMBER",
  NONE = "NONE",
  ONE_OR_LESS = "ONE_OR_LESS",
  TWO_OR_LESS = "TWO_OR_LESS",
}

export type FareclassShelfBrandName =
  | "basic"
  | "standard"
  | "enhanced"
  | "premium"
  | "luxury";

export interface ITimeRange {
  // note: time is measured in minutes
  min: number;
  max: number;
}

export enum FlightShopType {
  PRICE_FREEZE_PURCHASE = "pf-purchase",
  PRICE_FREEZE_EXERCISE = "pf-exercise",
  CHFAR_EXERCISE = "chfar-exercise",
  DEFAULT = "default",
}

export const FLIGHT_SHOP_TYPE = "flightShopType";

export enum FlightBookType {
  PRICE_FREEZE_PURCHASE = "pf-purchase",
  PRICE_FREEZE_EXERCISE = "pf-exercise",
  CHFAR_EXERCISE = "chfar-exercise",
  DEFAULT = "default",
}

export const FLIGHT_BOOK_TYPE = "flightBookType";
