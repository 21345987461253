import { Airport } from "@b2bportal/air-booking-api";
import {
  FiatPrice,
  Prediction,
  TripSegment,
  TripSlice,
} from "@b2bportal/air-shopping-api";
import {
  AirlineCode,
  AirportMap,
  FareDetails,
  FareSliceDetails,
  FlightInfoDetails,
  GordianSeatSegment,
  IConfirmationNumber,
  IFlightCardProps,
  IFlyerOption,
  ITripTerminus,
  MyTripsFilter,
  MyTripsModalTypes,
  RadioOption,
  TagInfo,
  TripCategory,
  TripDetails,
} from "@hopper-b2b/types";
import { TypographyProps } from "@mui/material";
import { ReactNode } from "react";

import { IActionLink } from "../ActionLinks";
import { FareDetailsCard, IFareDetailsCardProps } from "../FareDetailsCard";
import { FareDetailsSliceHeader } from "../FareDetailsSliceHeader";
import { B2BFlightCardV2 } from "../FlightCard";
import {
  FlightDetailsCard,
  IFlightDetailsCardProps,
} from "../FlightDetailsCard";
import {
  FlightDetailsSummary,
  IFlightDetailsSummaryProps,
} from "../FlightDetailsSummary";
import { FlightShopRow, IFlightShopRowProps } from "../FlightShopRow";
import { FlightSummaryRow, IFlightSummaryRowProps } from "../FlightSummaryRow";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import {
  IPassengerCountPickerProps,
  PassengerCountPicker,
} from "../PassengerCountPicker";
import { EmptySlot } from "./EmptySlot";
import { Edit } from "./components/Edit";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Slots
  extends Partial<{
    "flight-shop-loading-screen": React.ComponentType<{
      loading: boolean;
      averageLoadingTime?: number;
      className?: string;
      loadingSteps?: Array<{
        title: string;
        description?: string;
        image?: string;
      }>;
      onResetRenderLoadingSteps?: (renderLoadingSteps: boolean) => void;
    }>;
    // Flights / Air
    "fare-card-header": React.ComponentType<{ fareId: string }>;
    "fare-details-card-policy": React.ComponentType<{ fareId: string }>;
    "flight-shop-review-itinerary-policy": React.ComponentType<{
      fareId: string;
    }>;
    "flight-shop-review-itinerary-large-header": React.ComponentType;
    "flight-shop-transition-loader": React.ComponentType<{
      resetRenderLoader: () => void;
    }>;
    "flight-search-loader": React.ComponentType<any>;
    "mobile-flight-filter-title": React.ComponentType<{ title: string }>;
    "mobile-flight-number-filter-selection": React.ComponentType<{
      options: { label: AirlineCode; value: AirlineCode }[];
      onChange: (value: string) => void;
      value: string;
      flightNumberOptions: JSX.Element[];
    }>;
    "mobile-flight-price-prediction-content": React.ComponentType<{
      prediction: Prediction;
    }>;
    "price-breakdown-content-policy": React.ComponentType;
    "price-range-tag": React.ComponentType<{ value: string; index: number }>;
    "flight-shop-list-mobile-card": React.ComponentType<IFlightCardProps>;
    "flight-shop-list-expanded-section": React.ComponentType<IFlightDetailsCardProps>;
    "fare-details-slice-header": React.ComponentType<{
      origin: string;
      destination?: string;
      date: Date;
      airline: string;
    }>;
    "flight-checkout-cta-section": React.ComponentType<{
      onClick: () => void;
      buttonDisabled?: boolean;
    }>;
    "flight-shop-cta-section": React.ComponentType<{
      totalPrice: FiatPrice;
      discountedPrice: FiatPrice;
      tripCategory: TripCategory;
      onClick: () => void;
    }>;
    "flight-calendar-date-label": React.ComponentType<{
      label: string;
      icon: string;
      dateSelected: string;
      warning?: boolean;
    }>;
    // Virtual Interline
    "fare-details-card": React.ComponentType<IFareDetailsCardProps>;
    "search-calendar-banner": React.ComponentType;
    "flight-details-summary": React.ComponentType<IFlightDetailsSummaryProps>;
    "flight-shop-row": React.ComponentType<IFlightShopRowProps>;
    "flight-shop-review-itinerary-root-background": React.ComponentType<unknown>;
    "missed-connection-protection-outlined-button": React.ComponentType<unknown>;
    "non-refundable-banner": React.ComponentType<unknown>;
    "traveler-id-match-banner": React.ComponentType;
    "flight-summary-row": React.ComponentType<IFlightSummaryRowProps>;
    "mobile-flight-details-modal": React.ComponentType<{
      isOutgoing: boolean;
      className?: string;
      departureTime?: string;
      segments: TripSegment[];
      plusDays?: number;
      fareSlice?: FareSliceDetails;
      fareDetails: FareDetails[];
      tripDetails: TripDetails;
      onClose: () => void;
      onChangeFlight?: (isOutbound: boolean) => void;
      locationName: string;
    }>;
    "mobile-flight-summary-row": React.ComponentType<{
      tripSlice: TripSlice;
      airports: AirportMap;
      className: string;
      isReturn?: boolean;
      onClick?: () => void;
      hasSummaryPanelBorder?: boolean;
    }>;
    "trip-review-combination-banner": React.ComponentType<{
      tripDetails: TripDetails;
      airports: AirportMap;
      isMobile: boolean;
      fareDetails: FareDetails;
    }>;
    "mobile-flight-details-expanded": React.ComponentType<{
      fareDetails: FareDetails[];
      isOutgoing: boolean;
      tripDetails: TripDetails;
      onFareClick: (fareId: string) => void;
      className?: string;
      departureTime?: string;
      segments: TripSegment[];
      plusDays?: number;
      header?: JSX.Element;
      fareSlice?: FareSliceDetails;
      onChange?: () => void;
      onMobileContinue?: () => void;
    }>;
    "mobile-locations-button": React.ComponentType<{
      handleEditLocation: () => void;
      locationLabel: string;
      passengerCount: number;
    }>;
    // Hotels / Lodging
    "lodging-card-policy": React.ComponentType<{
      lodgingId: string;
      autoMarginLeft?: boolean;
    }>;
    // Common
    edit: React.ComponentType<{
      className?: string;
      onClick: () => void;
    }>;
    "add-traveler-form-select": React.ComponentType<{
      options: RadioOption[];
      label: string;
      setOption: (option: RadioOption["value"]) => void;
      selected: RadioOption;
      className: string;
    }>;
    // Checkout
    "passenger-frequent-flyer-select": React.ComponentType<{
      selected: IFlyerOption | null;
      options: IFlyerOption[];
      setOption: (option: IFlyerOption) => void;
    }>;
    "passenger-required-assistance-select": React.ComponentType<{
      selected: string;
      options: JSX.Element;
    }>;
    "passenger-passport-country-select": React.ComponentType<{
      selected: RadioOption["value"] | null;
      options: RadioOption[];
      setOption: (option: RadioOption["value"]) => void;
    }>;
    "flight-review-mixed-cabin-tooltip": React.ComponentType<{
      showDivider?: boolean;
    }>;
    "flight-shop-summary-panel": React.ComponentType<{
      tripSlice: TripSlice;
      airports: AirportMap;
    }>;
    "traveler-select-row": React.ComponentType<{
      className?: string;
      value: string;
      selectRowType: string;
      isSelected: boolean;
      onClickEdit: () => void;
      onClickSelect?: () => void;
      buttonClassName?: string;
      disabled?: boolean;
      isMobile?: boolean;
    }>;
    "selected-seat-card": React.ComponentType<{
      selectedSeats: GordianSeatSegment;
      airports: { [key: string]: Airport | undefined };
      idx: number;
      onEditClick?: (idx: number) => void;
      isMobile?: boolean;
    }>;
    "trip-details-links": React.ComponentType<{
      actions: IActionLink[];
    }>;
    "trip-details-summary": React.ComponentType<{
      confirmationNumbers: IConfirmationNumber[];
      onClick: (modalType: MyTripsModalTypes) => void;
      flightInfoDetails: FlightInfoDetails;
      title: string;
      titleTag?: TagInfo | undefined;
      isMultiAirline?: boolean;
    }>;
    "empty-trips-list": React.ComponentType<{
      className?: string;
      tripsFilter: MyTripsFilter;
    }>;
    "self-serve-cancellation-error-screen": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      onBack?: () => void;
      className?: string;
    }>;
    "exchange-loader": React.ComponentType<{
      open: boolean;
      message?: string;
      secondaryMessage?: string;
      className?: string;
      onClose?: () => void;
    }>;
    "trips-list-loading-screen": React.ComponentType<{
      open: boolean;
      message?: string;
      secondaryMessage?: string;
      className?: string;
      onClose?: () => void;
    }>;
    "exchange-error-screen": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      onBack?: () => void;
      className?: string;
      modal?: boolean;
      header?: string | JSX.Element;
    }>;
    "self-serve-cancellation-flight-info": React.ComponentType<{
      actions?: ReactNode | ReactNode[];
      disclaimer?: string;
      infoItems: string[];
      subtitle?: ReactNode;
      subtitle2?: ReactNode;
      subtitleProps?: TypographyProps;
      tcHelpProps?: TypographyProps;
      tcHelpText?: ReactNode;
      title?: ReactNode;
      titleProps?: TypographyProps;
      primaryOnClick?: () => void;
      primaryButtonText?: string;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string;
      modal?: boolean;
      contactAirline?: boolean;
    }>;
    "exchange-contact-airline-screen": React.ComponentType<{
      actions?: ReactNode | ReactNode[];
      disclaimer?: string;
      infoItems: string[];
      subtitle?: ReactNode;
      subtitle2?: ReactNode;
      subtitleProps?: TypographyProps;
      tcHelpProps?: TypographyProps;
      tcHelpText?: ReactNode;
      title?: ReactNode;
      titleProps?: TypographyProps;
      primaryOnClick?: () => void;
      primaryButtonText?: string;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string;
      modal?: boolean;
      contactAirline?: boolean;
    }>;
    "exchange-landing-screen": React.ComponentType<{
      onContinue: () => void;
      origin: ITripTerminus;
      destination: ITripTerminus;
      handleChangeDates: () => void;
      handleChangeLocations: () => void;
      returnDate: Date;
      departureDate: Date;
      policyTitle: string | JSX.Element;
    }>;
    "lodging-search-mobile-guest-modal": React.ComponentType<IPassengerCountPickerProps>;
    "hotel-shop-filters-modal": React.ComponentType<{
      open: boolean;
      filters: any; // TODO: Update lodging type - iLodgingFilterState
      filterBoundaries: any; // TODO: Update lodging type - LodgingsFilterBoundaries
      closeFiltersModal: () => void;
      onFilterPriceRangeChange(nextValue: Pick<any, "min" | "max">): void; // TODO: Update lodging type - PriceRange
      onStarRatingChange(nextValue: number[]): void;
      onUserRatingChange(nextValue: number): void;
      onAmenitiesChange: (value: string[]) => void;
      onAmenitiesTypeChange: (value: boolean) => void;
      onFreeCancellationChange: (value: boolean) => void;
      onResetFilters: () => void;
      sort?: any; // TODO: Update lodging type - SortOption
      setSort?: (value: string) => void;
    }>;
    "flight-search-mobile-header-left-content": React.ComponentType<{
      className: string;
      onClick: () => void;
      content: ReactNode;
      searchStateStep?: number;
    }>;
    "trips-confirmation-modal-copy-and-go-button": React.ComponentType<{
      className?: string;
      locator: string;
      redirectUrl: string;
      onClick: () => void;
      "aria-label": string;
    }>;
    "trips-error-modal-content": React.ComponentType<{
      title: string;
      subtitle: string;
      primaryOnClick: () => void;
      primaryButtonText: string | JSX.Element;
      secondaryOnClick?: () => void;
      secondaryButtonText?: string | JSX.Element;
      onBack?: () => void;
      className?: string;
    }>;
  }> {}

export type SlotProps<T extends keyof Slots> = React.ComponentProps<Slots[T]>;

export const defaultSlots: Slots = {
  "fare-card-header": EmptySlot,
  edit: Edit,
  "flight-shop-list-mobile-card": B2BFlightCardV2,
  "flight-shop-list-expanded-section": FlightDetailsCard,
  "fare-details-slice-header": FareDetailsSliceHeader,
  "fare-details-card": FareDetailsCard,
  "flight-details-summary": FlightDetailsSummary,
  "flight-review-mixed-cabin-tooltip": MixedCabinToolTip,
  "flight-shop-row": FlightShopRow,
  "flight-summary-row": FlightSummaryRow,
  "trip-review-combination-banner": EmptySlot,
  "flight-shop-review-itinerary-root-background": EmptySlot,
  "missed-connection-protection-outlined-button": EmptySlot,
  "non-refundable-banner": EmptySlot,
  "traveler-id-match-banner": EmptySlot,
  "mobile-flight-details-expanded": EmptySlot,
  "mobile-locations-button": EmptySlot,
  "lodging-search-mobile-guest-modal": PassengerCountPicker,
  "search-calendar-banner": EmptySlot,
};
