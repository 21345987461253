import parse from "libphonenumber-js";
import { AuthMachineContext } from "./context";
import { StateValue } from "xstate";
import { getChildState, getParentState } from "@hopper-b2b/checkout";

export const getPhoneNumber = ({
  context,
}: {
  context: AuthMachineContext;
}) => {
  return parse(context.phoneNumber).format("E.164");
};

export const getFormattedPhoneNumber = ({
  context,
}: {
  context: AuthMachineContext;
}) => {
  return parse(context.phoneNumber).format("INTERNATIONAL");
};

export const getEmail = ({ context }: { context: AuthMachineContext }) =>
  context.email;

export const getError = ({ context }: { context: AuthMachineContext }) =>
  context.error;

export const getMissingFields = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.missingFields;

export const getVerificationAlternatives = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.verificationAlternative;

export const getAuthStateToken = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.authStateToken;

export const getMatchingUserMatchCondition = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.userMatch?.matchCondition;

export const getMatchingUserVerifiedAuthMethods = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.userMatch?.verifiedAuthMethods;

export const hasVerificationAlternative = ({
  context,
}: {
  context: AuthMachineContext;
}) =>
  context.verificationAlternative?.redactedEmail ||
  context.verificationAlternative?.redactedPhone;

export const getPreviousUserTrackingToken = ({
  context,
}: {
  context: AuthMachineContext;
}) => context.previousUserTrackingToken;

export const getAuthType = ({ context }: { context: AuthMachineContext }) =>
  context.authType;

export const getIsLoading = (state: StateValue): boolean => {
  const parentState = getParentState(state);
  const childState = getChildState(state);

  switch (parentState) {
    case "landing":
      return false;
    case "googleLogin":
      return true;
    case "phoneLogin":
      switch (childState) {
        case "sendCodeToPhone":
        case "resend":
        case "verify":
          return true;
        case "verifyCodeForm":
        case "additionalOptions":
          return false;
        default:
          return false;
      }
    case "emailLogin":
      switch (childState) {
        case "sendCodeToEmail":
        case "resend":
        case "verify":
          return true;
        case "verifyCodeForm":
        case "additionalOptions":
          return false;
        default:
          return false;
      }
    case "merge":
      switch (childState) {
        case "sendCode":
        case "verify":
        case "resend":
          return true;
        case "idle":
        case "verifyForm":
          return false;
        default:
          return false;
      }
    case "finalize":
      return false;
    case "success":
      return false;
    case "error":
      return false;
    default:
      return false;
  }
};
