import { colors, typography, spacing } from "./primitives";
export const tokens = {
  primary: {
    main: colors.blue[50],
    contrast: colors.gray.white,
  },
  brand: {
    main: colors.coral[50],
    contrast: colors.gray.white,
  },
  surface: {
    main: colors.gray.white,
    mainHover: "#FAFDFF",
    primaryResting: colors.blue[50],
    primaryHover: "#0170D1",
    primaryPressed: "#016AC7",
    primaryTintResting: colors.blue[5],
    primaryTintHover: "#F0FBFC",
    primaryTintPressed: "#EDF9FA",
    borderDivider: colors.gray[30],
    errorBorder: colors.red[50],
    overlayPhoto: "rgba(0, 0, 0, 0.50)",
    error: colors.red[5],
    atHotelBranded: "#17803C",
    discountTint: colors.green[10],
  },
  background: {
    main: colors.gray[10],
  },
  error: {
    main: colors.red[50],
  },
  text: {
    primary: colors.gray[100],
    secondary: colors.gray[70],
    tertiary: colors.gray[40],
    quaternary: colors.gray[30],
    disabled: "rgba(0, 0, 0, 0.3000)",
    link: colors.blue[60],
    discount: colors.green[60],
    priceAlert: colors.red[50],
  },
  component: {
    control: {
      surfaceResting: colors.gray.white,
      surfaceHover: "#FAFAFA",
      surfacePressed: "#F5F5F5",
      surfaceFocused: colors.gray.white,
      surfaceDisabled: "rgba(0, 0, 0, 0.0900)",
      borderResting: colors.gray[100],
      borderDisabled: "rgba(0, 0, 0, 0.2000)",
      backgroundResting: colors.gray[30],
    },
    input: {
      surfaceResting: colors.gray.white,
      surfaceHover: "#FCFCFC",
      surfacePressed: "#F7F7F7",
      surfaceFocused: colors.gray.white,
      surfaceError: colors.red[5],
      surfaceDisabled: "#F0F0F0",
      borderResting: colors.gray[40],
      borderFocused: colors.blue[50],
      borderError: colors.red[50],
    },
    searchInput: {
      surfaceResting: colors.gray.white,
      surfaceHover: "#FAFAFA",
      surfaceFocused: "#F2F2F2",
    },
    calendar: {
      surfaceGood: "#e3f8df",
      textGood: "#176700",
      surfaceAverage: "#fff6cf",
      textAverage: "#924e01",
      surfaceBad: "#ffe9d6",
      textBad: "#9f1700",
      surfaceTerrible: "#ffdbe0",
      textTerrible: "#79064e",
      surfaceDefault: "#f6f6f6",
      textDefault: colors.gray[100],
      surfaceSelected: colors.blue[50],
      textSelected: colors.gray.white,
      surfaceRange: colors.blue[5],
      textRange: colors.blue[60],
    },
  },
  cornerRadius: {
    radiusXs: "2px",
    radiusSm: "4px",
    radiusMd: "8px",
    radiusLg: "12px",
    radiusXl: "16px",
    radius2Xl: "24px",
  },
  responsive: {
    desktop: {
      fontSize: {
        displayLg: typography.fontSize[13],
        displayMd: typography.fontSize[11],
        displaySm: typography.fontSize[9],
        headlineLg: typography.fontSize[9],
        headlineMd: typography.fontSize[7],
        headlineSm: typography.fontSize[6],
      },
      fontLineHeight: {
        displayLg: typography.fontLineHeight[13],
        displayMd: typography.fontLineHeight[11],
        displaySm: typography.fontLineHeight[9],
        headlineLg: typography.fontLineHeight[9],
        headlineMd: typography.fontLineHeight[7],
        headlineSm: typography.fontLineHeight[6],
      },
    },
    tablet: {
      fontSize: {
        displayLg: typography.fontSize[11],
        displayMd: typography.fontSize[10],
        displaySm: typography.fontSize[9],
        headlineLg: typography.fontSize[8],
        headlineMd: typography.fontSize[6],
        headlineSm: typography.fontSize[5],
      },
      fontLineHeight: {
        displayLg: typography.fontLineHeight[11],
        displayMd: typography.fontLineHeight[10],
        displaySm: typography.fontLineHeight[9],
        headlineLg: typography.fontLineHeight[8],
        headlineMd: typography.fontLineHeight[6],
        headlineSm: typography.fontLineHeight[5],
      },
    },
    mobile: {
      fontSize: {
        displayLg: typography.fontSize[9],
        displayMd: typography.fontSize[8],
        displaySm: typography.fontSize[7],
        headlineLg: typography.fontSize[7],
        headlineMd: typography.fontSize[6],
        headlineSm: typography.fontSize[5],
      },
      fontLineHeight: {
        displayLg: typography.fontLineHeight[9],
        displayMd: typography.fontLineHeight[8],
        displaySm: typography.fontLineHeight[7],
        headlineLg: typography.fontLineHeight[7],
        headlineMd: typography.fontLineHeight[6],
        headlineSm: typography.fontLineHeight[5],
      },
    },
  },
  container: {
    padding: {
      mobile: 150,
      tablet: 300,
      desktop: 400,
    },
    paddingFluid: {
      mobile: 0,
      tablet: 300,
      desktop: 400,
    },
    paddingFluidAlt: {
      mobile: 150,
      tablet: 0,
      desktop: 0,
    },
    maxWidth: 1100,
  },
  header: {
    height: {
      mobile: 48,
      tablet: 60,
      desktop: 80,
    },
  },
  colors,
  typography,
  spacing,
};
