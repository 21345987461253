import { AxiosRequestConfig } from "axios";
import { createContext, PropsWithChildren, useContext } from "react";

export type IDistributedWebComponentContextProps = {
  isExternalHost: boolean;
  requestConfig: Pick<AxiosRequestConfig, "baseURL" | "withCredentials">;
};

export interface IDistributedWebComponentContextProvider {
  distributedWCContext: IDistributedWebComponentContextProps;
}
export const defaultDistributedWebComponentContext: IDistributedWebComponentContextProps =
  {
    isExternalHost: false,
    requestConfig: {},
  };

export const DistributedWebComponentContext =
  createContext<IDistributedWebComponentContextProps>(
    defaultDistributedWebComponentContext
  );

export const useDistributedWebComponentContext = () => {
  const ctx = useContext(DistributedWebComponentContext);
  if (ctx === undefined)
    throw new Error(
      `must be used within a DistributedWebComponentContextProvider`
    );
  return { ...ctx };
};

export const useIsExternalHost = () => {
  const { isExternalHost, requestConfig } = useDistributedWebComponentContext();
  return { isExternalHost, requestConfig };
};

export const DistributedWebComponentContextProvider = ({
  children,
  distributedWCContext,
}: PropsWithChildren<IDistributedWebComponentContextProvider>) => {
  return (
    <DistributedWebComponentContext.Provider value={distributedWCContext}>
      {children}
    </DistributedWebComponentContext.Provider>
  );
};
