export const HopperAssets = {
  Ds: {
    Bunmoji: {
      Apple_2x: "https://cdn.hopper.com/ds/Bunmoji/Apple@2x.png",
      Apple_3x: "https://cdn.hopper.com/ds/Bunmoji/Apple@3x.png",
      Baseball_2x: "https://cdn.hopper.com/ds/Bunmoji/Baseball@2x.png",
      Baseball_3x: "https://cdn.hopper.com/ds/Bunmoji/Baseball@3x.png",
      Bear_2x: "https://cdn.hopper.com/ds/Bunmoji/Bear@2x.png",
      Bear_3x: "https://cdn.hopper.com/ds/Bunmoji/Bear@3x.png",
      Cactus_2x: "https://cdn.hopper.com/ds/Bunmoji/Cactus@2x.png",
      Cactus_3x: "https://cdn.hopper.com/ds/Bunmoji/Cactus@3x.png",
      Canoe_2x: "https://cdn.hopper.com/ds/Bunmoji/Canoe@2x.png",
      Canoe_3x: "https://cdn.hopper.com/ds/Bunmoji/Canoe@3x.png",
      Carrot_2x: "https://cdn.hopper.com/ds/Bunmoji/Carrot@2x.png",
      Carrot_3x: "https://cdn.hopper.com/ds/Bunmoji/Carrot@3x.png",
      DeciduousTree_2x:
        "https://cdn.hopper.com/ds/Bunmoji/Deciduous-Tree@2x.png",
      DeciduousTree_3x:
        "https://cdn.hopper.com/ds/Bunmoji/Deciduous-Tree@3x.png",
      Droplet_2x: "https://cdn.hopper.com/ds/Bunmoji/Droplet@2x.png",
      Droplet_3x: "https://cdn.hopper.com/ds/Bunmoji/Droplet@3x.png",
      EvergreenTree_2x:
        "https://cdn.hopper.com/ds/Bunmoji/Evergreen-Tree@2x.png",
      EvergreenTree_3x:
        "https://cdn.hopper.com/ds/Bunmoji/Evergreen-Tree@3x.png",
      GameDie_2x: "https://cdn.hopper.com/ds/Bunmoji/Game-Die@2x.png",
      GameDie_3x: "https://cdn.hopper.com/ds/Bunmoji/Game-Die@3x.png",
      GlowingStar_2x: "https://cdn.hopper.com/ds/Bunmoji/Glowing-Star@2x.png",
      GlowingStar_3x: "https://cdn.hopper.com/ds/Bunmoji/Glowing-Star@3x.png",
      Grapes_2x: "https://cdn.hopper.com/ds/Bunmoji/Grapes@2x.png",
      Grapes_3x: "https://cdn.hopper.com/ds/Bunmoji/Grapes@3x.png",
      HearNoEvil_2x: "https://cdn.hopper.com/ds/Bunmoji/Hear-No-Evil@2x.png",
      HearNoEvil_3x: "https://cdn.hopper.com/ds/Bunmoji/Hear-No-Evil@3x.png",
      Hotdog_2x: "https://cdn.hopper.com/ds/Bunmoji/Hotdog@2x.png",
      Hotdog_3x: "https://cdn.hopper.com/ds/Bunmoji/Hotdog@3x.png",
      PalmTree_2x: "https://cdn.hopper.com/ds/Bunmoji/Palm-Tree@2x.png",
      PalmTree_3x: "https://cdn.hopper.com/ds/Bunmoji/Palm-Tree@3x.png",
      RabbitFace_2x: "https://cdn.hopper.com/ds/Bunmoji/Rabbit-Face@2x.png",
      RabbitFace_3x: "https://cdn.hopper.com/ds/Bunmoji/Rabbit-Face@3x.png",
      SeeNoEvil_2x: "https://cdn.hopper.com/ds/Bunmoji/See-No-Evil@2x.png",
      SeeNoEvil_3x: "https://cdn.hopper.com/ds/Bunmoji/See-No-Evil@3x.png",
      Seedling_2x: "https://cdn.hopper.com/ds/Bunmoji/Seedling@2x.png",
      Seedling_3x: "https://cdn.hopper.com/ds/Bunmoji/Seedling@3x.png",
      Sparkles_2x: "https://cdn.hopper.com/ds/Bunmoji/Sparkles@2x.png",
      Sparkles_3x: "https://cdn.hopper.com/ds/Bunmoji/Sparkles@3x.png",
      SpeakNoEvil_2x: "https://cdn.hopper.com/ds/Bunmoji/Speak-No-Evil@2x.png",
      SpeakNoEvil_3x: "https://cdn.hopper.com/ds/Bunmoji/Speak-No-Evil@3x.png",
      Star_2x: "https://cdn.hopper.com/ds/Bunmoji/Star@2x.png",
      Star_3x: "https://cdn.hopper.com/ds/Bunmoji/Star@3x.png",
      SweatDrops_2x: "https://cdn.hopper.com/ds/Bunmoji/Sweat-Drops@2x.png",
      SweatDrops_3x: "https://cdn.hopper.com/ds/Bunmoji/Sweat-Drops@3x.png",
      Smilies: {
        Blush_2x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/blush@2x.png",
        Blush_3x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/blush@3x.png",
        Cry_2x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/cry@2x.png",
        Cry_3x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/cry@3x.png",
        SlightlySmilingFace_2x:
          "https://cdn.hopper.com/ds/Bunmoji/Smilies/slightlySmilingFace@2x.png",
        SlightlySmilingFace_3x:
          "https://cdn.hopper.com/ds/Bunmoji/Smilies/slightlySmilingFace@3x.png",
        Smiley_2x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/smiley@2x.png",
        Smiley_3x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/smiley@3x.png",
        Worried_2x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/worried@2x.png",
        Worried_3x: "https://cdn.hopper.com/ds/Bunmoji/Smilies/worried@3x.png",
      },
    },
    Bunny: {
      BusinessBg_2x: "https://cdn.hopper.com/ds/Bunny/Business-Bg@2x.png",
      BusinessBg_3x: "https://cdn.hopper.com/ds/Bunny/Business-Bg@3x.png",
      Business_2x: "https://cdn.hopper.com/ds/Bunny/Business@2x.png",
      Business_3x: "https://cdn.hopper.com/ds/Bunny/Business@3x.png",
      CheckIn_2x: "https://cdn.hopper.com/ds/Bunny/Check-In@2x.png",
      CheckIn_3x: "https://cdn.hopper.com/ds/Bunny/Check-In@3x.png",
      FallbackBg_2x: "https://cdn.hopper.com/ds/Bunny/Fallback-Bg@2x.png",
      FallbackBg_3x: "https://cdn.hopper.com/ds/Bunny/Fallback-Bg@3x.png",
      Fallback_2x: "https://cdn.hopper.com/ds/Bunny/Fallback@2x.png",
      Fallback_3x: "https://cdn.hopper.com/ds/Bunny/Fallback@3x.png",
      GuestCheckout_2x: "https://cdn.hopper.com/ds/Bunny/Guest-Checkout@2x.png",
      GuestCheckout_3x: "https://cdn.hopper.com/ds/Bunny/Guest-Checkout@3x.png",
      HopperTreesBg_2x:
        "https://cdn.hopper.com/ds/Bunny/Hopper-Trees-bg@2x.png",
      HopperTreesBg_3x:
        "https://cdn.hopper.com/ds/Bunny/Hopper-Trees-bg@3x.png",
      HopperTrees_2x: "https://cdn.hopper.com/ds/Bunny/Hopper-Trees@2x.png",
      HopperTrees_3x: "https://cdn.hopper.com/ds/Bunny/Hopper-Trees@3x.png",
      LostConnection_2x:
        "https://cdn.hopper.com/ds/Bunny/Lost-Connection@2x.png",
      LostConnection_3x:
        "https://cdn.hopper.com/ds/Bunny/Lost-Connection@3x.png",
      MissedConnection_2x:
        "https://cdn.hopper.com/ds/Bunny/Missed-Connection@2x.png",
      MissedConnection_3x:
        "https://cdn.hopper.com/ds/Bunny/Missed-Connection@3x.png",
      MultiCityBg_2x: "https://cdn.hopper.com/ds/Bunny/Multi-City-Bg@2x.png",
      MultiCityBg_3x: "https://cdn.hopper.com/ds/Bunny/Multi-City-Bg@3x.png",
      MultiCity_2x: "https://cdn.hopper.com/ds/Bunny/Multi-City@2x.png",
      MultiCity_3x: "https://cdn.hopper.com/ds/Bunny/Multi-City@3x.png",
      PremiumBg_2x: "https://cdn.hopper.com/ds/Bunny/Premium-Bg@2x.png",
      PremiumBg_3x: "https://cdn.hopper.com/ds/Bunny/Premium-Bg@3x.png",
      Premium_2x: "https://cdn.hopper.com/ds/Bunny/Premium@2x.png",
      Premium_3x: "https://cdn.hopper.com/ds/Bunny/Premium@3x.png",
      PriceFreeze_2x: "https://cdn.hopper.com/ds/Bunny/Price-Freeze@2x.png",
      PriceFreeze_3x: "https://cdn.hopper.com/ds/Bunny/Price-Freeze@3x.png",
      Sad_2x: "https://cdn.hopper.com/ds/Bunny/Sad@2x.png",
      Sad_3x: "https://cdn.hopper.com/ds/Bunny/Sad@3x.png",
      SupportBg_2x: "https://cdn.hopper.com/ds/Bunny/Support-Bg@2x.png",
      SupportBg_3x: "https://cdn.hopper.com/ds/Bunny/Support-Bg@3x.png",
      Support_2x: "https://cdn.hopper.com/ds/Bunny/Support@2x.png",
      Support_3x: "https://cdn.hopper.com/ds/Bunny/Support@3x.png",
      VideoBg_2x: "https://cdn.hopper.com/ds/Bunny/Video-Bg@2x.png",
      VideoBg_3x: "https://cdn.hopper.com/ds/Bunny/Video-Bg@3x.png",
      Video_2x: "https://cdn.hopper.com/ds/Bunny/Video@2x.png",
      Video_3x: "https://cdn.hopper.com/ds/Bunny/Video@3x.png",
      Booking: {
        FlightsNewBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Flights-New-Bg@2x.png",
        FlightsNewBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Flights-New-Bg@3x.png",
        Flights_2x: "https://cdn.hopper.com/ds/Bunny/Booking/Flights@2x.png",
        Flights_3x: "https://cdn.hopper.com/ds/Bunny/Booking/Flights@3x.png",
        HotelsNewBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Hotels-New-Bg@2x.png",
        HotelsNewBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Hotels-New-Bg@3x.png",
        Hotels_2x: "https://cdn.hopper.com/ds/Bunny/Booking/Hotels@2x.png",
        Hotels_3x: "https://cdn.hopper.com/ds/Bunny/Booking/Hotels@3x.png",
        PendingBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Pending-bg@2x.png",
        PendingBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Booking/Pending-bg@3x.png",
        Pending_2x: "https://cdn.hopper.com/ds/Bunny/Booking/Pending@2x.png",
        Pending_3x: "https://cdn.hopper.com/ds/Bunny/Booking/Pending@3x.png",
      },
      Confirmation: {
        Cancel_2x: "https://cdn.hopper.com/ds/Bunny/Confirmation/Cancel@2x.png",
        Cancel_3x: "https://cdn.hopper.com/ds/Bunny/Confirmation/Cancel@3x.png",
        Default_2x:
          "https://cdn.hopper.com/ds/Bunny/Confirmation/Default@2x.png",
        Default_3x:
          "https://cdn.hopper.com/ds/Bunny/Confirmation/Default@3x.png",
        Pending_2x:
          "https://cdn.hopper.com/ds/Bunny/Confirmation/Pending@2x.png",
        Pending_3x:
          "https://cdn.hopper.com/ds/Bunny/Confirmation/Pending@3x.png",
        Winter_2x: "https://cdn.hopper.com/ds/Bunny/Confirmation/Winter@2x.png",
        Winter_3x: "https://cdn.hopper.com/ds/Bunny/Confirmation/Winter@3x.png",
      },
      "Fare-Class": {
        BasicEconomyBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Basic-Economy-bg@2x.png",
        BasicEconomyBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Basic-Economy-bg@3x.png",
        BasicEconomy_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Basic-Economy@2x.png",
        BasicEconomy_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Basic-Economy@3x.png",
        BusinessBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Business-bg@2x.png",
        BusinessBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Business-bg@3x.png",
        Business_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Business@2x.png",
        Business_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Business@3x.png",
        EconomyBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Economy-bg@2x.png",
        EconomyBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Economy-bg@3x.png",
        Economy_2x: "https://cdn.hopper.com/ds/Bunny/Fare-Class/Economy@2x.png",
        Economy_3x: "https://cdn.hopper.com/ds/Bunny/Fare-Class/Economy@3x.png",
        FirstClassBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/First-Class-bg@2x.png",
        FirstClassBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/First-Class-bg@3x.png",
        FirstClass_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/First-Class@2x.png",
        FirstClass_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/First-Class@3x.png",
        PremiumEconomyBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Premium-Economy-bg@2x.png",
        PremiumEconomyBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Premium-Economy-bg@3x.png",
        PremiumEconomy_2x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Premium-Economy@2x.png",
        PremiumEconomy_3x:
          "https://cdn.hopper.com/ds/Bunny/Fare-Class/Premium-Economy@3x.png",
      },
      "Lobby-Bun": {
        LuggageBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Luggage-bg@2x.png",
        LuggageBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Luggage-bg@3x.png",
        Luggage_2x: "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Luggage@2x.png",
        Luggage_3x: "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Luggage@3x.png",
        PlatterSilver_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-Silver@2x.png",
        PlatterSilver_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-Silver@3x.png",
        PlatterBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-bg@2x.png",
        PlatterBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-bg@3x.png",
        PlatterGold_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-gold@2x.png",
        PlatterGold_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Platter-gold@3x.png",
        TrolleyGold_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-Gold@2x.png",
        TrolleyGold_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-Gold@3x.png",
        TrolleySilver_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-Silver@2x.png",
        TrolleySilver_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-Silver@3x.png",
        TrolleyBg_2x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-bg@2x.png",
        TrolleyBg_3x:
          "https://cdn.hopper.com/ds/Bunny/Lobby-Bun/Trolley-bg@3x.png",
      },
      Predictions: {
        Fair_2x: "https://cdn.hopper.com/ds/Bunny/Predictions/Fair@2x.png",
        Fair_3x: "https://cdn.hopper.com/ds/Bunny/Predictions/Fair@3x.png",
        Good_2x: "https://cdn.hopper.com/ds/Bunny/Predictions/Good@2x.png",
        Good_3x: "https://cdn.hopper.com/ds/Bunny/Predictions/Good@3x.png",
        Great_2x: "https://cdn.hopper.com/ds/Bunny/Predictions/Great@2x.png",
        Great_3x: "https://cdn.hopper.com/ds/Bunny/Predictions/Great@3x.png",
        Wait_2x: "https://cdn.hopper.com/ds/Bunny/Predictions/Wait@2x.png",
        Wait_3x: "https://cdn.hopper.com/ds/Bunny/Predictions/Wait@3x.png",
      },
      Shocked: {
        GreyBg_2x: "https://cdn.hopper.com/ds/Bunny/Shocked/Grey-bg@2x.png",
        GreyBg_3x: "https://cdn.hopper.com/ds/Bunny/Shocked/Grey-bg@3x.png",
        Grey_2x: "https://cdn.hopper.com/ds/Bunny/Shocked/Grey@2x.png",
        Grey_3x: "https://cdn.hopper.com/ds/Bunny/Shocked/Grey@3x.png",
        PinkBg_2x: "https://cdn.hopper.com/ds/Bunny/Shocked/Pink-bg@2x.png",
        PinkBg_3x: "https://cdn.hopper.com/ds/Bunny/Shocked/Pink-bg@3x.png",
        Pink_2x: "https://cdn.hopper.com/ds/Bunny/Shocked/Pink@2x.png",
        Pink_3x: "https://cdn.hopper.com/ds/Bunny/Shocked/Pink@3x.png",
      },
      Social: {
        Creator_2x: "https://cdn.hopper.com/ds/Bunny/Social/Creator@2x.png",
        Creator_3x: "https://cdn.hopper.com/ds/Bunny/Social/Creator@3x.png",
      },
    },
    Footer: {
      HopperTrees_2x: "https://cdn.hopper.com/ds/Footer/Hopper-Trees@2x.png",
      HopperTrees_3x: "https://cdn.hopper.com/ds/Footer/Hopper-Trees@3x.png",
      PriceFreeze_2x: "https://cdn.hopper.com/ds/Footer/Price-Freeze@2x.png",
      PriceFreeze_3x: "https://cdn.hopper.com/ds/Footer/Price-Freeze@3x.png",
      "Bunny-Bundles": {
        Btm_2x: "https://cdn.hopper.com/ds/Footer/Bunny-Bundles/Btm@2x.png",
        Btm_3x: "https://cdn.hopper.com/ds/Footer/Bunny-Bundles/Btm@3x.png",
        Top_2x: "https://cdn.hopper.com/ds/Footer/Bunny-Bundles/Top@2x.png",
        Top_3x: "https://cdn.hopper.com/ds/Footer/Bunny-Bundles/Top@3x.png",
      },
      "Hidden-Rates": {
        Btm_2x: "https://cdn.hopper.com/ds/Footer/Hidden-Rates/Btm@2x.png",
        Btm_3x: "https://cdn.hopper.com/ds/Footer/Hidden-Rates/Btm@3x.png",
        Top_2x: "https://cdn.hopper.com/ds/Footer/Hidden-Rates/Top@2x.png",
        Top_3x: "https://cdn.hopper.com/ds/Footer/Hidden-Rates/Top@3x.png",
      },
      Landscape: {
        Day_2x: "https://cdn.hopper.com/ds/Footer/Landscape/Day@2x.png",
        Day_3x: "https://cdn.hopper.com/ds/Footer/Landscape/Day@3x.png",
        Night_2x: "https://cdn.hopper.com/ds/Footer/Landscape/Night@2x.png",
        Night_3x: "https://cdn.hopper.com/ds/Footer/Landscape/Night@3x.png",
        SplitDay_2x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Split-Day@2x.png",
        SplitDay_3x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Split-Day@3x.png",
        SplitNight_2x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Split-Night@2x.png",
        SplitNight_3x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Split-Night@3x.png",
        SunriseSunset_2x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Sunrise-Sunset@2x.png",
        SunriseSunset_3x:
          "https://cdn.hopper.com/ds/Footer/Landscape/Sunrise-Sunset@3x.png",
      },
      Wave: {
        Btm_2x: "https://cdn.hopper.com/ds/Footer/Wave/Btm@2x.png",
        Btm_3x: "https://cdn.hopper.com/ds/Footer/Wave/Btm@3x.png",
        Top_2x: "https://cdn.hopper.com/ds/Footer/Wave/Top@2x.png",
        Top_3x: "https://cdn.hopper.com/ds/Footer/Wave/Top@3x.png",
      },
    },
    Hero: {
      HopperTrees_2x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees@2x.png",
      HopperTrees_3x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees@3x.png",
      Premium_2x: "https://cdn.hopper.com/ds/Hero/Premium@2x.png",
      Premium_3x: "https://cdn.hopper.com/ds/Hero/Premium@3x.png",
      PriceDrop_2x: "https://cdn.hopper.com/ds/Hero/Price-Drop@2x.png",
      PriceDrop_3x: "https://cdn.hopper.com/ds/Hero/Price-Drop@3x.png",
      TDT_2x: "https://cdn.hopper.com/ds/Hero/TDT@2x.png",
      TDT_3x: "https://cdn.hopper.com/ds/Hero/TDT@3x.png",
      "Deal-Drop": {
        BunDay_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Bun-Day@2x.png",
        BunDay_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Bun-Day@3x.png",
        CanadaDay_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Canada-Day@2x.png",
        CanadaDay_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Canada-Day@3x.png",
        CincoMayo_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Cinco-Mayo@2x.png",
        CincoMayo_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Cinco-Mayo@3x.png",
        Default_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Default@3x.png",
        Easter_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Easter@2x.png",
        Easter_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Easter@3x.png",
        FlashSale_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Flash-Sale@2x.png",
        FlashSale_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Flash-Sale@3x.png",
        Halloween_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Halloween@2x.png",
        Halloween_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Halloween@3x.png",
        LunarNY_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Lunar-NY@2x.png",
        LunarNY_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Lunar-NY@3x.png",
        SpringGetaway_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Spring-Getaway@2x.png",
        SpringGetaway_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Spring-Getaway@3x.png",
        SummerTravel_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Summer-Travel@2x.png",
        SummerTravel_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Summer-Travel@3x.png",
        Summer_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Summer@2x.png",
        Summer_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drop/Summer@3x.png",
        Thanksgiving_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Thanksgiving@2x.png",
        Thanksgiving_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drop/Thanksgiving@3x.png",
      },
      "Deal-Drops": {
        "BunDay+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Bun-Day+Wave@2x.png",
        "BunDay+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Bun-Day+Wave@3x.png",
        BunDay_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Bun-Day@2x.png",
        BunDay_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Bun-Day@3x.png",
        "CincoMayo+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Cinco-Mayo+Wave@2x.png",
        "CincoMayo+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Cinco-Mayo+Wave@3x.png",
        CincoMayo_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Cinco-Mayo@2x.png",
        CincoMayo_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Cinco-Mayo@3x.png",
        "Default+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Default+Wave@2x.png",
        "Default+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Default+Wave@3x.png",
        Default_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Default@3x.png",
        Easter_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Easter@2x.png",
        Easter_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Easter@3x.png",
        FlashSale_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Flash-Sale@2x.png",
        FlashSale_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Flash-Sale@3x.png",
        "Halloween+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Halloween+Wave@2x.png",
        "Halloween+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Halloween+Wave@3x.png",
        Halloween_2x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Halloween@2x.png",
        Halloween_3x:
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Halloween@3x.png",
        "LunarNY+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Lunar-NY+Wave@2x.png",
        "LunarNY+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Lunar-NY+Wave@3x.png",
        LunarNY_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Lunar-NY@2x.png",
        LunarNY_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Lunar-NY@3x.png",
        Spring_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Spring@2x.png",
        Spring_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Spring@3x.png",
        "Summer+Wave_2x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Summer+Wave@2x.png",
        "Summer+Wave_3x":
          "https://cdn.hopper.com/ds/Hero/Deal-Drops/Summer+Wave@3x.png",
        Summer_2x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Summer@2x.png",
        Summer_3x: "https://cdn.hopper.com/ds/Hero/Deal-Drops/Summer@3x.png",
      },
      "Hopper-Trees": {
        Full_2x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees/Full@3x.png",
        Wave_2x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees/Wave@2x.png",
        Wave_3x: "https://cdn.hopper.com/ds/Hero/Hopper-Trees/Wave@3x.png",
      },
      Premium: {
        Full_2x: "https://cdn.hopper.com/ds/Hero/Premium/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Hero/Premium/Full@3x.png",
        Wave_2x: "https://cdn.hopper.com/ds/Hero/Premium/Wave@2x.png",
        Wave_3x: "https://cdn.hopper.com/ds/Hero/Premium/Wave@3x.png",
      },
      "Price-Drop": {
        Full_2x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Full@3x.png",
        Padding_2x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Padding@2x.png",
        Padding_3x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Padding@3x.png",
        Wide_2x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Wide@2x.png",
        Wide_3x: "https://cdn.hopper.com/ds/Hero/Price-Drop/Wide@3x.png",
      },
      "Stay-Night": {
        City_2x: "https://cdn.hopper.com/ds/Hero/Stay-Night/City@2x.png",
        City_3x: "https://cdn.hopper.com/ds/Hero/Stay-Night/City@3x.png",
        Nature_2x: "https://cdn.hopper.com/ds/Hero/Stay-Night/Nature@2x.png",
        Nature_3x: "https://cdn.hopper.com/ds/Hero/Stay-Night/Nature@3x.png",
      },
      TDT: {
        Full_2x: "https://cdn.hopper.com/ds/Hero/TDT/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Hero/TDT/Full@3x.png",
        Wave_2x: "https://cdn.hopper.com/ds/Hero/TDT/Wave@2x.png",
        Wave_3x: "https://cdn.hopper.com/ds/Hero/TDT/Wave@3x.png",
      },
      Walmart: {
        Full_2x: "https://cdn.hopper.com/ds/Hero/Walmart/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Hero/Walmart/Full@3x.png",
        Padding_2x: "https://cdn.hopper.com/ds/Hero/Walmart/Padding@2x.png",
        Padding_3x: "https://cdn.hopper.com/ds/Hero/Walmart/Padding@3x.png",
      },
    },
    Modal: {
      City_2x: "https://cdn.hopper.com/ds/Modal/City@2x.png",
      City_3x: "https://cdn.hopper.com/ds/Modal/City@3x.png",
      Fallback_2x: "https://cdn.hopper.com/ds/Modal/Fallback@2x.png",
      Fallback_3x: "https://cdn.hopper.com/ds/Modal/Fallback@3x.png",
      Hotels_2x: "https://cdn.hopper.com/ds/Modal/Hotels@2x.png",
      Hotels_3x: "https://cdn.hopper.com/ds/Modal/Hotels@3x.png",
      Placeholder_2x: "https://cdn.hopper.com/ds/Modal/Placeholder@2x.png",
      Placeholder_3x: "https://cdn.hopper.com/ds/Modal/Placeholder@3x.png",
      Alert: {
        BabyOnBoard_2x:
          "https://cdn.hopper.com/ds/Modal/Alert/Baby-On-Board@2x.png",
        BabyOnBoard_3x:
          "https://cdn.hopper.com/ds/Modal/Alert/Baby-On-Board@3x.png",
        Duplicate_2x: "https://cdn.hopper.com/ds/Modal/Alert/Duplicate@2x.png",
        Duplicate_3x: "https://cdn.hopper.com/ds/Modal/Alert/Duplicate@3x.png",
        GuestCheckout_2x:
          "https://cdn.hopper.com/ds/Modal/Alert/Guest-Checkout@2x.png",
        GuestCheckout_3x:
          "https://cdn.hopper.com/ds/Modal/Alert/Guest-Checkout@3x.png",
      },
      Announcement: {
        BESeatSelection_2x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Seat-Selection@2x.png",
        BESeatSelection_3x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Seat-Selection@3x.png",
        BEUpgrades01_2x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Upgrades-01@2x.png",
        BEUpgrades01_3x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Upgrades-01@3x.png",
        BEUpgrades02_2x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Upgrades-02@2x.png",
        BEUpgrades02_3x:
          "https://cdn.hopper.com/ds/Modal/Announcement/BE-Upgrades-02@3x.png",
        FlightsHotels_2x:
          "https://cdn.hopper.com/ds/Modal/Announcement/Flights-Hotels@2x.png",
        FlightsHotels_3x:
          "https://cdn.hopper.com/ds/Modal/Announcement/Flights-Hotels@3x.png",
        Flights_2x:
          "https://cdn.hopper.com/ds/Modal/Announcement/Flights@2x.png",
        Flights_3x:
          "https://cdn.hopper.com/ds/Modal/Announcement/Flights@3x.png",
      },
      Default: {
        Car_2x: "https://cdn.hopper.com/ds/Modal/Default/Car@2x.png",
        Car_3x: "https://cdn.hopper.com/ds/Modal/Default/Car@3x.png",
      },
      Error: {
        Default_2x: "https://cdn.hopper.com/ds/Modal/Error/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Modal/Error/Default@3x.png",
        LostConnection_2x:
          "https://cdn.hopper.com/ds/Modal/Error/Lost-Connection@2x.png",
        LostConnection_3x:
          "https://cdn.hopper.com/ds/Modal/Error/Lost-Connection@3x.png",
      },
      Feature: {
        FrequentFlyer_2x:
          "https://cdn.hopper.com/ds/Modal/Feature/Frequent-Flyer@2x.png",
        FrequentFlyer_3x:
          "https://cdn.hopper.com/ds/Modal/Feature/Frequent-Flyer@3x.png",
        HackerFares_2x:
          "https://cdn.hopper.com/ds/Modal/Feature/Hacker-Fares@2x.png",
        HackerFares_3x:
          "https://cdn.hopper.com/ds/Modal/Feature/Hacker-Fares@3x.png",
        PushRequest01_2x:
          "https://cdn.hopper.com/ds/Modal/Feature/Push-Request-01@2x.png",
        PushRequest01_3x:
          "https://cdn.hopper.com/ds/Modal/Feature/Push-Request-01@3x.png",
        PushRequest02_2x:
          "https://cdn.hopper.com/ds/Modal/Feature/Push-Request-02@2x.png",
        PushRequest02_3x:
          "https://cdn.hopper.com/ds/Modal/Feature/Push-Request-02@3x.png",
      },
      Localisation: {
        BrazilianPortuguese_2x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Brazilian-Portuguese@2x.png",
        BrazilianPortuguese_3x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Brazilian-Portuguese@3x.png",
        French_2x: "https://cdn.hopper.com/ds/Modal/Localisation/French@2x.png",
        French_3x: "https://cdn.hopper.com/ds/Modal/Localisation/French@3x.png",
        MachineTranslation_2x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Machine-Translation@2x.png",
        MachineTranslation_3x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Machine-Translation@3x.png",
        Spanish_2x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Spanish@2x.png",
        Spanish_3x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Spanish@3x.png",
        Template_2x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Template@2x.png",
        Template_3x:
          "https://cdn.hopper.com/ds/Modal/Localisation/Template@3x.png",
      },
      Shocked: {
        Grey_2x: "https://cdn.hopper.com/ds/Modal/Shocked/Grey@2x.png",
        Grey_3x: "https://cdn.hopper.com/ds/Modal/Shocked/Grey@3x.png",
        Pink_2x: "https://cdn.hopper.com/ds/Modal/Shocked/Pink@2x.png",
        Pink_3x: "https://cdn.hopper.com/ds/Modal/Shocked/Pink@3x.png",
      },
    },
    Other: {
      Loading_2x: "https://cdn.hopper.com/ds/Other/loading@2x.png",
      Loading_3x: "https://cdn.hopper.com/ds/Other/loading@3x.png",
      "App-Overview": {
        Notification_2x:
          "https://cdn.hopper.com/ds/Other/App-Overview/Notification@2x.png",
        Notification_3x:
          "https://cdn.hopper.com/ds/Other/App-Overview/Notification@3x.png",
        Trip_2x: "https://cdn.hopper.com/ds/Other/App-Overview/Trip@2x.png",
        Trip_3x: "https://cdn.hopper.com/ds/Other/App-Overview/Trip@3x.png",
        Watch_2x: "https://cdn.hopper.com/ds/Other/App-Overview/Watch@2x.png",
        Watch_3x: "https://cdn.hopper.com/ds/Other/App-Overview/Watch@3x.png",
        Onboarding: {
          Notification_2x:
            "https://cdn.hopper.com/ds/Other/App-Overview/Onboarding/Notification@2x.png",
          Notification_3x:
            "https://cdn.hopper.com/ds/Other/App-Overview/Onboarding/Notification@3x.png",
        },
      },
      Bear: {
        SupportBot_2x:
          "https://cdn.hopper.com/ds/Other/Bear/Support-Bot@2x.png",
        SupportBot_3x:
          "https://cdn.hopper.com/ds/Other/Bear/Support-Bot@3x.png",
        Support_2x: "https://cdn.hopper.com/ds/Other/Bear/Support@2x.png",
        Support_3x: "https://cdn.hopper.com/ds/Other/Bear/Support@3x.png",
        "Fare-Type": {
          BasicEconomy_2x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Basic-Economy@2x.png",
          BasicEconomy_3x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Basic-Economy@3x.png",
          Default_2x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Default@2x.png",
          Default_3x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Default@3x.png",
          Economy_2x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Economy@2x.png",
          Economy_3x:
            "https://cdn.hopper.com/ds/Other/Bear/Fare-Type/Economy@3x.png",
        },
      },
      Landscape: {
        BasicEconomy_2x:
          "https://cdn.hopper.com/ds/Other/Landscape/Basic-Economy@2x.png",
        BasicEconomy_3x:
          "https://cdn.hopper.com/ds/Other/Landscape/Basic-Economy@3x.png",
        Economy_2x: "https://cdn.hopper.com/ds/Other/Landscape/Economy@2x.png",
        Economy_3x: "https://cdn.hopper.com/ds/Other/Landscape/Economy@3x.png",
      },
      Share: {
        Default_2x: "https://cdn.hopper.com/ds/Other/Share/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Other/Share/Default@3x.png",
      },
      "Sign-Up": {
        Booking01_2x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-01@2x.png",
        Booking01_3x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-01@3x.png",
        Booking02_2x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-02@2x.png",
        Booking02_3x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-02@3x.png",
        Booking03_2x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-03@2x.png",
        Booking03_3x:
          "https://cdn.hopper.com/ds/Other/Sign-Up/Booking-03@3x.png",
        Trip01_2x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-01@2x.png",
        Trip01_3x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-01@3x.png",
        Trip02_2x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-02@2x.png",
        Trip02_3x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-02@3x.png",
        Trip03_2x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-03@2x.png",
        Trip03_3x: "https://cdn.hopper.com/ds/Other/Sign-Up/Trip-03@3x.png",
      },
      Loader: {
        Hotels: {
          Dark_2x: "https://cdn.hopper.com/ds/Other/Loader/Hotels/Dark@2x.png",
          Dark_3x: "https://cdn.hopper.com/ds/Other/Loader/Hotels/Dark@3x.png",
          Light_2x:
            "https://cdn.hopper.com/ds/Other/Loader/Hotels/Light@2x.png",
          Light_3x:
            "https://cdn.hopper.com/ds/Other/Loader/Hotels/Light@3x.png",
        },
      },
    },
    Scene: {
      AlgoMerch_2x: "https://cdn.hopper.com/ds/Scene/Algo-Merch@2x.png",
      AlgoMerch_3x: "https://cdn.hopper.com/ds/Scene/Algo-Merch@3x.png",
      AutoBuyBg_2x: "https://cdn.hopper.com/ds/Scene/Auto-Buy-Bg@2x.png",
      AutoBuyBg_3x: "https://cdn.hopper.com/ds/Scene/Auto-Buy-Bg@3x.png",
      AutoBuy_2x: "https://cdn.hopper.com/ds/Scene/Auto-Buy@2x.png",
      AutoBuy_3x: "https://cdn.hopper.com/ds/Scene/Auto-Buy@3x.png",
      BunnyBundlesBg_2x:
        "https://cdn.hopper.com/ds/Scene/Bunny-Bundles-bg@2x.png",
      BunnyBundlesBg_3x:
        "https://cdn.hopper.com/ds/Scene/Bunny-Bundles-bg@3x.png",
      BunnyBundles_2x: "https://cdn.hopper.com/ds/Scene/Bunny-Bundles@2x.png",
      BunnyBundles_3x: "https://cdn.hopper.com/ds/Scene/Bunny-Bundles@3x.png",
      CarRentalsBg_2x: "https://cdn.hopper.com/ds/Scene/Car-Rentals-Bg@2x.png",
      CarRentalsBg_3x: "https://cdn.hopper.com/ds/Scene/Car-Rentals-Bg@3x.png",
      CarRentals_2x: "https://cdn.hopper.com/ds/Scene/Car-Rentals@2x.png",
      CarRentals_3x: "https://cdn.hopper.com/ds/Scene/Car-Rentals@3x.png",
      ChatbotBg_2x: "https://cdn.hopper.com/ds/Scene/Chatbot-Bg@2x.png",
      ChatbotBg_3x: "https://cdn.hopper.com/ds/Scene/Chatbot-Bg@3x.png",
      Chatbot_2x: "https://cdn.hopper.com/ds/Scene/Chatbot@2x.png",
      Chatbot_3x: "https://cdn.hopper.com/ds/Scene/Chatbot@3x.png",
      ChauffeurServiceBg_2x:
        "https://cdn.hopper.com/ds/Scene/Chauffeur-Service-Bg@2x.png",
      ChauffeurServiceBg_3x:
        "https://cdn.hopper.com/ds/Scene/Chauffeur-Service-Bg@3x.png",
      ChauffeurService_2x:
        "https://cdn.hopper.com/ds/Scene/Chauffeur-Service@2x.png",
      ChauffeurService_3x:
        "https://cdn.hopper.com/ds/Scene/Chauffeur-Service@3x.png",
      ConnectBankBg_2x:
        "https://cdn.hopper.com/ds/Scene/Connect-Bank-bg@2x.png",
      ConnectBankBg_3x:
        "https://cdn.hopper.com/ds/Scene/Connect-Bank-bg@3x.png",
      ConnectBank_2x: "https://cdn.hopper.com/ds/Scene/Connect-Bank@2x.png",
      ConnectBank_3x: "https://cdn.hopper.com/ds/Scene/Connect-Bank@3x.png",
      FallbackBg_2x: "https://cdn.hopper.com/ds/Scene/Fallback-Bg@2x.png",
      FallbackBg_3x: "https://cdn.hopper.com/ds/Scene/Fallback-Bg@3x.png",
      Fallback_2x: "https://cdn.hopper.com/ds/Scene/Fallback@2x.png",
      Fallback_3x: "https://cdn.hopper.com/ds/Scene/Fallback@3x.png",
      FlightDelayBg_2x:
        "https://cdn.hopper.com/ds/Scene/Flight-Delay-bg@2x.png",
      FlightDelayBg_3x:
        "https://cdn.hopper.com/ds/Scene/Flight-Delay-bg@3x.png",
      FlightDelay_2x: "https://cdn.hopper.com/ds/Scene/Flight-Delay@2x.png",
      FlightDelay_3x: "https://cdn.hopper.com/ds/Scene/Flight-Delay@3x.png",
      LFARBg_2x: "https://cdn.hopper.com/ds/Scene/LFAR-Bg@2x.png",
      LFARBg_3x: "https://cdn.hopper.com/ds/Scene/LFAR-Bg@3x.png",
      LFAR_2x: "https://cdn.hopper.com/ds/Scene/LFAR@2x.png",
      LFAR_3x: "https://cdn.hopper.com/ds/Scene/LFAR@3x.png",
      MissedConnectionBg_2x:
        "https://cdn.hopper.com/ds/Scene/Missed-Connection-bg@2x.png",
      MissedConnectionBg_3x:
        "https://cdn.hopper.com/ds/Scene/Missed-Connection-bg@3x.png",
      MissedConnection_2x:
        "https://cdn.hopper.com/ds/Scene/Missed-Connection@2x.png",
      MissedConnection_3x:
        "https://cdn.hopper.com/ds/Scene/Missed-Connection@3x.png",
      MultiCityBg_2x: "https://cdn.hopper.com/ds/Scene/Multi-City-Bg@2x.png",
      MultiCityBg_3x: "https://cdn.hopper.com/ds/Scene/Multi-City-Bg@3x.png",
      MultiCity_2x: "https://cdn.hopper.com/ds/Scene/Multi-City@2x.png",
      MultiCity_3x: "https://cdn.hopper.com/ds/Scene/Multi-City@3x.png",
      NewPriceDropBg_2x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Drop-Bg@2x.png",
      NewPriceDropBg_3x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Drop-Bg@3x.png",
      NewPriceDrop_2x: "https://cdn.hopper.com/ds/Scene/New-Price-Drop@2x.png",
      NewPriceDrop_3x: "https://cdn.hopper.com/ds/Scene/New-Price-Drop@3x.png",
      NewPriceFreezeBg_2x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Freeze-Bg@2x.png",
      NewPriceFreezeBg_3x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Freeze-Bg@3x.png",
      NewPriceFreeze_2x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Freeze@2x.png",
      NewPriceFreeze_3x:
        "https://cdn.hopper.com/ds/Scene/New-Price-Freeze@3x.png",
      PremiumBg_2x: "https://cdn.hopper.com/ds/Scene/Premium-Bg@2x.png",
      PremiumBg_3x: "https://cdn.hopper.com/ds/Scene/Premium-Bg@3x.png",
      Premium_2x: "https://cdn.hopper.com/ds/Scene/Premium@2x.png",
      Premium_3x: "https://cdn.hopper.com/ds/Scene/Premium@3x.png",
      PriceDropBg_2x: "https://cdn.hopper.com/ds/Scene/Price-Drop-bg@2x.png",
      PriceDropBg_3x: "https://cdn.hopper.com/ds/Scene/Price-Drop-bg@3x.png",
      PriceDrop_2x: "https://cdn.hopper.com/ds/Scene/Price-Drop@2x.png",
      PriceDrop_3x: "https://cdn.hopper.com/ds/Scene/Price-Drop@3x.png",
      PriceFreezeBg_2x:
        "https://cdn.hopper.com/ds/Scene/Price-Freeze-bg@2x.png",
      PriceFreezeBg_3x:
        "https://cdn.hopper.com/ds/Scene/Price-Freeze-bg@3x.png",
      PriceFreeze_2x: "https://cdn.hopper.com/ds/Scene/Price-Freeze@2x.png",
      PriceFreeze_3x: "https://cdn.hopper.com/ds/Scene/Price-Freeze@3x.png",
      ReferralsBg_2x: "https://cdn.hopper.com/ds/Scene/Referrals-Bg@2x.png",
      ReferralsBg_3x: "https://cdn.hopper.com/ds/Scene/Referrals-Bg@3x.png",
      Referrals_2x: "https://cdn.hopper.com/ds/Scene/Referrals@2x.png",
      Referrals_3x: "https://cdn.hopper.com/ds/Scene/Referrals@3x.png",
      SharingBg_2x: "https://cdn.hopper.com/ds/Scene/Sharing-bg@2x.png",
      SharingBg_3x: "https://cdn.hopper.com/ds/Scene/Sharing-bg@3x.png",
      Sharing_2x: "https://cdn.hopper.com/ds/Scene/Sharing@2x.png",
      Sharing_3x: "https://cdn.hopper.com/ds/Scene/Sharing@3x.png",
      TDTBkg_2x: "https://cdn.hopper.com/ds/Scene/TDT-Bkg@2x.png",
      TDTBkg_3x: "https://cdn.hopper.com/ds/Scene/TDT-Bkg@3x.png",
      TDT_2x: "https://cdn.hopper.com/ds/Scene/TDT@2x.png",
      TDT_3x: "https://cdn.hopper.com/ds/Scene/TDT@3x.png",
      TipToggle_2x: "https://cdn.hopper.com/ds/Scene/Tip-Toggle@2x.png",
      TipToggle_3x: "https://cdn.hopper.com/ds/Scene/Tip-Toggle@3x.png",
      TravellingFriends_2x:
        "https://cdn.hopper.com/ds/Scene/Travelling-Friends@2x.png",
      TravellingFriends_3x:
        "https://cdn.hopper.com/ds/Scene/Travelling-Friends@3x.png",
      UserMerchandising_2x:
        "https://cdn.hopper.com/ds/Scene/User-Merchandising@2x.png",
      UserMerchandising_3x:
        "https://cdn.hopper.com/ds/Scene/User-Merchandising@3x.png",
      WalmartBg_2x: "https://cdn.hopper.com/ds/Scene/Walmart-Bg@2x.png",
      WalmartBg_3x: "https://cdn.hopper.com/ds/Scene/Walmart-Bg@3x.png",
      Walmart_2x: "https://cdn.hopper.com/ds/Scene/Walmart@2x.png",
      Walmart_3x: "https://cdn.hopper.com/ds/Scene/Walmart@3x.png",
      CFAR: {
        DefaultBg_2x: "https://cdn.hopper.com/ds/Scene/CFAR/Default-bg@2x.png",
        DefaultBg_3x: "https://cdn.hopper.com/ds/Scene/CFAR/Default-bg@3x.png",
        Default_2x: "https://cdn.hopper.com/ds/Scene/CFAR/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Scene/CFAR/Default@3x.png",
        HotelIncentiveBg_2x:
          "https://cdn.hopper.com/ds/Scene/CFAR/Hotel-Incentive-bg@2x.png",
        HotelIncentiveBg_3x:
          "https://cdn.hopper.com/ds/Scene/CFAR/Hotel-Incentive-bg@3x.png",
        HotelIncentive_2x:
          "https://cdn.hopper.com/ds/Scene/CFAR/Hotel-Incentive@2x.png",
        HotelIncentive_3x:
          "https://cdn.hopper.com/ds/Scene/CFAR/Hotel-Incentive@3x.png",
      },
      "Car-Types": {
        "01Mini_2x": "https://cdn.hopper.com/ds/Scene/Car-Types/01-Mini@2x.png",
        "01Mini_3x": "https://cdn.hopper.com/ds/Scene/Car-Types/01-Mini@3x.png",
        "02Small_2x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/02-Small@2x.png",
        "02Small_3x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/02-Small@3x.png",
        "03Intermediate_2x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/03-Intermediate@2x.png",
        "03Intermediate_3x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/03-Intermediate@3x.png",
        "04Full_2x": "https://cdn.hopper.com/ds/Scene/Car-Types/04-Full@2x.png",
        "04Full_3x": "https://cdn.hopper.com/ds/Scene/Car-Types/04-Full@3x.png",
        "05SUV_2x": "https://cdn.hopper.com/ds/Scene/Car-Types/05-SUV@2x.png",
        "05SUV_3x": "https://cdn.hopper.com/ds/Scene/Car-Types/05-SUV@3x.png",
        "06Van_2x": "https://cdn.hopper.com/ds/Scene/Car-Types/06-Van@2x.png",
        "06Van_3x": "https://cdn.hopper.com/ds/Scene/Car-Types/06-Van@3x.png",
        "07Luxury_2x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/07-Luxury@2x.png",
        "07Luxury_3x":
          "https://cdn.hopper.com/ds/Scene/Car-Types/07-Luxury@3x.png",
      },
      "Carrot-Cash": {
        "10DollarBill_2x":
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/10-Dollar-Bill@2x.png",
        "10DollarBill_3x":
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/10-Dollar-Bill@3x.png",
        DefaultBg_2x:
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/Default-Bg@2x.png",
        DefaultBg_3x:
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/Default-Bg@3x.png",
        Default_2x:
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/Default@2x.png",
        Default_3x:
          "https://cdn.hopper.com/ds/Scene/Carrot-Cash/Default@3x.png",
      },
      Covid: {
        MaskBg_2x: "https://cdn.hopper.com/ds/Scene/Covid/Mask-Bg@2x.png",
        MaskBg_3x: "https://cdn.hopper.com/ds/Scene/Covid/Mask-Bg@3x.png",
        Mask_2x: "https://cdn.hopper.com/ds/Scene/Covid/Mask@2x.png",
        Mask_3x: "https://cdn.hopper.com/ds/Scene/Covid/Mask@3x.png",
        NoMaskBg_2x: "https://cdn.hopper.com/ds/Scene/Covid/No-Mask-Bg@2x.png",
        NoMaskBg_3x: "https://cdn.hopper.com/ds/Scene/Covid/No-Mask-Bg@3x.png",
        NoMask_2x: "https://cdn.hopper.com/ds/Scene/Covid/No-Mask@2x.png",
        NoMask_3x: "https://cdn.hopper.com/ds/Scene/Covid/No-Mask@3x.png",
      },
      "Hidden-Rates": {
        DefaultBg_2x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Default-Bg@2x.png",
        DefaultBg_3x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Default-Bg@3x.png",
        Default_2x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Default@2x.png",
        Default_3x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Default@3x.png",
        WatchBg_2x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Watch-bg@2x.png",
        WatchBg_3x:
          "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Watch-bg@3x.png",
        Watch_2x: "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Watch@2x.png",
        Watch_3x: "https://cdn.hopper.com/ds/Scene/Hidden-Rates/Watch@3x.png",
      },
      Installments: {
        CalendarBg_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Calendar-bg@2x.png",
        CalendarBg_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Calendar-bg@3x.png",
        Calendar_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Calendar@2x.png",
        Calendar_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Calendar@3x.png",
        HourglassBg_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Hourglass-bg@2x.png",
        HourglassBg_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Hourglass-bg@3x.png",
        Hourglass_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Hourglass@2x.png",
        Hourglass_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Hourglass@3x.png",
        OverduePaymentBg_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Overdue-Payment-Bg@2x.png",
        OverduePaymentBg_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Overdue-Payment-Bg@3x.png",
        OverduePayment_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Overdue-Payment@2x.png",
        OverduePayment_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Overdue-Payment@3x.png",
        PhysicalAddressBg_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Physical-Address-bg@2x.png",
        PhysicalAddressBg_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Physical-Address-bg@3x.png",
        PhysicalAddress_2x:
          "https://cdn.hopper.com/ds/Scene/Installments/Physical-Address@2x.png",
        PhysicalAddress_3x:
          "https://cdn.hopper.com/ds/Scene/Installments/Physical-Address@3x.png",
      },
      Localisation: {
        BrazilianPortuguese_2x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Brazilian-Portuguese@2x.png",
        BrazilianPortuguese_3x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Brazilian-Portuguese@3x.png",
        French_2x: "https://cdn.hopper.com/ds/Scene/Localisation/French@2x.png",
        French_3x: "https://cdn.hopper.com/ds/Scene/Localisation/French@3x.png",
        MachineTranslationBg_2x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Machine-Translation-Bg@2x.png",
        MachineTranslationBg_3x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Machine-Translation-Bg@3x.png",
        MachineTranslation_2x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Machine-Translation@2x.png",
        MachineTranslation_3x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Machine-Translation@3x.png",
        Spanish_2x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Spanish@2x.png",
        Spanish_3x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Spanish@3x.png",
        Template_2x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Template@2x.png",
        Template_3x:
          "https://cdn.hopper.com/ds/Scene/Localisation/Template@3x.png",
      },
      Overwhelmed: {
        DefaultBg_2x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Default-Bg@2x.png",
        DefaultBg_3x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Default-Bg@3x.png",
        Default_2x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Default@2x.png",
        Default_3x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Default@3x.png",
        WatchBg_2x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Watch-bg@2x.png",
        WatchBg_3x:
          "https://cdn.hopper.com/ds/Scene/Overwhelmed/Watch-bg@3x.png",
        Watch_2x: "https://cdn.hopper.com/ds/Scene/Overwhelmed/Watch@2x.png",
        Watch_3x: "https://cdn.hopper.com/ds/Scene/Overwhelmed/Watch@3x.png",
      },
      "Rebooking-Tool": {
        LuggageBg_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Luggage-bg@2x.png",
        LuggageBg_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Luggage-bg@3x.png",
        Luggage_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Luggage@2x.png",
        Luggage_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Luggage@3x.png",
        OnboardingBg_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Onboarding-bg@2x.png",
        OnboardingBg_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Onboarding-bg@3x.png",
        Onboarding_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Onboarding@2x.png",
        Onboarding_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Onboarding@3x.png",
        ReturnBg_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Return-bg@2x.png",
        ReturnBg_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Return-bg@3x.png",
        Return_2x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Return@2x.png",
        Return_3x:
          "https://cdn.hopper.com/ds/Scene/Rebooking-Tool/Return@3x.png",
      },
      "Refundable-Fare": {
        FlightDrawer_2x:
          "https://cdn.hopper.com/ds/Scene/Refundable-Fare/Flight-Drawer@2x.png",
        FlightDrawer_3x:
          "https://cdn.hopper.com/ds/Scene/Refundable-Fare/Flight-Drawer@3x.png",
      },
      "Support-Tree": {
        BillQuestions_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Bill-Questions@2x.png",
        BillQuestions_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Bill-Questions@3x.png",
        CallSupport_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Call-Support@2x.png",
        CallSupport_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Call-Support@3x.png",
        CancelBooking_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Cancel-Booking@2x.png",
        CancelBooking_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Cancel-Booking@3x.png",
        CancelPolicy_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Cancel-Policy@2x.png",
        CancelPolicy_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Cancel-Policy@3x.png",
        CardUpdate_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Card-Update@2x.png",
        CardUpdate_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Card-Update@3x.png",
        ChangePolicy_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Change-Policy@2x.png",
        ChangePolicy_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Change-Policy@3x.png",
        CheckDetails_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Check-Details@2x.png",
        CheckDetails_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Check-Details@3x.png",
        CheckIn_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Check-In@2x.png",
        CheckIn_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Check-In@3x.png",
        ContactHotel_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Contact-Hotel@2x.png",
        ContactHotel_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Contact-Hotel@3x.png",
        FlightDelay_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Flight-Delay@2x.png",
        FlightDelay_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Flight-Delay@3x.png",
        FlightMonitoring_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Flight-Monitoring@2x.png",
        FlightMonitoring_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Flight-Monitoring@3x.png",
        HotelIssues_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Hotel-Issues@2x.png",
        HotelIssues_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Hotel-Issues@3x.png",
        ModifyStay_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Modify-Stay@2x.png",
        ModifyStay_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Modify-Stay@3x.png",
        RefundRequest_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Refund-Request@2x.png",
        RefundRequest_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Refund-Request@3x.png",
        ReportHotel_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Report-Hotel@2x.png",
        ReportHotel_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Report-Hotel@3x.png",
        RoomUpgrade_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Room-Upgrade@2x.png",
        RoomUpgrade_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Room-Upgrade@3x.png",
        UnexpectedChanges_2x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Unexpected-Changes@2x.png",
        UnexpectedChanges_3x:
          "https://cdn.hopper.com/ds/Scene/Support-Tree/Unexpected-Changes@3x.png",
        VIP_2x: "https://cdn.hopper.com/ds/Scene/Support-Tree/VIP@2x.png",
        VIP_3x: "https://cdn.hopper.com/ds/Scene/Support-Tree/VIP@3x.png",
      },
      Ticket: {
        FlexibleBg_2x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Flexible-bg@2x.png",
        FlexibleBg_3x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Flexible-bg@3x.png",
        Flexible_2x: "https://cdn.hopper.com/ds/Scene/Ticket/Flexible@2x.png",
        Flexible_3x: "https://cdn.hopper.com/ds/Scene/Ticket/Flexible@3x.png",
        RefundableBg_2x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Refundable-bg@2x.png",
        RefundableBg_3x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Refundable-bg@3x.png",
        Refundable_2x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Refundable@2x.png",
        Refundable_3x:
          "https://cdn.hopper.com/ds/Scene/Ticket/Refundable@3x.png",
      },
      "Trip-Protection": {
        Default_2x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Default@2x.png",
        Default_3x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Default@3x.png",
        MascotBadge_2x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Mascot-Badge@2x.png",
        MascotBadge_3x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Mascot-Badge@3x.png",
        Mascot_2x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Mascot@2x.png",
        Mascot_3x:
          "https://cdn.hopper.com/ds/Scene/Trip-Protection/Mascot@3x.png",
      },
      Onboarding: {
        "Auto-Buy": {
          "01Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/01-Bg@2x.png",
          "01Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/01-Bg@3x.png",
          "02Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/02-Bg@2x.png",
          "02Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/02-Bg@3x.png",
          "03Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/03-Bg@2x.png",
          "03Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Auto-Buy/03-Bg@3x.png",
        },
        "Price-Drop": {
          "01Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/01-Bg@2x.png",
          "01Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/01-Bg@3x.png",
          "01_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/01@2x.png",
          "01_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/01@3x.png",
          "02Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/02-Bg@2x.png",
          "02Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/02-Bg@3x.png",
          "02_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/02@2x.png",
          "02_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/02@3x.png",
          "03Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/03-Bg@2x.png",
          "03Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/03-Bg@3x.png",
          "03_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/03@2x.png",
          "03_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Drop/03@3x.png",
        },
        "Price-Freeze": {
          "01Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/01-Bg@2x.png",
          "01Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/01-Bg@3x.png",
          "01_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/01@2x.png",
          "01_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/01@3x.png",
          "02Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/02-Bg@2x.png",
          "02Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/02-Bg@3x.png",
          "02_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/02@2x.png",
          "02_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/02@3x.png",
          "03Bg_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/03-Bg@2x.png",
          "03Bg_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/03-Bg@3x.png",
          "03_2x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/03@2x.png",
          "03_3x":
            "https://cdn.hopper.com/ds/Scene/Onboarding/Price-Freeze/03@3x.png",
        },
      },
    },
    Share: {
      Gifts_2x: "https://cdn.hopper.com/ds/Share/Gifts@2x.png",
      Gifts_3x: "https://cdn.hopper.com/ds/Share/Gifts@3x.png",
      Referrals_2x: "https://cdn.hopper.com/ds/Share/Referrals@2x.png",
      Referrals_3x: "https://cdn.hopper.com/ds/Share/Referrals@3x.png",
    },
    Thumb: {
      BaggageDamage_2x: "https://cdn.hopper.com/ds/Thumb/Baggage-Damage@2x.png",
      BaggageDamage_3x: "https://cdn.hopper.com/ds/Thumb/Baggage-Damage@3x.png",
      BaggageProtection_2x:
        "https://cdn.hopper.com/ds/Thumb/Baggage-Protection@2x.png",
      BaggageProtection_3x:
        "https://cdn.hopper.com/ds/Thumb/Baggage-Protection@3x.png",
      Check_2x: "https://cdn.hopper.com/ds/Thumb/Check@2x.png",
      Check_3x: "https://cdn.hopper.com/ds/Thumb/Check@3x.png",
      CreateAccount_2x: "https://cdn.hopper.com/ds/Thumb/Create-Account@2x.png",
      CreateAccount_3x: "https://cdn.hopper.com/ds/Thumb/Create-Account@3x.png",
      Credit_2x: "https://cdn.hopper.com/ds/Thumb/Credit@2x.png",
      Credit_3x: "https://cdn.hopper.com/ds/Thumb/Credit@3x.png",
      DealsFeed_2x: "https://cdn.hopper.com/ds/Thumb/Deals-Feed@2x.png",
      DealsFeed_3x: "https://cdn.hopper.com/ds/Thumb/Deals-Feed@3x.png",
      EventProtection_2x:
        "https://cdn.hopper.com/ds/Thumb/Event-Protection@2x.png",
      EventProtection_3x:
        "https://cdn.hopper.com/ds/Thumb/Event-Protection@3x.png",
      FairBear_2x: "https://cdn.hopper.com/ds/Thumb/Fair-Bear@2x.png",
      FairBear_3x: "https://cdn.hopper.com/ds/Thumb/Fair-Bear@3x.png",
      FirstIncentiveOffer_2x:
        "https://cdn.hopper.com/ds/Thumb/First-Incentive-Offer@2x.png",
      FirstIncentiveOffer_3x:
        "https://cdn.hopper.com/ds/Thumb/First-Incentive-Offer@3x.png",
      FlexFlight_2x: "https://cdn.hopper.com/ds/Thumb/Flex-Flight@2x.png",
      FlexFlight_3x: "https://cdn.hopper.com/ds/Thumb/Flex-Flight@3x.png",
      FlightChange_2x: "https://cdn.hopper.com/ds/Thumb/Flight-Change@2x.png",
      FlightChange_3x: "https://cdn.hopper.com/ds/Thumb/Flight-Change@3x.png",
      FlightDelay_2x: "https://cdn.hopper.com/ds/Thumb/Flight-Delay@2x.png",
      FlightDelay_3x: "https://cdn.hopper.com/ds/Thumb/Flight-Delay@3x.png",
      FlightInterruption_2x:
        "https://cdn.hopper.com/ds/Thumb/Flight-Interruption@2x.png",
      FlightInterruption_3x:
        "https://cdn.hopper.com/ds/Thumb/Flight-Interruption@3x.png",
      FlightTransfer_2x:
        "https://cdn.hopper.com/ds/Thumb/Flight-Transfer@2x.png",
      FlightTransfer_3x:
        "https://cdn.hopper.com/ds/Thumb/Flight-Transfer@3x.png",
      Happy_2x: "https://cdn.hopper.com/ds/Thumb/Happy@2x.png",
      Happy_3x: "https://cdn.hopper.com/ds/Thumb/Happy@3x.png",
      Logo_2x: "https://cdn.hopper.com/ds/Thumb/Logo@2x.png",
      Logo_3x: "https://cdn.hopper.com/ds/Thumb/Logo@3x.png",
      MedEvac_2x: "https://cdn.hopper.com/ds/Thumb/Med-Evac@2x.png",
      MedEvac_3x: "https://cdn.hopper.com/ds/Thumb/Med-Evac@3x.png",
      MedExpense_2x: "https://cdn.hopper.com/ds/Thumb/Med-Expense@2x.png",
      MedExpense_3x: "https://cdn.hopper.com/ds/Thumb/Med-Expense@3x.png",
      MultiCity_2x: "https://cdn.hopper.com/ds/Thumb/Multi-City@2x.png",
      MultiCity_3x: "https://cdn.hopper.com/ds/Thumb/Multi-City@3x.png",
      Packages_2x: "https://cdn.hopper.com/ds/Thumb/Packages@2x.png",
      Packages_3x: "https://cdn.hopper.com/ds/Thumb/Packages@3x.png",
      Stays_2x: "https://cdn.hopper.com/ds/Thumb/Stays@2x.png",
      Stays_3x: "https://cdn.hopper.com/ds/Thumb/Stays@3x.png",
      StoreA_2x: "https://cdn.hopper.com/ds/Thumb/Store-A@2x.png",
      StoreA_3x: "https://cdn.hopper.com/ds/Thumb/Store-A@3x.png",
      StoreB_2x: "https://cdn.hopper.com/ds/Thumb/Store-B@2x.png",
      StoreB_3x: "https://cdn.hopper.com/ds/Thumb/Store-B@3x.png",
      Tada_2x: "https://cdn.hopper.com/ds/Thumb/Tada@2x.png",
      Tada_3x: "https://cdn.hopper.com/ds/Thumb/Tada@3x.png",
      TripCancel_2x: "https://cdn.hopper.com/ds/Thumb/Trip-Cancel@2x.png",
      TripCancel_3x: "https://cdn.hopper.com/ds/Thumb/Trip-Cancel@3x.png",
      TripExtras_2x: "https://cdn.hopper.com/ds/Thumb/Trip-Extras@2x.png",
      TripExtras_3x: "https://cdn.hopper.com/ds/Thumb/Trip-Extras@3x.png",
      Watch_2x: "https://cdn.hopper.com/ds/Thumb/Watch@2x.png",
      Watch_3x: "https://cdn.hopper.com/ds/Thumb/Watch@3x.png",
      "0-Change-Fee": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/0-Change-Fee/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/0-Change-Fee/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/0-Change-Fee/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/0-Change-Fee/Icon@3x.png",
      },
      "Airport-Transfers": {
        "01Car_2x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/01-Car@2x.png",
        "01Car_3x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/01-Car@3x.png",
        "02Bus_2x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/02-Bus@2x.png",
        "02Bus_3x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/02-Bus@3x.png",
        "03Train_2x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/03-Train@2x.png",
        "03Train_3x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/03-Train@3x.png",
        "04Combined_2x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/04-Combined@2x.png",
        "04Combined_3x":
          "https://cdn.hopper.com/ds/Thumb/Airport-Transfers/04-Combined@3x.png",
      },
      "Auto-Buy": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Auto-Buy/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Auto-Buy/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Auto-Buy/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Auto-Buy/Icon@3x.png",
      },
      BPG: {
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/BPG/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/BPG/Icon@3x.png",
      },
      Baggage: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Baggage/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Baggage/Bun@3x.png",
        IconCircle_2x:
          "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Circle@2x.png",
        IconCircle_3x:
          "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Circle@3x.png",
        IconFull_2x: "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Full@2x.png",
        IconFull_3x: "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Full@3x.png",
        IconSquare_2x:
          "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Square@2x.png",
        IconSquare_3x:
          "https://cdn.hopper.com/ds/Thumb/Baggage/Icon-Square@3x.png",
      },
      "Bunny-Bundles": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Bunny-Bundles/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Bunny-Bundles/Bun@3x.png",
      },
      CFAR: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/CFAR/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/CFAR/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/CFAR/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/CFAR/Icon@3x.png",
      },
      "Car-Rentals": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Car-Rentals/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Car-Rentals/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Car-Rentals/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Car-Rentals/Icon@3x.png",
      },
      "Chauffeur-Service": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Chauffeur-Service/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Chauffeur-Service/Bun@3x.png",
        Icon_2x:
          "https://cdn.hopper.com/ds/Thumb/Chauffeur-Service/Icon@2x.png",
        Icon_3x:
          "https://cdn.hopper.com/ds/Thumb/Chauffeur-Service/Icon@3x.png",
      },
      "Combo-Coverage": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Combo-Coverage/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Combo-Coverage/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Combo-Coverage/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Combo-Coverage/Icon@3x.png",
      },
      "Combo-Flights": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Combo-Flights/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Combo-Flights/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Combo-Flights/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Combo-Flights/Icon@3x.png",
      },
      Concierge: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Concierge/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Concierge/Bun@3x.png",
      },
      Covid: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Covid/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Covid/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Covid/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Covid/Icon@3x.png",
      },
      Directions: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Directions/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Directions/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Directions/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Directions/Icon@3x.png",
      },
      "Entry-Point": {
        Cars_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Cars@2x.png",
        Cars_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Cars@3x.png",
        Flex_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flex@2x.png",
        Flex_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flex@3x.png",
        FlightsLight_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flights-Light@2x.png",
        FlightsLight_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flights-Light@3x.png",
        Flights_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flights@2x.png",
        Flights_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Flights@3x.png",
        GiftCard_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Gift-Card@2x.png",
        GiftCard_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Gift-Card@3x.png",
        Holidays_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Holidays@2x.png",
        Holidays_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Holidays@3x.png",
        Homes_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Homes@2x.png",
        Homes_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Homes@3x.png",
        Hotels_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Hotels@2x.png",
        Hotels_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Hotels@3x.png",
        Invite_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Invite@2x.png",
        Invite_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Invite@3x.png",
        Minigame_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Minigame@2x.png",
        Minigame_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Minigame@3x.png",
        Missions_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Missions@2x.png",
        Missions_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Missions@3x.png",
        More_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/More@2x.png",
        More_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/More@3x.png",
        MultiCity_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Multi-City@2x.png",
        MultiCity_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Multi-City@3x.png",
        Packages_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Packages@2x.png",
        Packages_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Packages@3x.png",
        PriceFreeze_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Price-Freeze@2x.png",
        PriceFreeze_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Price-Freeze@3x.png",
        Prize_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Prize@2x.png",
        Prize_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Prize@3x.png",
        Protection_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Protection@2x.png",
        Protection_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Protection@3x.png",
        SignIn_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Sign-In@2x.png",
        SignIn_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Sign-In@3x.png",
        Stays_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Stays@2x.png",
        Stays_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Stays@3x.png",
        StoreA_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-A@2x.png",
        StoreA_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-A@3x.png",
        StoreB_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-B@2x.png",
        StoreB_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-B@3x.png",
        StoreC_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-C@2x.png",
        StoreC_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store-C@3x.png",
        Store_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store@2x.png",
        Store_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Store@3x.png",
        Streaks_2x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Streaks@2x.png",
        Streaks_3x:
          "https://cdn.hopper.com/ds/Thumb/Entry-Point/Streaks@3x.png",
        Trees_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Trees@2x.png",
        Trees_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Trees@3x.png",
        Wallet_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Wallet@2x.png",
        Wallet_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Wallet@3x.png",
        Watch_2x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Watch@2x.png",
        Watch_3x: "https://cdn.hopper.com/ds/Thumb/Entry-Point/Watch@3x.png",
        "Airport-Transfers": {
          "01Car_2x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/01-Car@2x.png",
          "01Car_3x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/01-Car@3x.png",
          "02Bus_2x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/02-Bus@2x.png",
          "02Bus_3x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/02-Bus@3x.png",
          "03Train_2x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/03-Train@2x.png",
          "03Train_3x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/03-Train@3x.png",
          "04Combined_2x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/04-Combined@2x.png",
          "04Combined_3x":
            "https://cdn.hopper.com/ds/Thumb/Entry-Point/Airport-Transfers/04-Combined@3x.png",
        },
      },
      Fallback: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Fallback/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Fallback/Bun@3x.png",
        IconFull_2x:
          "https://cdn.hopper.com/ds/Thumb/Fallback/Icon-Full@2x.png",
        IconFull_3x:
          "https://cdn.hopper.com/ds/Thumb/Fallback/Icon-Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Fallback/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Fallback/Icon@3x.png",
      },
      Flag: {
        USA_2x: "https://cdn.hopper.com/ds/Thumb/Flag/USA@2x.png",
        USA_3x: "https://cdn.hopper.com/ds/Thumb/Flag/USA@3x.png",
      },
      "Flash-Deal": {
        IconFull_2x:
          "https://cdn.hopper.com/ds/Thumb/Flash-Deal/Icon-Full@2x.png",
        IconFull_3x:
          "https://cdn.hopper.com/ds/Thumb/Flash-Deal/Icon-Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Flash-Deal/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Flash-Deal/Icon@3x.png",
      },
      Gift: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Gift/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Gift/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Gift/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Gift/Icon@3x.png",
      },
      "Gift-Card": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Gift-Card/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Gift-Card/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Gift-Card/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Gift-Card/Icon@3x.png",
      },
      "Hidden-Rates": {
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Hidden-Rates/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Hidden-Rates/Icon@3x.png",
      },
      Homes: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Homes/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Homes/Bun@3x.png",
        Cancellation_2x:
          "https://cdn.hopper.com/ds/Thumb/Homes/Cancellation@2x.png",
        Cancellation_3x:
          "https://cdn.hopper.com/ds/Thumb/Homes/Cancellation@3x.png",
        Favourite_2x: "https://cdn.hopper.com/ds/Thumb/Homes/Favourite@2x.png",
        Favourite_3x: "https://cdn.hopper.com/ds/Thumb/Homes/Favourite@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Homes/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Homes/Icon@3x.png",
      },
      "Hopper-Trees": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Hopper-Trees/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Hopper-Trees/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Hopper-Trees/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Hopper-Trees/Icon@3x.png",
      },
      Hotels: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Hotels/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Hotels/Bun@3x.png",
        Cancellation_2x:
          "https://cdn.hopper.com/ds/Thumb/Hotels/Cancellation@2x.png",
        Cancellation_3x:
          "https://cdn.hopper.com/ds/Thumb/Hotels/Cancellation@3x.png",
        Favourite_2x: "https://cdn.hopper.com/ds/Thumb/Hotels/Favourite@2x.png",
        Favourite_3x: "https://cdn.hopper.com/ds/Thumb/Hotels/Favourite@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Hotels/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Hotels/Icon@3x.png",
        LobbyBun_2x: "https://cdn.hopper.com/ds/Thumb/Hotels/Lobby-Bun@2x.png",
        LobbyBun_3x: "https://cdn.hopper.com/ds/Thumb/Hotels/Lobby-Bun@3x.png",
      },
      "Missed-Connection": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Missed-Connection/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Missed-Connection/Bun@3x.png",
        Icon_2x:
          "https://cdn.hopper.com/ds/Thumb/Missed-Connection/Icon@2x.png",
        Icon_3x:
          "https://cdn.hopper.com/ds/Thumb/Missed-Connection/Icon@3x.png",
      },
      "New-Price-Drop": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/New-Price-Drop/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/New-Price-Drop/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/New-Price-Drop/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/New-Price-Drop/Icon@3x.png",
      },
      Offers: {
        Default_2x: "https://cdn.hopper.com/ds/Thumb/Offers/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Thumb/Offers/Default@3x.png",
        FlightDelays_2x:
          "https://cdn.hopper.com/ds/Thumb/Offers/Flight-Delays@2x.png",
        FlightDelays_3x:
          "https://cdn.hopper.com/ds/Thumb/Offers/Flight-Delays@3x.png",
        Generic_2x: "https://cdn.hopper.com/ds/Thumb/Offers/Generic@2x.png",
        Generic_3x: "https://cdn.hopper.com/ds/Thumb/Offers/Generic@3x.png",
        MissedConnection_2x:
          "https://cdn.hopper.com/ds/Thumb/Offers/Missed-Connection@2x.png",
        MissedConnection_3x:
          "https://cdn.hopper.com/ds/Thumb/Offers/Missed-Connection@3x.png",
        Savings_2x: "https://cdn.hopper.com/ds/Thumb/Offers/Savings@2x.png",
        Savings_3x: "https://cdn.hopper.com/ds/Thumb/Offers/Savings@3x.png",
        "Savings-Icon": {
          Cars: {
            Bun_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Cars/Bun@2x.png",
            Bun_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Cars/Bun@3x.png",
            Icon_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Cars/Icon@2x.png",
            Icon_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Cars/Icon@3x.png",
          },
          Flights: {
            Bun_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Flights/Bun@2x.png",
            Bun_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Flights/Bun@3x.png",
            Icon_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Flights/Icon@2x.png",
            Icon_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Flights/Icon@3x.png",
          },
          Hotels: {
            Bun_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Hotels/Bun@2x.png",
            Bun_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Hotels/Bun@3x.png",
            Icon_2x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Hotels/Icon@2x.png",
            Icon_3x:
              "https://cdn.hopper.com/ds/Thumb/Offers/Savings-Icon/Hotels/Icon@3x.png",
          },
        },
      },
      "Pay-Over-Time": {
        BunA_2x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Bun-A@2x.png",
        BunA_3x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Bun-A@3x.png",
        BunB_2x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Bun-B@2x.png",
        BunB_3x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Bun-B@3x.png",
        IconA_2x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-A@2x.png",
        IconA_3x: "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-A@3x.png",
        "Icon-B": {
          Blue_2x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Blue@2x.png",
          Blue_3x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Blue@3x.png",
          Full_2x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Full@2x.png",
          Full_3x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Full@3x.png",
          Green_2x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Green@2x.png",
          Green_3x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Green@3x.png",
          Orange_2x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Orange@2x.png",
          Orange_3x:
            "https://cdn.hopper.com/ds/Thumb/Pay-Over-Time/Icon-B/Orange@3x.png",
        },
      },
      "Pay-Today": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Pay-Today/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Pay-Today/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Pay-Today/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Pay-Today/Icon@3x.png",
      },
      Payments: {
        AffirmKlarna_2x:
          "https://cdn.hopper.com/ds/Thumb/Payments/AffirmKlarna@2x.png",
        AffirmKlarna_3x:
          "https://cdn.hopper.com/ds/Thumb/Payments/AffirmKlarna@3x.png",
      },
      Placeholder: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Placeholder/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Placeholder/Bun@3x.png",
        IconFull_2x:
          "https://cdn.hopper.com/ds/Thumb/Placeholder/Icon-Full@2x.png",
        IconFull_3x:
          "https://cdn.hopper.com/ds/Thumb/Placeholder/Icon-Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Placeholder/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Placeholder/Icon@3x.png",
      },
      Premium: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Premium/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Premium/Bun@3x.png",
        IconFull_2x: "https://cdn.hopper.com/ds/Thumb/Premium/Icon-Full@2x.png",
        IconFull_3x: "https://cdn.hopper.com/ds/Thumb/Premium/Icon-Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Premium/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Premium/Icon@3x.png",
      },
      "Price-Freeze": {
        BunCircle_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Bun-Circle@2x.png",
        BunCircle_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Bun-Circle@3x.png",
        BunSquare_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Bun-Square@2x.png",
        BunSquare_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Bun-Square@3x.png",
        Cancellation_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cancellation@2x.png",
        Cancellation_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cancellation@3x.png",
        CarrotCash_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Carrot-Cash@2x.png",
        CarrotCash_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Carrot-Cash@3x.png",
        CubeState01_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-01@2x.png",
        CubeState01_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-01@3x.png",
        CubeState02_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-02@2x.png",
        CubeState02_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-02@3x.png",
        CubeState03_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-03@2x.png",
        CubeState03_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-03@3x.png",
        CubeState04_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-04@2x.png",
        CubeState04_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-04@3x.png",
        CubeState05_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-05@2x.png",
        CubeState05_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Cube-State-05@3x.png",
        Duplicate_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Duplicate@2x.png",
        Duplicate_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Duplicate@3x.png",
        FlexibleFlight_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Flexible-Flight@2x.png",
        FlexibleFlight_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Flexible-Flight@3x.png",
        IconCoin_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Icon-Coin@2x.png",
        IconCoin_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Icon-Coin@3x.png",
        IconSnowflake_2x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Icon-Snowflake@2x.png",
        IconSnowflake_3x:
          "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Icon-Snowflake@3x.png",
        Saving_2x: "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Saving@2x.png",
        Saving_3x: "https://cdn.hopper.com/ds/Thumb/Price-Freeze/Saving@3x.png",
      },
      Refund: {
        Cars_2x: "https://cdn.hopper.com/ds/Thumb/Refund/Cars@2x.png",
        Cars_3x: "https://cdn.hopper.com/ds/Thumb/Refund/Cars@3x.png",
        Default_2x: "https://cdn.hopper.com/ds/Thumb/Refund/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Thumb/Refund/Default@3x.png",
        Flights_2x: "https://cdn.hopper.com/ds/Thumb/Refund/Flights@2x.png",
        Flights_3x: "https://cdn.hopper.com/ds/Thumb/Refund/Flights@3x.png",
        Hotels_2x: "https://cdn.hopper.com/ds/Thumb/Refund/Hotels@2x.png",
        Hotels_3x: "https://cdn.hopper.com/ds/Thumb/Refund/Hotels@3x.png",
        PriceFreeze_2x:
          "https://cdn.hopper.com/ds/Thumb/Refund/Price-Freeze@2x.png",
        PriceFreeze_3x:
          "https://cdn.hopper.com/ds/Thumb/Refund/Price-Freeze@3x.png",
        Tips_2x: "https://cdn.hopper.com/ds/Thumb/Refund/Tips@2x.png",
        Tips_3x: "https://cdn.hopper.com/ds/Thumb/Refund/Tips@3x.png",
        VIPSupport_2x:
          "https://cdn.hopper.com/ds/Thumb/Refund/VIP-Support@2x.png",
        VIPSupport_3x:
          "https://cdn.hopper.com/ds/Thumb/Refund/VIP-Support@3x.png",
      },
      "Reserved-Parking": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Reserved-Parking/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Reserved-Parking/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Reserved-Parking/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Reserved-Parking/Icon@3x.png",
      },
      Seats: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Seats/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Seats/Bun@3x.png",
        IconCircle_2x:
          "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Circle@2x.png",
        IconCircle_3x:
          "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Circle@3x.png",
        IconFull_2x: "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Full@2x.png",
        IconFull_3x: "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Full@3x.png",
        IconSquare_2x:
          "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Square@2x.png",
        IconSquare_3x:
          "https://cdn.hopper.com/ds/Thumb/Seats/Icon-Square@3x.png",
      },
      Support: {
        Bear_2x: "https://cdn.hopper.com/ds/Thumb/Support/Bear@2x.png",
        Bear_3x: "https://cdn.hopper.com/ds/Thumb/Support/Bear@3x.png",
        BillQuestions_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Bill-Questions@2x.png",
        BillQuestions_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Bill-Questions@3x.png",
        Bot_2x: "https://cdn.hopper.com/ds/Thumb/Support/Bot@2x.png",
        Bot_3x: "https://cdn.hopper.com/ds/Thumb/Support/Bot@3x.png",
        Chatbot_2x: "https://cdn.hopper.com/ds/Thumb/Support/Chatbot@2x.png",
        Chatbot_3x: "https://cdn.hopper.com/ds/Thumb/Support/Chatbot@3x.png",
        CheckIn_2x: "https://cdn.hopper.com/ds/Thumb/Support/Check-In@2x.png",
        CheckIn_3x: "https://cdn.hopper.com/ds/Thumb/Support/Check-In@3x.png",
        ContactHotel_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Contact-Hotel@2x.png",
        ContactHotel_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Contact-Hotel@3x.png",
        DateChange_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Date-change@2x.png",
        DateChange_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Date-change@3x.png",
        DifferentHotel_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Different-Hotel@2x.png",
        DifferentHotel_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Different-Hotel@3x.png",
        Fees_2x: "https://cdn.hopper.com/ds/Thumb/Support/Fees@2x.png",
        Fees_3x: "https://cdn.hopper.com/ds/Thumb/Support/Fees@3x.png",
        FileClaim_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/File-Claim@2x.png",
        FileClaim_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/File-Claim@3x.png",
        GuestInfo_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Guest-Info@2x.png",
        GuestInfo_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Guest-Info@3x.png",
        LostReservation_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Lost-Reservation@2x.png",
        LostReservation_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Lost-Reservation@3x.png",
        Question_2x: "https://cdn.hopper.com/ds/Thumb/Support/Question@2x.png",
        Question_3x: "https://cdn.hopper.com/ds/Thumb/Support/Question@3x.png",
        UpdatePayment_2x:
          "https://cdn.hopper.com/ds/Thumb/Support/Update-Payment@2x.png",
        UpdatePayment_3x:
          "https://cdn.hopper.com/ds/Thumb/Support/Update-Payment@3x.png",
        Basic: {
          Icon_2x: "https://cdn.hopper.com/ds/Thumb/Support/Basic/Icon@2x.png",
          Icon_3x: "https://cdn.hopper.com/ds/Thumb/Support/Basic/Icon@3x.png",
        },
        Help: {
          Bun_2x: "https://cdn.hopper.com/ds/Thumb/Support/Help/Bun@2x.png",
          Bun_3x: "https://cdn.hopper.com/ds/Thumb/Support/Help/Bun@3x.png",
          IconFull_2x:
            "https://cdn.hopper.com/ds/Thumb/Support/Help/Icon-Full@2x.png",
          IconFull_3x:
            "https://cdn.hopper.com/ds/Thumb/Support/Help/Icon-Full@3x.png",
          Icon_2x: "https://cdn.hopper.com/ds/Thumb/Support/Help/Icon@2x.png",
          Icon_3x: "https://cdn.hopper.com/ds/Thumb/Support/Help/Icon@3x.png",
        },
        Standard: {
          Icon_2x:
            "https://cdn.hopper.com/ds/Thumb/Support/Standard/Icon@2x.png",
          Icon_3x:
            "https://cdn.hopper.com/ds/Thumb/Support/Standard/Icon@3x.png",
        },
        VIP: {
          BearHappy_2x:
            "https://cdn.hopper.com/ds/Thumb/Support/VIP/Bear-Happy@2x.png",
          BearHappy_3x:
            "https://cdn.hopper.com/ds/Thumb/Support/VIP/Bear-Happy@3x.png",
          BearSad_2x:
            "https://cdn.hopper.com/ds/Thumb/Support/VIP/Bear-Sad@2x.png",
          BearSad_3x:
            "https://cdn.hopper.com/ds/Thumb/Support/VIP/Bear-Sad@3x.png",
          Icon_2x: "https://cdn.hopper.com/ds/Thumb/Support/VIP/Icon@2x.png",
          Icon_3x: "https://cdn.hopper.com/ds/Thumb/Support/VIP/Icon@3x.png",
        },
      },
      "Ticket-Flexible": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Bun@3x.png",
        IconCircle_2x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Icon-Circle@2x.png",
        IconCircle_3x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Icon-Circle@3x.png",
        IconSquare_2x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Icon-Square@2x.png",
        IconSquare_3x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Flexible/Icon-Square@3x.png",
      },
      "Ticket-Refundable": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Bun@3x.png",
        IconCircle_2x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Icon-Circle@2x.png",
        IconCircle_3x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Icon-Circle@3x.png",
        IconSquare_2x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Icon-Square@2x.png",
        IconSquare_3x:
          "https://cdn.hopper.com/ds/Thumb/Ticket-Refundable/Icon-Square@3x.png",
      },
      "Trip-Protection": {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Bun@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Icon@3x.png",
        Mascot: {
          Hedgehog_2x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Hedgehog@2x.png",
          Hedgehog_3x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Hedgehog@3x.png",
          Kangaroo_2x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Kangaroo@2x.png",
          Kangaroo_3x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Kangaroo@3x.png",
          Llama_2x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Llama@2x.png",
          Llama_3x:
            "https://cdn.hopper.com/ds/Thumb/Trip-Protection/Mascot/Llama@3x.png",
        },
      },
      Voucher: {
        Full_2x: "https://cdn.hopper.com/ds/Thumb/Voucher/Full@2x.png",
        Full_3x: "https://cdn.hopper.com/ds/Thumb/Voucher/Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Voucher/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Voucher/Icon@3x.png",
        NoOffer_2x: "https://cdn.hopper.com/ds/Thumb/Voucher/No-Offer@2x.png",
        NoOffer_3x: "https://cdn.hopper.com/ds/Thumb/Voucher/No-Offer@3x.png",
      },
      Wallet: {
        Bun_2x: "https://cdn.hopper.com/ds/Thumb/Wallet/Bun@2x.png",
        Bun_3x: "https://cdn.hopper.com/ds/Thumb/Wallet/Bun@3x.png",
        IconFull_2x: "https://cdn.hopper.com/ds/Thumb/Wallet/Icon-Full@2x.png",
        IconFull_3x: "https://cdn.hopper.com/ds/Thumb/Wallet/Icon-Full@3x.png",
        Icon_2x: "https://cdn.hopper.com/ds/Thumb/Wallet/Icon@2x.png",
        Icon_3x: "https://cdn.hopper.com/ds/Thumb/Wallet/Icon@3x.png",
      },
      Warning: {
        BunOrange_2x:
          "https://cdn.hopper.com/ds/Thumb/Warning/Bun-Orange@2x.png",
        BunOrange_3x:
          "https://cdn.hopper.com/ds/Thumb/Warning/Bun-Orange@3x.png",
        BunRed_2x: "https://cdn.hopper.com/ds/Thumb/Warning/Bun-Red@2x.png",
        BunRed_3x: "https://cdn.hopper.com/ds/Thumb/Warning/Bun-Red@3x.png",
        IconOrange_2x:
          "https://cdn.hopper.com/ds/Thumb/Warning/Icon-Orange@2x.png",
        IconOrange_3x:
          "https://cdn.hopper.com/ds/Thumb/Warning/Icon-Orange@3x.png",
        IconRed_2x: "https://cdn.hopper.com/ds/Thumb/Warning/Icon-Red@2x.png",
        IconRed_3x: "https://cdn.hopper.com/ds/Thumb/Warning/Icon-Red@3x.png",
      },
      "BE-Upgrade": {
        "Carry-On": {
          Add_2x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Carry-On/Add@2x.png",
          Add_3x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Carry-On/Add@3x.png",
          No_2x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Carry-On/No@2x.png",
          No_3x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Carry-On/No@3x.png",
        },
        "Seat-Selection": {
          Add_2x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Seat-Selection/Add@2x.png",
          Add_3x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Seat-Selection/Add@3x.png",
          No_2x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Seat-Selection/No@2x.png",
          No_3x:
            "https://cdn.hopper.com/ds/Thumb/BE-Upgrade/Seat-Selection/No@3x.png",
        },
      },
      Coin: {
        "Carrot-Cash": {
          Add_2x: "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Add@2x.png",
          Add_3x: "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Add@3x.png",
          Back_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Back@2x.png",
          Back_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Back@3x.png",
          Default_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Default@2x.png",
          Default_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Default@3x.png",
          Full_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Full@2x.png",
          Full_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/Full@3x.png",
          NoOffer_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/No-Offer@2x.png",
          NoOffer_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Carrot-Cash/No-Offer@3x.png",
        },
        Cash: {
          Default_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Default@2x.png",
          Default_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Default@3x.png",
          Full_2x: "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Full@2x.png",
          Full_3x: "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Full@3x.png",
          Refundable_2x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Refundable@2x.png",
          Refundable_3x:
            "https://cdn.hopper.com/ds/Thumb/Coin/Cash/Refundable@3x.png",
        },
      },
      Flexibility: {
        "Hotels-CFAR": {
          Icon_2x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-CFAR/Icon@2x.png",
          Icon_3x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-CFAR/Icon@3x.png",
        },
        "Hotels-ChFAR": {
          Icon_2x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-ChFAR/Icon@2x.png",
          Icon_3x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-ChFAR/Icon@3x.png",
        },
        "Hotels-LFAR": {
          Icon_2x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-LFAR/Icon@2x.png",
          Icon_3x:
            "https://cdn.hopper.com/ds/Thumb/Flexibility/Hotels-LFAR/Icon@3x.png",
        },
      },
      Flights: {
        Icon: {
          CircleBlue_2x:
            "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Circle-Blue@2x.png",
          CircleBlue_3x:
            "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Circle-Blue@3x.png",
          SquareYellow_2x:
            "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Square-Yellow@2x.png",
          SquareYellow_3x:
            "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Square-Yellow@3x.png",
          Circle: {
            Blue_2x:
              "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Circle/Blue@2x.png",
            Blue_3x:
              "https://cdn.hopper.com/ds/Thumb/Flights/Icon/Circle/Blue@3x.png",
          },
        },
      },
      "User-Merchandising": {
        External: {
          IconPurple_2x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Icon-Purple@2x.png",
          IconPurple_3x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Icon-Purple@3x.png",
          IconRed_2x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Icon-Red@2x.png",
          IconRed_3x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Icon-Red@3x.png",
          Plane_2x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Plane@2x.png",
          Plane_3x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/External/Plane@3x.png",
        },
        Internal: {
          Icon_2x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/Internal/Icon@2x.png",
          Icon_3x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/Internal/Icon@3x.png",
          Plane_2x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/Internal/Plane@2x.png",
          Plane_3x:
            "https://cdn.hopper.com/ds/Thumb/User-Merchandising/Internal/Plane@3x.png",
        },
      },
    },
    Avatars: {
      Passenger: {
        Add_2x: "https://cdn.hopper.com/ds/Avatars/Passenger/Add@2x.png",
        Add_3x: "https://cdn.hopper.com/ds/Avatars/Passenger/Add@3x.png",
        Adult_2x: "https://cdn.hopper.com/ds/Avatars/Passenger/Adult@2x.png",
        Adult_3x: "https://cdn.hopper.com/ds/Avatars/Passenger/Adult@3x.png",
        Child_2x: "https://cdn.hopper.com/ds/Avatars/Passenger/Child@2x.png",
        Child_3x: "https://cdn.hopper.com/ds/Avatars/Passenger/Child@3x.png",
        Infant_2x: "https://cdn.hopper.com/ds/Avatars/Passenger/Infant@2x.png",
        Infant_3x: "https://cdn.hopper.com/ds/Avatars/Passenger/Infant@3x.png",
      },
      Profile: {
        Adventurer_2x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Adventurer@2x.png",
        Adventurer_3x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Adventurer@3x.png",
        Content_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Content@2x.png",
        Content_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Content@3x.png",
        Cool_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Cool@2x.png",
        Cool_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Cool@3x.png",
        Cute_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Cute@2x.png",
        Cute_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Cute@3x.png",
        Default_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Default@2x.png",
        Default_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Default@3x.png",
        Eating_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Eating@2x.png",
        Eating_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Eating@3x.png",
        Grumpy_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Grumpy@2x.png",
        Grumpy_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Grumpy@3x.png",
        Happy_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Happy@2x.png",
        Happy_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Happy@3x.png",
        Hipster_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Hipster@2x.png",
        Hipster_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Hipster@3x.png",
        Neurotic_2x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Neurotic@2x.png",
        Neurotic_3x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Neurotic@3x.png",
        Placeholder_2x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Placeholder@2x.png",
        Placeholder_3x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Placeholder@3x.png",
        Pride_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Pride@2x.png",
        Pride_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Pride@3x.png",
        Sweetheart_2x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Sweetheart@2x.png",
        Sweetheart_3x:
          "https://cdn.hopper.com/ds/Avatars/Profile/Sweetheart@3x.png",
        Trans_2x: "https://cdn.hopper.com/ds/Avatars/Profile/Trans@2x.png",
        Trans_3x: "https://cdn.hopper.com/ds/Avatars/Profile/Trans@3x.png",
      },
      Product: {
        Passenger: {
          Add_2x:
            "https://cdn.hopper.com/ds/Avatars/Product/Passenger/Add@2x.png",
          Add_3x:
            "https://cdn.hopper.com/ds/Avatars/Product/Passenger/Add@3x.png",
        },
      },
    },
    Icon: {
      Airlines: {
        AerolineasArgentinas_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AerolineasArgentinas@2x.png",
        AerolineasArgentinas_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AerolineasArgentinas@3x.png",
        Aeromexico_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Aeromexico@2x.png",
        Aeromexico_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Aeromexico@3x.png",
        AirAlgerie_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirAlgerie@2x.png",
        AirAlgerie_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirAlgerie@3x.png",
        AirBerlin_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirBerlin@2x.png",
        AirBerlin_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirBerlin@3x.png",
        AirCanada_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirCanada@2x.png",
        AirCanada_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirCanada@3x.png",
        AirChina_2x: "https://cdn.hopper.com/ds/Icon/Airlines/AirChina@2x.png",
        AirChina_3x: "https://cdn.hopper.com/ds/Icon/Airlines/AirChina@3x.png",
        AirFrance_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirFrance@2x.png",
        AirFrance_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirFrance@3x.png",
        AirIndia_2x: "https://cdn.hopper.com/ds/Icon/Airlines/AirIndia@2x.png",
        AirIndia_3x: "https://cdn.hopper.com/ds/Icon/Airlines/AirIndia@3x.png",
        AirNewZealand_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirNewZealand@2x.png",
        AirNewZealand_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirNewZealand@3x.png",
        AirTransat_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirTransat@2x.png",
        AirTransat_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AirTransat@3x.png",
        AlaskaAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AlaskaAirlines@2x.png",
        AlaskaAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AlaskaAirlines@3x.png",
        Alitalia_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Alitalia@2x.png",
        Alitalia_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Alitalia@3x.png",
        AmericanAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AmericanAirlines@2x.png",
        AmericanAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/AmericanAirlines@3x.png",
        Avianca_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Avianca@2x.png",
        Avianca_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Avianca@3x.png",
        Azul_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Azul@2x.png",
        Azul_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Azul@3x.png",
        BritishAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/BritishAirways@2x.png",
        BritishAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/BritishAirways@3x.png",
        BulgariaAir_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/BulgariaAir@2x.png",
        BulgariaAir_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/BulgariaAir@3x.png",
        ChinaAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ChinaAirlines@2x.png",
        ChinaAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ChinaAirlines@3x.png",
        ChinaSouthernAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ChinaSouthernAirlines@2x.png",
        ChinaSouthernAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ChinaSouthernAirlines@3x.png",
        Delta_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Delta@2x.png",
        Delta_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Delta@3x.png",
        Egyptair_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Egyptair@2x.png",
        Egyptair_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Egyptair@3x.png",
        Emirates_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Emirates@2x.png",
        Emirates_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Emirates@3x.png",
        EthiopianAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/EthiopianAirlines@2x.png",
        EthiopianAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/EthiopianAirlines@3x.png",
        EtihadAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/EtihadAirways@2x.png",
        EtihadAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/EtihadAirways@3x.png",
        Finnair_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Finnair@2x.png",
        Finnair_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Finnair@3x.png",
        FrontierAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/FrontierAirlines@2x.png",
        FrontierAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/FrontierAirlines@3x.png",
        GOL_2x: "https://cdn.hopper.com/ds/Icon/Airlines/GOL@2x.png",
        GOL_3x: "https://cdn.hopper.com/ds/Icon/Airlines/GOL@3x.png",
        GermanWings_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/GermanWings@2x.png",
        GermanWings_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/GermanWings@3x.png",
        HawaiianAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/HawaiianAirlines@2x.png",
        HawaiianAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/HawaiianAirlines@3x.png",
        HongKongAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/HongKongAirlines@2x.png",
        HongKongAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/HongKongAirlines@3x.png",
        Iberia_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Iberia@2x.png",
        Iberia_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Iberia@3x.png",
        Icelandair_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Icelandair@2x.png",
        Icelandair_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Icelandair@3x.png",
        IsraelAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/IsraelAirlines@2x.png",
        IsraelAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/IsraelAirlines@3x.png",
        JapanAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/JapanAirlines@2x.png",
        JapanAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/JapanAirlines@3x.png",
        JetBlue_2x: "https://cdn.hopper.com/ds/Icon/Airlines/JetBlue@2x.png",
        JetBlue_3x: "https://cdn.hopper.com/ds/Icon/Airlines/JetBlue@3x.png",
        KLM_2x: "https://cdn.hopper.com/ds/Icon/Airlines/KLM@2x.png",
        KLM_3x: "https://cdn.hopper.com/ds/Icon/Airlines/KLM@3x.png",
        KoreanAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/KoreanAirlines@2x.png",
        KoreanAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/KoreanAirlines@3x.png",
        LATAM_2x: "https://cdn.hopper.com/ds/Icon/Airlines/LATAM@2x.png",
        LATAM_3x: "https://cdn.hopper.com/ds/Icon/Airlines/LATAM@3x.png",
        Lufthansa_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Lufthansa@2x.png",
        Lufthansa_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Lufthansa@3x.png",
        NiponAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/NiponAirways@2x.png",
        NiponAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/NiponAirways@3x.png",
        NorwegianAir_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/NorwegianAir@2x.png",
        NorwegianAir_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/NorwegianAir@3x.png",
        Porter_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Porter@2x.png",
        Porter_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Porter@3x.png",
        QantasAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/QantasAirways@2x.png",
        QantasAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/QantasAirways@3x.png",
        QatarAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/QatarAirways@2x.png",
        QatarAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/QatarAirways@3x.png",
        Rex_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Rex@2x.png",
        Rex_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Rex@3x.png",
        RoyalAirMaroc_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/RoyalAirMaroc@2x.png",
        RoyalAirMaroc_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/RoyalAirMaroc@3x.png",
        Ryanair_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Ryanair@2x.png",
        Ryanair_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Ryanair@3x.png",
        ScandinavianAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ScandinavianAirlines@2x.png",
        ScandinavianAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ScandinavianAirlines@3x.png",
        Spirit_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Spirit@2x.png",
        Spirit_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Spirit@3x.png",
        Swiss_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Swiss@2x.png",
        Swiss_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Swiss@3x.png",
        TAP_2x: "https://cdn.hopper.com/ds/Icon/Airlines/TAP@2x.png",
        TAP_3x: "https://cdn.hopper.com/ds/Icon/Airlines/TAP@3x.png",
        TUIAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/TUIAirlines@2x.png",
        TUIAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/TUIAirlines@3x.png",
        ThomasCook_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ThomasCook@2x.png",
        ThomasCook_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/ThomasCook@3x.png",
        Transnusa_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Transnusa@2x.png",
        Transnusa_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/Transnusa@3x.png",
        TurkishAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/TurkishAirlines@2x.png",
        TurkishAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/TurkishAirlines@3x.png",
        UkraineInternationalAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/UkraineInternationalAirlines@2x.png",
        UkraineInternationalAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/UkraineInternationalAirlines@3x.png",
        UnitedAirlines_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/UnitedAirlines@2x.png",
        UnitedAirlines_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/UnitedAirlines@3x.png",
        Virgin_2x: "https://cdn.hopper.com/ds/Icon/Airlines/Virgin@2x.png",
        Virgin_3x: "https://cdn.hopper.com/ds/Icon/Airlines/Virgin@3x.png",
        WOW_2x: "https://cdn.hopper.com/ds/Icon/Airlines/WOW@2x.png",
        WOW_3x: "https://cdn.hopper.com/ds/Icon/Airlines/WOW@3x.png",
        WestJet_2x: "https://cdn.hopper.com/ds/Icon/Airlines/WestJet@2x.png",
        WestJet_3x: "https://cdn.hopper.com/ds/Icon/Airlines/WestJet@3x.png",
        XLAirwaysFrance_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/XLAirwaysFrance@2x.png",
        XLAirwaysFrance_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/XLAirwaysFrance@3x.png",
        _Placeholder_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/_Placeholder@2x.png",
        _Placeholder_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/_Placeholder@3x.png",
        AirAsia_2x: "https://cdn.hopper.com/ds/Icon/Airlines/airAsia@2x.png",
        AirAsia_3x: "https://cdn.hopper.com/ds/Icon/Airlines/airAsia@3x.png",
        Akasaair_2x: "https://cdn.hopper.com/ds/Icon/Airlines/akasaair@2x.png",
        Akasaair_3x: "https://cdn.hopper.com/ds/Icon/Airlines/akasaair@3x.png",
        Bonza_2x: "https://cdn.hopper.com/ds/Icon/Airlines/bonza@2x.png",
        Bonza_3x: "https://cdn.hopper.com/ds/Icon/Airlines/bonza@3x.png",
        Indigo_2x: "https://cdn.hopper.com/ds/Icon/Airlines/indigo@2x.png",
        Indigo_3x: "https://cdn.hopper.com/ds/Icon/Airlines/indigo@3x.png",
        Jetstar_2x: "https://cdn.hopper.com/ds/Icon/Airlines/jetstar@2x.png",
        Jetstar_3x: "https://cdn.hopper.com/ds/Icon/Airlines/jetstar@3x.png",
        Myairline_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/myairline@2x.png",
        Myairline_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/myairline@3x.png",
        Royalair_2x: "https://cdn.hopper.com/ds/Icon/Airlines/royalair@2x.png",
        Royalair_3x: "https://cdn.hopper.com/ds/Icon/Airlines/royalair@3x.png",
        Spicejet_2x: "https://cdn.hopper.com/ds/Icon/Airlines/spicejet@2x.png",
        Spicejet_3x: "https://cdn.hopper.com/ds/Icon/Airlines/spicejet@3x.png",
        ThaiAirways_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/thaiAirways@2x.png",
        ThaiAirways_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/thaiAirways@3x.png",
        ThaiLion_2x: "https://cdn.hopper.com/ds/Icon/Airlines/thaiLion@2x.png",
        ThaiLion_3x: "https://cdn.hopper.com/ds/Icon/Airlines/thaiLion@3x.png",
        ThaiVietJet_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/thaiVietJet@2x.png",
        ThaiVietJet_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/thaiVietJet@3x.png",
        Vietjet_2x: "https://cdn.hopper.com/ds/Icon/Airlines/vietjet@2x.png",
        Vietjet_3x: "https://cdn.hopper.com/ds/Icon/Airlines/vietjet@3x.png",
        Vietravel_2x:
          "https://cdn.hopper.com/ds/Icon/Airlines/vietravel@2x.png",
        Vietravel_3x:
          "https://cdn.hopper.com/ds/Icon/Airlines/vietravel@3x.png",
        Vistara_2x: "https://cdn.hopper.com/ds/Icon/Airlines/vistara@2x.png",
        Vistara_3x: "https://cdn.hopper.com/ds/Icon/Airlines/vistara@3x.png",
        WizzAir_2x: "https://cdn.hopper.com/ds/Icon/Airlines/wizz-air@2x.png",
        WizzAir_3x: "https://cdn.hopper.com/ds/Icon/Airlines/wizz-air@3x.png",
      },
      Amenity: {
        "24hr_2x": "https://cdn.hopper.com/ds/Icon/Amenity/24hr@2x.png",
        "24hr_3x": "https://cdn.hopper.com/ds/Icon/Amenity/24hr@3x.png",
        "24hrAccess_2x":
          "https://cdn.hopper.com/ds/Icon/Amenity/24hrAccess@2x.png",
        "24hrAccess_3x":
          "https://cdn.hopper.com/ds/Icon/Amenity/24hrAccess@3x.png",
        Accessibility_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/Accessibility@2x.png",
        Accessibility_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/Accessibility@3x.png",
        TV_2x: "https://cdn.hopper.com/ds/Icon/Amenity/TV@2x.png",
        TV_3x: "https://cdn.hopper.com/ds/Icon/Amenity/TV@3x.png",
        Access_2x: "https://cdn.hopper.com/ds/Icon/Amenity/access@2x.png",
        Access_3x: "https://cdn.hopper.com/ds/Icon/Amenity/access@3x.png",
        AdultsOnly_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/adultsOnly@2x.png",
        AdultsOnly_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/adultsOnly@3x.png",
        AirConditioning_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/airConditioning@2x.png",
        AirConditioning_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/airConditioning@3x.png",
        AirportShuttle_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/airportShuttle@2x.png",
        AirportShuttle_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/airportShuttle@3x.png",
        Baby_2x: "https://cdn.hopper.com/ds/Icon/Amenity/baby@2x.png",
        Baby_3x: "https://cdn.hopper.com/ds/Icon/Amenity/baby@3x.png",
        Bar_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bar@2x.png",
        Bar_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bar@3x.png",
        BarBuilding_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/barBuilding@2x.png",
        BarBuilding_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/barBuilding@3x.png",
        Bbq_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bbq@2x.png",
        Bbq_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bbq@3x.png",
        Beach_2x: "https://cdn.hopper.com/ds/Icon/Amenity/beach@2x.png",
        Beach_3x: "https://cdn.hopper.com/ds/Icon/Amenity/beach@3x.png",
        BedLinens_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bedLinens@2x.png",
        BedLinens_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bedLinens@3x.png",
        Bell_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bell@2x.png",
        Bell_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bell@3x.png",
        Bicycle_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bicycle@2x.png",
        Bicycle_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bicycle@3x.png",
        BoardGames_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/boardGames@2x.png",
        BoardGames_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/boardGames@3x.png",
        Breakfast_2x: "https://cdn.hopper.com/ds/Icon/Amenity/breakfast@2x.png",
        Breakfast_3x: "https://cdn.hopper.com/ds/Icon/Amenity/breakfast@3x.png",
        CarbonMonoxideAlarm_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/carbonMonoxideAlarm@2x.png",
        CarbonMonoxideAlarm_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/carbonMonoxideAlarm@3x.png",
        CeilingFan_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/ceilingFan@2x.png",
        CeilingFan_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/ceilingFan@3x.png",
        Coffee_2x: "https://cdn.hopper.com/ds/Icon/Amenity/coffee@2x.png",
        Coffee_3x: "https://cdn.hopper.com/ds/Icon/Amenity/coffee@3x.png",
        ContactlessCheckIn_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/contactlessCheckIn@2x.png",
        ContactlessCheckIn_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/contactlessCheckIn@3x.png",
        Crib_2x: "https://cdn.hopper.com/ds/Icon/Amenity/crib@2x.png",
        Crib_3x: "https://cdn.hopper.com/ds/Icon/Amenity/crib@3x.png",
        DedicatedWorkspace_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/dedicatedWorkspace@2x.png",
        DedicatedWorkspace_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/dedicatedWorkspace@3x.png",
        DiningTable_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/diningTable@2x.png",
        DiningTable_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/diningTable@3x.png",
        Dishwasher_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/dishwasher@2x.png",
        Dishwasher_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/dishwasher@3x.png",
        Disinfectant_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/disinfectant@2x.png",
        Disinfectant_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/disinfectant@3x.png",
        Elevator_2x: "https://cdn.hopper.com/ds/Icon/Amenity/elevator@2x.png",
        Elevator_3x: "https://cdn.hopper.com/ds/Icon/Amenity/elevator@3x.png",
        Essential_2x: "https://cdn.hopper.com/ds/Icon/Amenity/essential@2x.png",
        Essential_3x: "https://cdn.hopper.com/ds/Icon/Amenity/essential@3x.png",
        Essentials_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/essentials@2x.png",
        Essentials_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/essentials@3x.png",
        EvCharger_2x: "https://cdn.hopper.com/ds/Icon/Amenity/evCharger@2x.png",
        EvCharger_3x: "https://cdn.hopper.com/ds/Icon/Amenity/evCharger@3x.png",
        ExtraPillowsBlankets_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/extraPillowsBlankets@2x.png",
        ExtraPillowsBlankets_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/extraPillowsBlankets@3x.png",
        Fallback_2x: "https://cdn.hopper.com/ds/Icon/Amenity/fallback@2x.png",
        Fallback_3x: "https://cdn.hopper.com/ds/Icon/Amenity/fallback@3x.png",
        FamilyFriendly_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/familyFriendly@2x.png",
        FamilyFriendly_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/familyFriendly@3x.png",
        FireExtinguisher_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/fireExtinguisher@2x.png",
        FireExtinguisher_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/fireExtinguisher@3x.png",
        FirePit_2x: "https://cdn.hopper.com/ds/Icon/Amenity/firePit@2x.png",
        FirePit_3x: "https://cdn.hopper.com/ds/Icon/Amenity/firePit@3x.png",
        Fireplace_2x: "https://cdn.hopper.com/ds/Icon/Amenity/fireplace@2x.png",
        Fireplace_3x: "https://cdn.hopper.com/ds/Icon/Amenity/fireplace@3x.png",
        FirstAidKit_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/firstAidKit@2x.png",
        FirstAidKit_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/firstAidKit@3x.png",
        FoodDrink_2x: "https://cdn.hopper.com/ds/Icon/Amenity/foodDrink@2x.png",
        FoodDrink_3x: "https://cdn.hopper.com/ds/Icon/Amenity/foodDrink@3x.png",
        FrontDesk_2x: "https://cdn.hopper.com/ds/Icon/Amenity/frontDesk@2x.png",
        FrontDesk_3x: "https://cdn.hopper.com/ds/Icon/Amenity/frontDesk@3x.png",
        GamingConsole_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/gamingConsole@2x.png",
        GamingConsole_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/gamingConsole@3x.png",
        Gym_2x: "https://cdn.hopper.com/ds/Icon/Amenity/gym@2x.png",
        Gym_3x: "https://cdn.hopper.com/ds/Icon/Amenity/gym@3x.png",
        Hairdryer_2x: "https://cdn.hopper.com/ds/Icon/Amenity/hairdryer@2x.png",
        Hairdryer_3x: "https://cdn.hopper.com/ds/Icon/Amenity/hairdryer@3x.png",
        HandSanitiser_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/handSanitiser@2x.png",
        HandSanitiser_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/handSanitiser@3x.png",
        Hangers_2x: "https://cdn.hopper.com/ds/Icon/Amenity/hangers@2x.png",
        Hangers_3x: "https://cdn.hopper.com/ds/Icon/Amenity/hangers@3x.png",
        Heat_2x: "https://cdn.hopper.com/ds/Icon/Amenity/heat@2x.png",
        Heat_3x: "https://cdn.hopper.com/ds/Icon/Amenity/heat@3x.png",
        Heating_2x: "https://cdn.hopper.com/ds/Icon/Amenity/heating@2x.png",
        Heating_3x: "https://cdn.hopper.com/ds/Icon/Amenity/heating@3x.png",
        HighChair_2x: "https://cdn.hopper.com/ds/Icon/Amenity/highChair@2x.png",
        HighChair_3x: "https://cdn.hopper.com/ds/Icon/Amenity/highChair@3x.png",
        HotTub_2x: "https://cdn.hopper.com/ds/Icon/Amenity/hotTub@2x.png",
        HotTub_3x: "https://cdn.hopper.com/ds/Icon/Amenity/hotTub@3x.png",
        HotWaterKettle_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/hotWaterKettle@2x.png",
        HotWaterKettle_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/hotWaterKettle@3x.png",
        Housekeeping_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/housekeeping@2x.png",
        Housekeeping_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/housekeeping@3x.png",
        InternetAccess_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/internetAccess@2x.png",
        InternetAccess_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/internetAccess@3x.png",
        Iron_2x: "https://cdn.hopper.com/ds/Icon/Amenity/iron@2x.png",
        Iron_3x: "https://cdn.hopper.com/ds/Icon/Amenity/iron@3x.png",
        Kayak_2x: "https://cdn.hopper.com/ds/Icon/Amenity/kayak@2x.png",
        Kayak_3x: "https://cdn.hopper.com/ds/Icon/Amenity/kayak@3x.png",
        KidFriendly_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/kidFriendly@2x.png",
        KidFriendly_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/kidFriendly@3x.png",
        Kitchen_2x: "https://cdn.hopper.com/ds/Icon/Amenity/kitchen@2x.png",
        Kitchen_3x: "https://cdn.hopper.com/ds/Icon/Amenity/kitchen@3x.png",
        Kitchenette_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/kitchenette@2x.png",
        Kitchenette_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/kitchenette@3x.png",
        Laptop_2x: "https://cdn.hopper.com/ds/Icon/Amenity/laptop@2x.png",
        Laptop_3x: "https://cdn.hopper.com/ds/Icon/Amenity/laptop@3x.png",
        Laundry_2x: "https://cdn.hopper.com/ds/Icon/Amenity/laundry@2x.png",
        Laundry_3x: "https://cdn.hopper.com/ds/Icon/Amenity/laundry@3x.png",
        Microwave_2x: "https://cdn.hopper.com/ds/Icon/Amenity/microwave@2x.png",
        Microwave_3x: "https://cdn.hopper.com/ds/Icon/Amenity/microwave@3x.png",
        NonSmoking_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/nonSmoking@2x.png",
        NonSmoking_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/nonSmoking@3x.png",
        OutdoorSeating_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/outdoorSeating@2x.png",
        OutdoorSeating_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/outdoorSeating@3x.png",
        Outdoors_2x: "https://cdn.hopper.com/ds/Icon/Amenity/outdoors@2x.png",
        Outdoors_3x: "https://cdn.hopper.com/ds/Icon/Amenity/outdoors@3x.png",
        Oven_2x: "https://cdn.hopper.com/ds/Icon/Amenity/oven@2x.png",
        Oven_3x: "https://cdn.hopper.com/ds/Icon/Amenity/oven@3x.png",
        Parking_2x: "https://cdn.hopper.com/ds/Icon/Amenity/parking@2x.png",
        Parking_3x: "https://cdn.hopper.com/ds/Icon/Amenity/parking@3x.png",
        Patio_2x: "https://cdn.hopper.com/ds/Icon/Amenity/patio@2x.png",
        Patio_3x: "https://cdn.hopper.com/ds/Icon/Amenity/patio@3x.png",
        Piano_2x: "https://cdn.hopper.com/ds/Icon/Amenity/piano@2x.png",
        Piano_3x: "https://cdn.hopper.com/ds/Icon/Amenity/piano@3x.png",
        PingPongTable_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/pingPongTable@2x.png",
        PingPongTable_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/pingPongTable@3x.png",
        Pool_2x: "https://cdn.hopper.com/ds/Icon/Amenity/pool@2x.png",
        Pool_3x: "https://cdn.hopper.com/ds/Icon/Amenity/pool@3x.png",
        PoolTable_2x: "https://cdn.hopper.com/ds/Icon/Amenity/poolTable@2x.png",
        PoolTable_3x: "https://cdn.hopper.com/ds/Icon/Amenity/poolTable@3x.png",
        PrivateEntrance_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/privateEntrance@2x.png",
        PrivateEntrance_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/privateEntrance@3x.png",
        Protection_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/protection@2x.png",
        Protection_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/protection@3x.png",
        Refrigerator_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/refrigerator@2x.png",
        Refrigerator_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/refrigerator@3x.png",
        Restaurant_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/restaurant@2x.png",
        Restaurant_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/restaurant@3x.png",
        RoomService_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/roomService@2x.png",
        RoomService_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/roomService@3x.png",
        Shampoo_2x: "https://cdn.hopper.com/ds/Icon/Amenity/shampoo@2x.png",
        Shampoo_3x: "https://cdn.hopper.com/ds/Icon/Amenity/shampoo@3x.png",
        SkiInSkiOut_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/skiInSkiOut@2x.png",
        SkiInSkiOut_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/skiInSkiOut@3x.png",
        SmokeAlarm_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/smokeAlarm@2x.png",
        SmokeAlarm_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/smokeAlarm@3x.png",
        SocialDistance_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/socialDistance@2x.png",
        SocialDistance_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/socialDistance@3x.png",
        SoundSystem_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/soundSystem@2x.png",
        SoundSystem_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/soundSystem@3x.png",
        Spa_2x: "https://cdn.hopper.com/ds/Icon/Amenity/spa@2x.png",
        Spa_3x: "https://cdn.hopper.com/ds/Icon/Amenity/spa@3x.png",
        Stove_2x: "https://cdn.hopper.com/ds/Icon/Amenity/stove@2x.png",
        Stove_3x: "https://cdn.hopper.com/ds/Icon/Amenity/stove@3x.png",
        StreetParking_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/streetParking@2x.png",
        StreetParking_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/streetParking@3x.png",
        ThingsToDo_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/thingsToDo@2x.png",
        ThingsToDo_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/thingsToDo@3x.png",
        Toaster_2x: "https://cdn.hopper.com/ds/Icon/Amenity/toaster@2x.png",
        Toaster_3x: "https://cdn.hopper.com/ds/Icon/Amenity/toaster@3x.png",
        Transportation_2x:
          "https://cdn.hopper.com/ds/Icon/Amenity/transportation@2x.png",
        Transportation_3x:
          "https://cdn.hopper.com/ds/Icon/Amenity/transportation@3x.png",
        Wardrobe_2x: "https://cdn.hopper.com/ds/Icon/Amenity/wardrobe@2x.png",
        Wardrobe_3x: "https://cdn.hopper.com/ds/Icon/Amenity/wardrobe@3x.png",
        Wifi_2x: "https://cdn.hopper.com/ds/Icon/Amenity/wifi@2x.png",
        Wifi_3x: "https://cdn.hopper.com/ds/Icon/Amenity/wifi@3x.png",
        Access: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/access/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/access/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/access/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/access/solid@3x.png",
        },
        Bath: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bath/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bath/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/Amenity/bath/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/Amenity/bath/solid@3x.png",
        },
        Bathtub: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bathtub/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bathtub/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bathtub/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bathtub/solid@3x.png",
        },
        BedDouble: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedDouble/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedDouble/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedDouble/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedDouble/solid@3x.png",
        },
        BedSingle: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSingle/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSingle/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSingle/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSingle/solid@3x.png",
        },
        BedSofa: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSofa/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSofa/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSofa/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/bedSofa/solid@3x.png",
        },
        BusinessServices: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/businessServices/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/businessServices/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/businessServices/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/businessServices/solid@3x.png",
        },
        Cleanliness: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/cleanliness/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/cleanliness/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/cleanliness/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/cleanliness/solid@3x.png",
        },
        PetFriendly: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/petFriendly/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/petFriendly/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/petFriendly/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/petFriendly/solid@3x.png",
        },
        Safety: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/safety/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/safety/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/safety/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/safety/solid@3x.png",
        },
        Shower: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/shower/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/shower/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/shower/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/shower/solid@3x.png",
        },
        Toilet: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/toilet/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/toilet/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/toilet/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/toilet/solid@3x.png",
        },
        View: {
          Beach_2x: "https://cdn.hopper.com/ds/Icon/Amenity/view/beach@2x.png",
          Beach_3x: "https://cdn.hopper.com/ds/Icon/Amenity/view/beach@3x.png",
          City_2x: "https://cdn.hopper.com/ds/Icon/Amenity/view/city@2x.png",
          City_3x: "https://cdn.hopper.com/ds/Icon/Amenity/view/city@3x.png",
          Mountain_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/mountain@2x.png",
          Mountain_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/mountain@3x.png",
          Nature_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/nature@2x.png",
          Nature_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/nature@3x.png",
          Property_2x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/property@2x.png",
          Property_3x:
            "https://cdn.hopper.com/ds/Icon/Amenity/view/property@3x.png",
          Water_2x: "https://cdn.hopper.com/ds/Icon/Amenity/view/water@2x.png",
          Water_3x: "https://cdn.hopper.com/ds/Icon/Amenity/view/water@3x.png",
        },
      },
      System: {
        Back_2x: "https://cdn.hopper.com/ds/Icon/System/Back@2x.png",
        Back_3x: "https://cdn.hopper.com/ds/Icon/System/Back@3x.png",
        Close_2x: "https://cdn.hopper.com/ds/Icon/System/Close@2x.png",
        Close_3x: "https://cdn.hopper.com/ds/Icon/System/Close@3x.png",
        Decrease_2x: "https://cdn.hopper.com/ds/Icon/System/Decrease@2x.png",
        Decrease_3x: "https://cdn.hopper.com/ds/Icon/System/Decrease@3x.png",
        Filters_2x: "https://cdn.hopper.com/ds/Icon/System/Filters@2x.png",
        Filters_3x: "https://cdn.hopper.com/ds/Icon/System/Filters@3x.png",
        Forward_2x: "https://cdn.hopper.com/ds/Icon/System/Forward@2x.png",
        Forward_3x: "https://cdn.hopper.com/ds/Icon/System/Forward@3x.png",
        Generic_2x: "https://cdn.hopper.com/ds/Icon/System/Generic@2x.png",
        Generic_3x: "https://cdn.hopper.com/ds/Icon/System/Generic@3x.png",
        Guest_2x: "https://cdn.hopper.com/ds/Icon/System/Guest@2x.png",
        Guest_3x: "https://cdn.hopper.com/ds/Icon/System/Guest@3x.png",
        Increase_2x: "https://cdn.hopper.com/ds/Icon/System/Increase@2x.png",
        Increase_3x: "https://cdn.hopper.com/ds/Icon/System/Increase@3x.png",
        Link_2x: "https://cdn.hopper.com/ds/Icon/System/Link@2x.png",
        Link_3x: "https://cdn.hopper.com/ds/Icon/System/Link@3x.png",
        Oneway_2x: "https://cdn.hopper.com/ds/Icon/System/Oneway@2x.png",
        Oneway_3x: "https://cdn.hopper.com/ds/Icon/System/Oneway@3x.png",
        Overflow_2x: "https://cdn.hopper.com/ds/Icon/System/Overflow@2x.png",
        Overflow_3x: "https://cdn.hopper.com/ds/Icon/System/Overflow@3x.png",
        Placeholder_2x:
          "https://cdn.hopper.com/ds/Icon/System/Placeholder@2x.png",
        Placeholder_3x:
          "https://cdn.hopper.com/ds/Icon/System/Placeholder@3x.png",
        Reverse_2x: "https://cdn.hopper.com/ds/Icon/System/Reverse@2x.png",
        Reverse_3x: "https://cdn.hopper.com/ds/Icon/System/Reverse@3x.png",
        Roundtrip_2x: "https://cdn.hopper.com/ds/Icon/System/Roundtrip@2x.png",
        Roundtrip_3x: "https://cdn.hopper.com/ds/Icon/System/Roundtrip@3x.png",
        Seat_2x: "https://cdn.hopper.com/ds/Icon/System/Seat@2x.png",
        Seat_3x: "https://cdn.hopper.com/ds/Icon/System/Seat@3x.png",
        Setting_2x: "https://cdn.hopper.com/ds/Icon/System/Setting@2x.png",
        Setting_3x: "https://cdn.hopper.com/ds/Icon/System/Setting@3x.png",
        Share_2x: "https://cdn.hopper.com/ds/Icon/System/Share@2x.png",
        Share_3x: "https://cdn.hopper.com/ds/Icon/System/Share@3x.png",
        Sort_2x: "https://cdn.hopper.com/ds/Icon/System/Sort@2x.png",
        Sort_3x: "https://cdn.hopper.com/ds/Icon/System/Sort@3x.png",
        BackAndroid_2x:
          "https://cdn.hopper.com/ds/Icon/System/backAndroid@2x.png",
        BackAndroid_3x:
          "https://cdn.hopper.com/ds/Icon/System/backAndroid@3x.png",
        BannerArrow_2x:
          "https://cdn.hopper.com/ds/Icon/System/bannerArrow@2x.png",
        BannerArrow_3x:
          "https://cdn.hopper.com/ds/Icon/System/bannerArrow@3x.png",
        Car_2x: "https://cdn.hopper.com/ds/Icon/System/car@2x.png",
        Car_3x: "https://cdn.hopper.com/ds/Icon/System/car@3x.png",
        CarrotCashBack_2x:
          "https://cdn.hopper.com/ds/Icon/System/carrotCashBack@2x.png",
        CarrotCashBack_3x:
          "https://cdn.hopper.com/ds/Icon/System/carrotCashBack@3x.png",
        Copy_2x: "https://cdn.hopper.com/ds/Icon/System/copy@2x.png",
        Copy_3x: "https://cdn.hopper.com/ds/Icon/System/copy@3x.png",
        DownTrendArrow_2x:
          "https://cdn.hopper.com/ds/Icon/System/downTrendArrow@2x.png",
        DownTrendArrow_3x:
          "https://cdn.hopper.com/ds/Icon/System/downTrendArrow@3x.png",
        DropdownActive_2x:
          "https://cdn.hopper.com/ds/Icon/System/dropdownActive@2x.png",
        DropdownActive_3x:
          "https://cdn.hopper.com/ds/Icon/System/dropdownActive@3x.png",
        DropdownDefault_2x:
          "https://cdn.hopper.com/ds/Icon/System/dropdownDefault@2x.png",
        DropdownDefault_3x:
          "https://cdn.hopper.com/ds/Icon/System/dropdownDefault@3x.png",
        Expand_2x: "https://cdn.hopper.com/ds/Icon/System/expand@2x.png",
        Expand_3x: "https://cdn.hopper.com/ds/Icon/System/expand@3x.png",
        HandControl_2x:
          "https://cdn.hopper.com/ds/Icon/System/handControl@2x.png",
        HandControl_3x:
          "https://cdn.hopper.com/ds/Icon/System/handControl@3x.png",
        Language_2x: "https://cdn.hopper.com/ds/Icon/System/language@2x.png",
        Language_3x: "https://cdn.hopper.com/ds/Icon/System/language@3x.png",
        LinkCopy_2x: "https://cdn.hopper.com/ds/Icon/System/linkCopy@2x.png",
        LinkCopy_3x: "https://cdn.hopper.com/ds/Icon/System/linkCopy@3x.png",
        NonRefundable_2x:
          "https://cdn.hopper.com/ds/Icon/System/nonRefundable@2x.png",
        NonRefundable_3x:
          "https://cdn.hopper.com/ds/Icon/System/nonRefundable@3x.png",
        PremiumRoadsideAssistance_2x:
          "https://cdn.hopper.com/ds/Icon/System/premiumRoadsideAssistance@2x.png",
        PremiumRoadsideAssistance_3x:
          "https://cdn.hopper.com/ds/Icon/System/premiumRoadsideAssistance@3x.png",
        PrepaidFuel_2x:
          "https://cdn.hopper.com/ds/Icon/System/prepaidFuel@2x.png",
        PrepaidFuel_3x:
          "https://cdn.hopper.com/ds/Icon/System/prepaidFuel@3x.png",
        Refundable_2x:
          "https://cdn.hopper.com/ds/Icon/System/refundable@2x.png",
        Refundable_3x:
          "https://cdn.hopper.com/ds/Icon/System/refundable@3x.png",
        Reload_2x: "https://cdn.hopper.com/ds/Icon/System/reload@2x.png",
        Reload_3x: "https://cdn.hopper.com/ds/Icon/System/reload@3x.png",
        Shrink_2x: "https://cdn.hopper.com/ds/Icon/System/shrink@2x.png",
        Shrink_3x: "https://cdn.hopper.com/ds/Icon/System/shrink@3x.png",
        Subtract_2x: "https://cdn.hopper.com/ds/Icon/System/subtract@2x.png",
        Subtract_3x: "https://cdn.hopper.com/ds/Icon/System/subtract@3x.png",
        Tolls_2x: "https://cdn.hopper.com/ds/Icon/System/tolls@2x.png",
        Tolls_3x: "https://cdn.hopper.com/ds/Icon/System/tolls@3x.png",
        UpTrendArrow_2x:
          "https://cdn.hopper.com/ds/Icon/System/upTrendArrow@2x.png",
        UpTrendArrow_3x:
          "https://cdn.hopper.com/ds/Icon/System/upTrendArrow@3x.png",
        WatchActive_2x:
          "https://cdn.hopper.com/ds/Icon/System/watchActive@2x.png",
        WatchActive_3x:
          "https://cdn.hopper.com/ds/Icon/System/watchActive@3x.png",
        WatchDefault_2x:
          "https://cdn.hopper.com/ds/Icon/System/watchDefault@2x.png",
        WatchDefault_3x:
          "https://cdn.hopper.com/ds/Icon/System/watchDefault@3x.png",
        WhereFrom_2x: "https://cdn.hopper.com/ds/Icon/System/whereFrom@2x.png",
        WhereFrom_3x: "https://cdn.hopper.com/ds/Icon/System/whereFrom@3x.png",
        WhereTo_2x: "https://cdn.hopper.com/ds/Icon/System/whereTo@2x.png",
        WhereTo_3x: "https://cdn.hopper.com/ds/Icon/System/whereTo@3x.png",
        Add: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Add/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Add/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Add/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Add/Solid@3x.png",
          Full_2x: "https://cdn.hopper.com/ds/Icon/System/Add/full@2x.png",
          Full_3x: "https://cdn.hopper.com/ds/Icon/System/Add/full@3x.png",
        },
        Airplane: {
          ComboFlight_2x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/comboFlight@2x.png",
          ComboFlight_3x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/comboFlight@3x.png",
          Diagonal_2x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/diagonal@2x.png",
          Diagonal_3x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/diagonal@3x.png",
          Down_2x: "https://cdn.hopper.com/ds/Icon/System/Airplane/down@2x.png",
          Down_3x: "https://cdn.hopper.com/ds/Icon/System/Airplane/down@3x.png",
          Left_2x: "https://cdn.hopper.com/ds/Icon/System/Airplane/left@2x.png",
          Left_3x: "https://cdn.hopper.com/ds/Icon/System/Airplane/left@3x.png",
          Right_2x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/right@2x.png",
          Right_3x:
            "https://cdn.hopper.com/ds/Icon/System/Airplane/right@3x.png",
        },
        Alert: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Alert/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Alert/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Alert/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Alert/Solid@3x.png",
        },
        Dismiss: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/Solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/Solid@3x.png",
          Hybrid_2x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/hybrid@2x.png",
          Hybrid_3x:
            "https://cdn.hopper.com/ds/Icon/System/Dismiss/hybrid@3x.png",
        },
        Home: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Home/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Home/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Home/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Home/Solid@3x.png",
        },
        Hotel: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Hotel/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Hotel/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Hotel/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Hotel/Solid@3x.png",
        },
        Info: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Info/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Info/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Info/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Info/Solid@3x.png",
        },
        Installments: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Installments/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Installments/Outline@3x.png",
        },
        Money: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Money/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Money/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Money/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Money/Solid@3x.png",
        },
        Monitoring: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Monitoring/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Monitoring/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/Monitoring/Solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/Monitoring/Solid@3x.png",
        },
        Remove: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Remove/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Remove/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Remove/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Remove/Solid@3x.png",
        },
        Star: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/Star/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/Star/Outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/Star/Solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/Star/Solid@3x.png",
        },
        AutoBuy: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/autoBuy/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/autoBuy/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/autoBuy/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/autoBuy/solid@3x.png",
        },
        Bed: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/bed/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/bed/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/bed/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/bed/solid@3x.png",
        },
        Calendar: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/calendar/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/calendar/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/calendar/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/calendar/solid@3x.png",
        },
        Camera: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/camera/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/camera/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/camera/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/camera/solid@3x.png",
        },
        CameraAdd: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/cameraAdd/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/cameraAdd/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/cameraAdd/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/cameraAdd/solid@3x.png",
        },
        Car: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/car/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/car/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/car/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/car/solid@3x.png",
        },
        CarSeat: {
          Booster_2x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/booster@2x.png",
          Booster_3x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/booster@3x.png",
          Child_2x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/child@2x.png",
          Child_3x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/child@3x.png",
          Infant_2x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/infant@2x.png",
          Infant_3x:
            "https://cdn.hopper.com/ds/Icon/System/carSeat/infant@3x.png",
        },
        CarrotCash: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/carrotCash/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/carrotCash/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/carrotCash/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/carrotCash/solid@3x.png",
        },
        Cart: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/cart/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/cart/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/cart/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/cart/solid@3x.png",
        },
        ChatBubble: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/chatBubble/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/chatBubble/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/chatBubble/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/chatBubble/solid@3x.png",
        },
        Check: {
          Full_2x: "https://cdn.hopper.com/ds/Icon/System/check/full@2x.png",
          Full_3x: "https://cdn.hopper.com/ds/Icon/System/check/full@3x.png",
          Hybrid_2x:
            "https://cdn.hopper.com/ds/Icon/System/check/hybrid@2x.png",
          Hybrid_3x:
            "https://cdn.hopper.com/ds/Icon/System/check/hybrid@3x.png",
          Large_2x: "https://cdn.hopper.com/ds/Icon/System/check/large@2x.png",
          Large_3x: "https://cdn.hopper.com/ds/Icon/System/check/large@3x.png",
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/check/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/check/outline@3x.png",
          Rounded_2x:
            "https://cdn.hopper.com/ds/Icon/System/check/rounded@2x.png",
          Rounded_3x:
            "https://cdn.hopper.com/ds/Icon/System/check/rounded@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/check/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/check/solid@3x.png",
        },
        CheckIn: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/checkIn/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/checkIn/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/checkIn/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/checkIn/solid@3x.png",
        },
        CheckOut: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/checkOut/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/checkOut/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/checkOut/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/checkOut/solid@3x.png",
        },
        Clock: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/clock/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/clock/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/clock/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/clock/solid@3x.png",
        },
        ComparablePhoto: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/comparablePhoto/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/comparablePhoto/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/comparablePhoto/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/comparablePhoto/solid@3x.png",
        },
        CrossOut: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/crossOut/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/crossOut/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/crossOut/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/crossOut/solid@3x.png",
        },
        DropdownActive: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownActive/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownActive/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownActive/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownActive/solid@3x.png",
        },
        DropdownDefault: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownDefault/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownDefault/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownDefault/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/dropdownDefault/solid@3x.png",
        },
        Edit: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/edit/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/edit/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/edit/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/edit/solid@3x.png",
        },
        Email: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/email/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/email/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/email/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/email/solid@3x.png",
        },
        Fire: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/fire/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/fire/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/fire/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/fire/solid@3x.png",
        },
        FlashDeal: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/flashDeal/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/flashDeal/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/flashDeal/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/flashDeal/solid@3x.png",
        },
        Friends: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/friends/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/friends/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/friends/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/friends/solid@3x.png",
        },
        Gift: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/gift/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/gift/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/gift/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/gift/solid@3x.png",
        },
        Headset: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/headset/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/headset/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/headset/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/headset/solid@3x.png",
        },
        Heart: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/heart/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/heart/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/heart/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/heart/solid@3x.png",
        },
        Help: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/help/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/help/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/help/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/help/solid@3x.png",
        },
        LdwInsurance: {
          Liability_2x:
            "https://cdn.hopper.com/ds/Icon/System/ldwInsurance/liability@2x.png",
          Liability_3x:
            "https://cdn.hopper.com/ds/Icon/System/ldwInsurance/liability@3x.png",
          MedicalTheft_2x:
            "https://cdn.hopper.com/ds/Icon/System/ldwInsurance/medicalTheft@2x.png",
          MedicalTheft_3x:
            "https://cdn.hopper.com/ds/Icon/System/ldwInsurance/medicalTheft@3x.png",
        },
        Location: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/location/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/location/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/location/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/location/solid@3x.png",
        },
        Lock: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/lock/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/lock/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/lock/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/lock/solid@3x.png",
        },
        Map: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/map/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/map/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/map/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/map/solid@3x.png",
        },
        Minigame: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/minigame/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/minigame/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/minigame/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/minigame/solid@3x.png",
        },
        Mission: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/mission/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/mission/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/mission/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/mission/solid@3x.png",
        },
        Mobile: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/mobile/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/mobile/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/mobile/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/mobile/solid@3x.png",
        },
        Moon: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/moon/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/moon/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/moon/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/moon/solid@3x.png",
        },
        MultiCity: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/multiCity/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/multiCity/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/multiCity/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/multiCity/solid@3x.png",
        },
        Notifications: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/notifications/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/notifications/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/notifications/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/notifications/solid@3x.png",
        },
        NotificationsNew: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/notificationsNew/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/notificationsNew/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/notificationsNew/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/notificationsNew/solid@3x.png",
        },
        Offers: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/offers/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/offers/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/offers/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/offers/solid@3x.png",
        },
        OpaqueRate: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/opaqueRate/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/opaqueRate/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/opaqueRate/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/opaqueRate/solid@3x.png",
        },
        PayLater: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/payLater/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/payLater/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/payLater/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/payLater/solid@3x.png",
        },
        Payment: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/payment/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/payment/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/payment/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/payment/solid@3x.png",
        },
        Phone: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/phone/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/phone/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/phone/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/phone/solid@3x.png",
        },
        Premium: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/premium/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/premium/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/premium/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/premium/solid@3x.png",
        },
        PriceDropProtection: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/priceDropProtection/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/priceDropProtection/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/priceDropProtection/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/priceDropProtection/solid@3x.png",
        },
        PriceFreeze: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/priceFreeze/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/priceFreeze/outline@3x.png",
        },
        PriceTag: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/priceTag/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/priceTag/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/priceTag/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/priceTag/solid@3x.png",
        },
        Profile: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/profile/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/profile/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/profile/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/profile/solid@3x.png",
        },
        RefundableTicket: {
          Hybrid_2x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/hybrid@2x.png",
          Hybrid_3x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/hybrid@3x.png",
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/refundableTicket/solid@3x.png",
        },
        Savings: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/savings/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/savings/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/savings/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/savings/solid@3x.png",
        },
        Search: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/search/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/search/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/search/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/search/solid@3x.png",
        },
        SecurityDeposit: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/securityDeposit/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/securityDeposit/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/securityDeposit/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/securityDeposit/solid@3x.png",
        },
        Settings: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/settings/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/settings/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/settings/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/settings/solid@3x.png",
        },
        Share: {
          Android_2x:
            "https://cdn.hopper.com/ds/Icon/System/share/Android@2x.png",
          Android_3x:
            "https://cdn.hopper.com/ds/Icon/System/share/Android@3x.png",
          IOS_2x: "https://cdn.hopper.com/ds/Icon/System/share/iOS@2x.png",
          IOS_3x: "https://cdn.hopper.com/ds/Icon/System/share/iOS@3x.png",
        },
        StoreA: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/storeA/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/storeA/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/storeA/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/storeA/solid@3x.png",
        },
        StoreB: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/storeB/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/storeB/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/storeB/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/storeB/solid@3x.png",
        },
        Tada: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/tada/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/tada/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/tada/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/tada/solid@3x.png",
        },
        TrophyA: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/trophyA/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/trophyA/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/trophyA/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/trophyA/solid@3x.png",
        },
        TrophyB: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/trophyB/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/trophyB/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/trophyB/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/trophyB/solid@3x.png",
        },
        Unlock: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/unlock/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/unlock/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/unlock/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/unlock/solid@3x.png",
        },
        UserMerchandising: {
          External_2x:
            "https://cdn.hopper.com/ds/Icon/System/userMerchandising/external@2x.png",
          External_3x:
            "https://cdn.hopper.com/ds/Icon/System/userMerchandising/external@3x.png",
          Internal_2x:
            "https://cdn.hopper.com/ds/Icon/System/userMerchandising/internal@2x.png",
          Internal_3x:
            "https://cdn.hopper.com/ds/Icon/System/userMerchandising/internal@3x.png",
        },
        Videofeed: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/videofeed/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/videofeed/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/videofeed/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/videofeed/solid@3x.png",
        },
        Voucher: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/voucher/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/voucher/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/System/voucher/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/System/voucher/solid@3x.png",
        },
        Wallet: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/wallet/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/wallet/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/wallet/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/wallet/solid@3x.png",
        },
        Wand: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/System/wand/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/System/wand/outline@3x.png",
          Solid_2x: "https://cdn.hopper.com/ds/Icon/System/wand/solid@2x.png",
          Solid_3x: "https://cdn.hopper.com/ds/Icon/System/wand/solid@3x.png",
        },
        Baggage: {
          CarryOn: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/carryOn/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/carryOn/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/carryOn/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/carryOn/solid@3x.png",
          },
          Checked: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/checked/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/checked/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/checked/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/checked/solid@3x.png",
          },
          Personal: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/personal/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/personal/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/personal/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/baggage/personal/solid@3x.png",
          },
        },
        Timer: {
          Default: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/timer/default/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/timer/default/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/timer/default/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/timer/default/solid@3x.png",
          },
          Flash: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/timer/flash/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/timer/flash/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/timer/flash/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/timer/flash/solid@3x.png",
          },
        },
        TripProtection: {
          Check: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/check/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/check/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/check/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/check/solid@3x.png",
          },
          Cross: {
            Outline_2x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/cross/outline@2x.png",
            Outline_3x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/cross/outline@3x.png",
            Solid_2x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/cross/solid@2x.png",
            Solid_3x:
              "https://cdn.hopper.com/ds/Icon/System/tripProtection/cross/solid@3x.png",
          },
        },
      },
      "Bun-Rating": {
        "01_2x": "https://cdn.hopper.com/ds/Icon/bun-rating/01@2x.png",
        "01_3x": "https://cdn.hopper.com/ds/Icon/bun-rating/01@3x.png",
        "02_2x": "https://cdn.hopper.com/ds/Icon/bun-rating/02@2x.png",
        "02_3x": "https://cdn.hopper.com/ds/Icon/bun-rating/02@3x.png",
        "03_2x": "https://cdn.hopper.com/ds/Icon/bun-rating/03@2x.png",
        "03_3x": "https://cdn.hopper.com/ds/Icon/bun-rating/03@3x.png",
        "04_2x": "https://cdn.hopper.com/ds/Icon/bun-rating/04@2x.png",
        "04_3x": "https://cdn.hopper.com/ds/Icon/bun-rating/04@3x.png",
        "05_2x": "https://cdn.hopper.com/ds/Icon/bun-rating/05@2x.png",
        "05_3x": "https://cdn.hopper.com/ds/Icon/bun-rating/05@3x.png",
      },
      Numbers: {
        "1_2x": "https://cdn.hopper.com/ds/Icon/numbers/1@2x.png",
        "1_3x": "https://cdn.hopper.com/ds/Icon/numbers/1@3x.png",
        "2_2x": "https://cdn.hopper.com/ds/Icon/numbers/2@2x.png",
        "2_3x": "https://cdn.hopper.com/ds/Icon/numbers/2@3x.png",
        "3_2x": "https://cdn.hopper.com/ds/Icon/numbers/3@2x.png",
        "3_3x": "https://cdn.hopper.com/ds/Icon/numbers/3@3x.png",
        "4_2x": "https://cdn.hopper.com/ds/Icon/numbers/4@2x.png",
        "4_3x": "https://cdn.hopper.com/ds/Icon/numbers/4@3x.png",
        "5_2x": "https://cdn.hopper.com/ds/Icon/numbers/5@2x.png",
        "5_3x": "https://cdn.hopper.com/ds/Icon/numbers/5@3x.png",
        Largefilled2_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled2@2x.png",
        Largefilled2_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled2@3x.png",
        Largefilled3_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled3@2x.png",
        Largefilled3_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled3@3x.png",
        Largefilled4_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled4@2x.png",
        Largefilled4_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled4@3x.png",
        Largefilled5_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled5@2x.png",
        Largefilled5_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largefilled5@3x.png",
        Largeflled1_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeflled1@2x.png",
        Largeflled1_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeflled1@3x.png",
        Largeunfilled1_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled1@2x.png",
        Largeunfilled1_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled1@3x.png",
        Largeunfilled2_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled2@2x.png",
        Largeunfilled2_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled2@3x.png",
        Largeunfilled3_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled3@2x.png",
        Largeunfilled3_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled3@3x.png",
        Largeunfilled4_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled4@2x.png",
        Largeunfilled4_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled4@3x.png",
        Largeunfilled5_2x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled5@2x.png",
        Largeunfilled5_3x:
          "https://cdn.hopper.com/ds/Icon/numbers/largeunfilled5@3x.png",
      },
      Ota: {
        Bookingcom_2x: "https://cdn.hopper.com/ds/Icon/ota/bookingcom@2x.png",
        Bookingcom_3x: "https://cdn.hopper.com/ds/Icon/ota/bookingcom@3x.png",
        Expedia_2x: "https://cdn.hopper.com/ds/Icon/ota/expedia@2x.png",
        Expedia_3x: "https://cdn.hopper.com/ds/Icon/ota/expedia@3x.png",
      },
      Payment: {
        Affirm_2x: "https://cdn.hopper.com/ds/Icon/payment/affirm@2x.png",
        Affirm_3x: "https://cdn.hopper.com/ds/Icon/payment/affirm@3x.png",
        Applepay_2x: "https://cdn.hopper.com/ds/Icon/payment/applepay@2x.png",
        Applepay_3x: "https://cdn.hopper.com/ds/Icon/payment/applepay@3x.png",
        Ideal_2x: "https://cdn.hopper.com/ds/Icon/payment/ideal@2x.png",
        Ideal_3x: "https://cdn.hopper.com/ds/Icon/payment/ideal@3x.png",
        Klarna_2x: "https://cdn.hopper.com/ds/Icon/payment/klarna@2x.png",
        Klarna_3x: "https://cdn.hopper.com/ds/Icon/payment/klarna@3x.png",
        Paypal_2x: "https://cdn.hopper.com/ds/Icon/payment/paypal@2x.png",
        Paypal_3x: "https://cdn.hopper.com/ds/Icon/payment/paypal@3x.png",
        Venmo_2x: "https://cdn.hopper.com/ds/Icon/payment/venmo@2x.png",
        Venmo_3x: "https://cdn.hopper.com/ds/Icon/payment/venmo@3x.png",
      },
      Social: {
        Apple_2x: "https://cdn.hopper.com/ds/Icon/social/apple@2x.png",
        Apple_3x: "https://cdn.hopper.com/ds/Icon/social/apple@3x.png",
        Facebook_2x: "https://cdn.hopper.com/ds/Icon/social/facebook@2x.png",
        Facebook_3x: "https://cdn.hopper.com/ds/Icon/social/facebook@3x.png",
        Google_2x: "https://cdn.hopper.com/ds/Icon/social/google@2x.png",
        Google_3x: "https://cdn.hopper.com/ds/Icon/social/google@3x.png",
        Whatsapp_2x: "https://cdn.hopper.com/ds/Icon/social/whatsapp@2x.png",
        Whatsapp_3x: "https://cdn.hopper.com/ds/Icon/social/whatsapp@3x.png",
      },
      Logo: {
        Hopper: {
          Mark_2x: "https://cdn.hopper.com/ds/Icon/Logo/hopper/mark@2x.png",
          Mark_3x: "https://cdn.hopper.com/ds/Icon/Logo/hopper/mark@3x.png",
          Primary_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/primary@2x.png",
          Primary_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/primary@3x.png",
          PrimaryWhite_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/primaryWhite@2x.png",
          PrimaryWhite_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/primaryWhite@3x.png",
          ProviderTransparent_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/provider-transparent@2x.png",
          ProviderTransparent_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/provider-transparent@3x.png",
          Provider_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/provider@2x.png",
          Provider_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/provider@3x.png",
          Stacked_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/stacked@2x.png",
          Stacked_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/hopper/stacked@3x.png",
          Type_2x: "https://cdn.hopper.com/ds/Icon/Logo/hopper/type@2x.png",
          Type_3x: "https://cdn.hopper.com/ds/Icon/Logo/hopper/type@3x.png",
        },
        HtsPowered: {
          Colour_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/colour@2x.png",
          Colour_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/colour@3x.png",
          ColourBkg_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/colourBkg@2x.png",
          ColourBkg_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/colourBkg@3x.png",
          Grayscale_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/grayscale@2x.png",
          Grayscale_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/grayscale@3x.png",
          GrayscaleBkg_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/grayscaleBkg@2x.png",
          GrayscaleBkg_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/grayscaleBkg@3x.png",
          White_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/white@2x.png",
          White_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsPowered/white@3x.png",
        },
        HtsProvided: {
          Colour_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/colour@2x.png",
          Colour_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/colour@3x.png",
          ColourBkg_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/colourBkg@2x.png",
          ColourBkg_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/colourBkg@3x.png",
          Grayscale_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/grayscale@2x.png",
          Grayscale_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/grayscale@3x.png",
          GrayscaleBkg_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/grayscaleBkg@2x.png",
          GrayscaleBkg_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/grayscaleBkg@3x.png",
          White_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/white@2x.png",
          White_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/htsProvided/white@3x.png",
        },
        Xcover: {
          Primary_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/xcover/primary@2x.png",
          Primary_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/xcover/primary@3x.png",
          Provider_2x:
            "https://cdn.hopper.com/ds/Icon/Logo/xcover/provider@2x.png",
          Provider_3x:
            "https://cdn.hopper.com/ds/Icon/Logo/xcover/provider@3x.png",
        },
      },
      Prediction: {
        Decreasing: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Decreasing/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Decreasing/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Decreasing/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Decreasing/solid@3x.png",
        },
        Departure: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Departure/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Departure/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Departure/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Departure/solid@3x.png",
        },
        Increasing: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Increasing/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Increasing/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Increasing/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Increasing/solid@3x.png",
        },
        Mixed: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Mixed/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Mixed/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Mixed/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Mixed/solid@3x.png",
        },
        Raise: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Raise/Outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Raise/Outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Raise/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/Raise/solid@3x.png",
        },
        Freeze: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/freeze/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/freeze/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/freeze/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/freeze/solid@3x.png",
        },
        Watch: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/watch/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/watch/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/Prediction/watch/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/Prediction/watch/solid@3x.png",
        },
      },
      Cards: {
        Rectangular: {
          Add_2x: "https://cdn.hopper.com/ds/Icon/cards/rectangular/add@2x.png",
          Add_3x: "https://cdn.hopper.com/ds/Icon/cards/rectangular/add@3x.png",
          Amex_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/amex@2x.png",
          Amex_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/amex@3x.png",
          Apple_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/apple@2x.png",
          Apple_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/apple@3x.png",
          Atome_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/atome@2x.png",
          Atome_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/atome@3x.png",
          Diners_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/diners@2x.png",
          Diners_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/diners@3x.png",
          Discover_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/discover@2x.png",
          Discover_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/discover@3x.png",
          Inactive_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/inactive@2x.png",
          Inactive_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/inactive@3x.png",
          Mastercard_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/mastercard@2x.png",
          Mastercard_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/mastercard@3x.png",
          Paypal_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/paypal@2x.png",
          Paypal_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/paypal@3x.png",
          Paytm_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/paytm@2x.png",
          Paytm_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/paytm@3x.png",
          Placeholder_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/placeholder@2x.png",
          Placeholder_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/placeholder@3x.png",
          Unknown_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/unknown@2x.png",
          Unknown_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/unknown@3x.png",
          Upi_2x: "https://cdn.hopper.com/ds/Icon/cards/rectangular/upi@2x.png",
          Upi_3x: "https://cdn.hopper.com/ds/Icon/cards/rectangular/upi@3x.png",
          Visa_2x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/visa@2x.png",
          Visa_3x:
            "https://cdn.hopper.com/ds/Icon/cards/rectangular/visa@3x.png",
        },
        Square: {
          ICICI_2x: "https://cdn.hopper.com/ds/Icon/cards/square/ICICI@2x.png",
          ICICI_3x: "https://cdn.hopper.com/ds/Icon/cards/square/ICICI@3x.png",
          NetBankingIndia_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/NetBanking-India@2x.png",
          NetBankingIndia_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/NetBanking-India@3x.png",
          Add_2x: "https://cdn.hopper.com/ds/Icon/cards/square/add@2x.png",
          Add_3x: "https://cdn.hopper.com/ds/Icon/cards/square/add@3x.png",
          Affirm_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/affirm@2x.png",
          Affirm_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/affirm@3x.png",
          Amex_2x: "https://cdn.hopper.com/ds/Icon/cards/square/amex@2x.png",
          Amex_3x: "https://cdn.hopper.com/ds/Icon/cards/square/amex@3x.png",
          Apple_2x: "https://cdn.hopper.com/ds/Icon/cards/square/apple@2x.png",
          Apple_3x: "https://cdn.hopper.com/ds/Icon/cards/square/apple@3x.png",
          Atome_2x: "https://cdn.hopper.com/ds/Icon/cards/square/atome@2x.png",
          Atome_3x: "https://cdn.hopper.com/ds/Icon/cards/square/atome@3x.png",
          Diners_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/diners@2x.png",
          Diners_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/diners@3x.png",
          Discover_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/discover@2x.png",
          Discover_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/discover@3x.png",
          Hdfc_2x: "https://cdn.hopper.com/ds/Icon/cards/square/hdfc@2x.png",
          Hdfc_3x: "https://cdn.hopper.com/ds/Icon/cards/square/hdfc@3x.png",
          Ideal_2x: "https://cdn.hopper.com/ds/Icon/cards/square/ideal@2x.png",
          Ideal_3x: "https://cdn.hopper.com/ds/Icon/cards/square/ideal@3x.png",
          Inactive_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/inactive@2x.png",
          Inactive_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/inactive@3x.png",
          Klarna_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/klarna@2x.png",
          Klarna_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/klarna@3x.png",
          Mastercard_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/mastercard@2x.png",
          Mastercard_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/mastercard@3x.png",
          Paypal_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/paypal@2x.png",
          Paypal_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/paypal@3x.png",
          Paytm_2x: "https://cdn.hopper.com/ds/Icon/cards/square/paytm@2x.png",
          Paytm_3x: "https://cdn.hopper.com/ds/Icon/cards/square/paytm@3x.png",
          Placeholder_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/placeholder@2x.png",
          Placeholder_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/placeholder@3x.png",
          Postpaid_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/postpaid@2x.png",
          Postpaid_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/postpaid@3x.png",
          Sbi_2x: "https://cdn.hopper.com/ds/Icon/cards/square/sbi@2x.png",
          Sbi_3x: "https://cdn.hopper.com/ds/Icon/cards/square/sbi@3x.png",
          Unknown_2x:
            "https://cdn.hopper.com/ds/Icon/cards/square/unknown@2x.png",
          Unknown_3x:
            "https://cdn.hopper.com/ds/Icon/cards/square/unknown@3x.png",
          Upi_2x: "https://cdn.hopper.com/ds/Icon/cards/square/upi@2x.png",
          Upi_3x: "https://cdn.hopper.com/ds/Icon/cards/square/upi@3x.png",
          Venmo_2x: "https://cdn.hopper.com/ds/Icon/cards/square/venmo@2x.png",
          Venmo_3x: "https://cdn.hopper.com/ds/Icon/cards/square/venmo@3x.png",
          Visa_2x: "https://cdn.hopper.com/ds/Icon/cards/square/visa@2x.png",
          Visa_3x: "https://cdn.hopper.com/ds/Icon/cards/square/visa@3x.png",
        },
      },
      FareClass: {
        BasicEconomy: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/basicEconomy/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/basicEconomy/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/basicEconomy/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/basicEconomy/solid@3x.png",
        },
        Business: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/business/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/business/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/business/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/business/solid@3x.png",
        },
        Economy: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/economy/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/economy/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/economy/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/economy/solid@3x.png",
        },
        FirstClass: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/firstClass/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/firstClass/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/firstClass/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/firstClass/solid@3x.png",
        },
        PremiumEconomy: {
          Outline_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/premiumEconomy/outline@2x.png",
          Outline_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/premiumEconomy/outline@3x.png",
          Solid_2x:
            "https://cdn.hopper.com/ds/Icon/fareClass/premiumEconomy/solid@2x.png",
          Solid_3x:
            "https://cdn.hopper.com/ds/Icon/fareClass/premiumEconomy/solid@3x.png",
        },
      },
    },
    Spot: {
      "Price-Freeze": {
        "01Bg_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/01-bg@2x.png",
        "01Bg_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/01-bg@3x.png",
        "01_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/01@2x.png",
        "01_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/01@3x.png",
        "02Bg_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/02-bg@2x.png",
        "02Bg_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/02-bg@3x.png",
        "02_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/02@2x.png",
        "02_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/02@3x.png",
        "03Bg_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/03-bg@2x.png",
        "03Bg_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/03-bg@3x.png",
        "03_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/03@2x.png",
        "03_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/03@3x.png",
        "04Bg_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/04-bg@2x.png",
        "04Bg_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/04-bg@3x.png",
        "04_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/04@2x.png",
        "04_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/04@3x.png",
        "05Bg_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/05-bg@2x.png",
        "05Bg_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/05-bg@3x.png",
        "05_2x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/05@2x.png",
        "05_3x": "https://cdn.hopper.com/ds/Spot/Price-Freeze/05@3x.png",
      },
      "Secret-Gift": {
        "01Closed_2x":
          "https://cdn.hopper.com/ds/Spot/Secret-Gift/01-Closed@2x.png",
        "01Closed_3x":
          "https://cdn.hopper.com/ds/Spot/Secret-Gift/01-Closed@3x.png",
        "02Opened_2x":
          "https://cdn.hopper.com/ds/Spot/Secret-Gift/02-Opened@2x.png",
        "02Opened_3x":
          "https://cdn.hopper.com/ds/Spot/Secret-Gift/02-Opened@3x.png",
        "30Off_2x": "https://cdn.hopper.com/ds/Spot/Secret-Gift/30-Off@2x.png",
        "30Off_3x": "https://cdn.hopper.com/ds/Spot/Secret-Gift/30-Off@3x.png",
      },
      "Loot-Box": {
        Default: {
          Gold: {
            "01Closed_2x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Gold/01-Closed@2x.png",
            "01Closed_3x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Gold/01-Closed@3x.png",
            "02Opened_2x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Gold/02-Opened@2x.png",
            "02Opened_3x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Gold/02-Opened@3x.png",
          },
          Silver: {
            "01Closed_2x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Silver/01-Closed@2x.png",
            "01Closed_3x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Silver/01-Closed@3x.png",
            "02Opened_2x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Silver/02-Opened@2x.png",
            "02Opened_3x":
              "https://cdn.hopper.com/ds/Spot/Loot-Box/Default/Silver/02-Opened@3x.png",
          },
        },
      },
    },
    Title: {
      Illustration: {
        Offers_2x: "https://cdn.hopper.com/ds/Title/Illustration/Offers@2x.png",
        Offers_3x: "https://cdn.hopper.com/ds/Title/Illustration/Offers@3x.png",
      },
    },
    Logo: {
      Rewards: {
        PrimaryBlack_2x:
          "https://cdn.hopper.com/ds/logo/rewards/primaryBlack@2x.png",
        PrimaryBlack_3x:
          "https://cdn.hopper.com/ds/logo/rewards/primaryBlack@3x.png",
        PrimaryWhite_2x:
          "https://cdn.hopper.com/ds/logo/rewards/primaryWhite@2x.png",
        PrimaryWhite_3x:
          "https://cdn.hopper.com/ds/logo/rewards/primaryWhite@3x.png",
        TaglineBlack_2x:
          "https://cdn.hopper.com/ds/logo/rewards/taglineBlack@2x.png",
        TaglineBlack_3x:
          "https://cdn.hopper.com/ds/logo/rewards/taglineBlack@3x.png",
        TaglineWhite_2x:
          "https://cdn.hopper.com/ds/logo/rewards/taglineWhite@2x.png",
        TaglineWhite_3x:
          "https://cdn.hopper.com/ds/logo/rewards/taglineWhite@3x.png",
      },
    },
  },
} as const;
