"use client";

import {
  Breakpoints,
  BreakpointsOptions,
  CSSObject,
  Components,
  PaletteOptions,
  ThemeOptions,
  TransitionsOptions,
  autocompleteClasses,
  createTheme,
  filledInputClasses,
  formHelperTextClasses,
  iconButtonClasses,
  inputAdornmentClasses,
  inputLabelClasses,
  menuItemClasses,
  toggleButtonClasses,
  toggleButtonGroupClasses,
  typographyClasses,
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { SpacingOptions } from "@mui/system/createTheme/createSpacing";
import ProximaNovaBoldOtf from "./assets/fonts/Proxima-Nova-Bold.otf";
import ProximaNovaExtraBoldOtf from "./assets/fonts/Proxima-Nova-Extrabold.otf";
import ProximaNovaMediumOtf from "./assets/fonts/Proxima-Nova-Medium.otf";
import ProximaNovaRegularOtf from "./assets/fonts/Proxima-Nova-Regular.otf";
import ProximaNovaSemiBoldOtf from "./assets/fonts/Proxima-Nova-Semibold.otf";
import globalStyles from "./global.module.css";
import { tokens } from "./tokens";

const palette: PaletteOptions = {
  primary: {
    main: tokens.primary.main,
    contrastText: tokens.primary.contrast,
  },
  text: {
    primary: tokens.text.primary,
    secondary: tokens.text.secondary,
    disabled: tokens.text.disabled,
  },
};

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 375,
    md: 744,
    lg: 992,
    xl: 1200,
    mobile: 0,
    tablet: 744,
    desktop: 992,
  },
};

const spacing: SpacingOptions = (token) => (token * 2) / 25;

const bp: Breakpoints = createBreakpoints(breakpoints);

const transitions: TransitionsOptions = {
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  },
  easing: {
    easeInOut: "cubic-bezier(0.77,0,0.18,1)",
    easeOut: "cubic-bezier(0.17,0.84,0.44,1)",
    easeIn: "cubic-bezier(0.9,0.03,0.69,0.22)",
  },
};

const typography: TypographyOptions = {
  fontFamily: [tokens.typography.fontFamily.main, "sans-serif"].join(","),
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  caption: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: 13,
    lineHeight: 16 / 13,
    color: tokens.text.secondary,
  },
  button: undefined,
  overline: undefined,
  displayLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displayLg,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displayLg,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displayLg,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displayLg,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displayLg,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displayLg,
    },
  },
  displayMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displayMd,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displayMd,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displayMd,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displayMd,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displayMd,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displayMd,
    },
  },
  displaySmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.extraBold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.displaySm,
      lineHeight: tokens.responsive.mobile.fontLineHeight.displaySm,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.displaySm,
      lineHeight: tokens.responsive.tablet.fontLineHeight.displaySm,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.displaySm,
      lineHeight: tokens.responsive.desktop.fontLineHeight.displaySm,
    },
  },
  headlineLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineLg,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineLg,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineLg,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineLg,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineLg,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineLg,
    },
  },
  headlineMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineMd,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineMd,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineMd,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineMd,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineMd,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineMd,
    },
  },
  headlineSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    [bp.up("mobile")]: {
      fontSize: tokens.responsive.mobile.fontSize.headlineSm,
      lineHeight: tokens.responsive.mobile.fontLineHeight.headlineSm,
    },
    [bp.up("tablet")]: {
      fontSize: tokens.responsive.tablet.fontSize.headlineSm,
      lineHeight: tokens.responsive.tablet.fontLineHeight.headlineSm,
    },
    [bp.up("desktop")]: {
      fontSize: tokens.responsive.desktop.fontSize.headlineSm,
      lineHeight: tokens.responsive.desktop.fontLineHeight.headlineSm,
    },
  },
  titleLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[5],
    lineHeight: tokens.typography.fontLineHeight[5],
  },
  titleMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  titleSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[2],
  },
  bodyLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  bodyMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  bodySmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
  },
  labelLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  labelMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  labelSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: tokens.typography.fontSize[2] * 0.017,
  },
  labelSmallEmphasis: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: tokens.typography.fontSize[2] * 0.017,
  },
  labelExtraSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[1],
    lineHeight: tokens.typography.fontLineHeight[1],
    letterSpacing: tokens.typography.fontSize[1] * 0.017,
  },
  buttonLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[5],
    lineHeight: tokens.typography.fontLineHeight[5],
  },
  buttonMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
  },
  buttonSmall: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.bold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  buttonLink: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  inputValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  inputHelper: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[2],
    lineHeight: tokens.typography.fontLineHeight[2],
    letterSpacing: 0.2,
  },
  inputLabel: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  inputLabelError: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  searchInputValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  searchInputSecondaryValue: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[4],
    letterSpacing: tokens.typography.fontSize[4] * -0.01,
  },
  searchInputLabel: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    lineHeight: "9px",
  },
  priceLarge: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[6],
    lineHeight: tokens.typography.fontLineHeight[6],
    letterSpacing: tokens.typography.fontSize[6] * -0.01,
  },
  priceMedium: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.semiBold,
    fontSize: tokens.typography.fontSize[4],
    lineHeight: tokens.typography.fontLineHeight[3],
  },
  priceStrikethrough: {
    fontFamily: tokens.typography.fontFamily.main,
    fontWeight: tokens.typography.fontWeight.regular,
    fontSize: tokens.typography.fontSize[3],
    textDecoration: "line-through",
    letterSpacing: "-0.56px",
  },
};

const searchFieldStyles: (leadingSpacing?: number) => CSSObject = (
  leadingSpacing = tokens.spacing[250]
) => ({
  [`.${autocompleteClasses.root} &, &`]: {
    ...typography.searchInputValue,
    "& ::placeholder": {
      ...typography.searchInputSecondaryValue,
    },
    [`.${filledInputClasses.root}`]: {
      backgroundColor: tokens.component.input.surfaceResting,
      borderRadius: "1000px",
      border: "1px solid transparent",
      overflow: "hidden",
      padding: 0,
      [`.${autocompleteClasses.root} &`]: {
        paddingRight: "40px",
      },
    },
    [`input.${filledInputClasses.input}, input.${autocompleteClasses.input}`]: {
      paddingTop: "25px",
      paddingLeft: leadingSpacing,
      "&:-webkit-autofill": {
        borderRadius: "0px",
      },
    },
    [`&:hover .${filledInputClasses.root}:not(.${filledInputClasses.disabled})`]:
      {
        backgroundColor: tokens.component.searchInput.surfaceHover,
      },
    [`&:focus-within .${filledInputClasses.root}:not(.${filledInputClasses.error}), & .${filledInputClasses.focused}.${filledInputClasses.root}:not(.${filledInputClasses.error})`]:
      {
        border: "1px solid transparent",
        margin: 0,
        backgroundColor: tokens.component.searchInput.surfaceFocused,
      },

    [`& .${filledInputClasses.error}`]: {
      [`&.${filledInputClasses.root}`]: {
        borderColor: tokens.component.input.borderError,
        backgroundColor: tokens.component.input.surfaceError,
      },
      [`&.${inputLabelClasses.root}, &.${formHelperTextClasses.root}`]: {
        color: tokens.error.main,
      },
      [`&.${inputLabelClasses.shrink}`]: {
        fontWeight: tokens.typography.fontWeight.bold,
      },
    },
    [`.${inputLabelClasses.root}`]: {
      transform: `translate(${leadingSpacing}px, 16px) scale(1)`,
    },
    [`.${inputLabelClasses.shrink}`]: {
      transform: `translate(${leadingSpacing}px, 9px) scale(.875)`,
    },
  },
});

const components: Components = {
  MuiScopedCssBaseline: {
    styleOverrides: {
      root: `
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local("Proxima Nova Regular"), 
          url(https://cdn.hopper.com/fonts/proximanova-regular.otf) format("opentype"),
          url(${ProximaNovaRegularOtf}) format("opentype");
      }
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: local("Proxima Nova Medium"), 
          url(https://cdn.hopper.com/fonts/proximanova-medium.otf) format("opentype"),
          url(${ProximaNovaMediumOtf}) format("opentype");
      }
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: local("Proxima Nova Semibold"), 
          url(https://cdn.hopper.com/fonts/proximanova-semibold.otf) format("opentype"),
          url(${ProximaNovaSemiBoldOtf}) format("opentype");
      }
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local("Proxima Nova Bold"), 
          url(https://cdn.hopper.com/fonts/proximanova-bold.otf) format("opentype"),
          url(${ProximaNovaBoldOtf}) format("opentype");
      }
      @font-face {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 800;
        font-display: swap;
        src: local("Proxima Nova Extrabold"), 
          url(https://cdn.hopper.com/fonts/proximanova-extrabold.otf) format("opentype"),
          url(${ProximaNovaExtraBoldOtf}) format("opentype");
      }
      @property --button-background-1 {
        syntax: "<color>";
        inherits: false;
        initial-value: #FED955;
      }
      @property --button-background-2 {
        syntax: "<color>";
        inherits: false;
        initial-value: #FDBE33;
      }
      background-color: transparent;
      ${globalStyles}
    `,
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "xl",
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&:first-of-type": {
          borderTopLeftRadius: tokens.cornerRadius.radiusMd,
          borderTopRightRadius: tokens.cornerRadius.radiusMd,
        },
        "&:not(:first-of-type)": {
          borderTop: "none",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: tokens.cornerRadius.radiusMd,
          borderBottomRightRadius: tokens.cornerRadius.radiusMd,
        },
        "&:before": {
          display: "none",
        },
        display: "flex",
        flexDirection: "column",
        padding: 0,
        border: `1px solid ${tokens.surface.borderDivider}`,
        boxShadow: "none",
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 0,
        padding: `${tokens.spacing[250]}px ${tokens.spacing[200]}px`,
        "&.Mui-expanded": {
          minHeight: 0,
        },
      },
      content: {
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: `0 ${tokens.spacing[200]}px ${tokens.spacing[250]}px`,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: {
        padding: `16px ${tokens.container.paddingFluidAlt}`,
        [`& .${autocompleteClasses.option}`]: {
          "&.Mui-focused": {
            backgroundColor: tokens.component.input.surfaceHover,
          },
          [`&[aria-selected="true"], &.Mui-focused[aria-selected="true"]`]: {
            backgroundColor: tokens.component.input.surfacePressed,
          },
        },
      },
      option: {
        padding: `${tokens.spacing[100]}px 24px`,
      },
      paper: {
        borderRadius: tokens.cornerRadius.radiusXl,
        backgroundColor: tokens.surface.main,
        boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.25)",
      },
      popper: {
        paddingTop: `${tokens.spacing[150]}px`,
      },
      noOptions: {
        ...typography.labelLarge,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        marginLeft: tokens.spacing[150],
        marginRight: tokens.spacing[150],
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "filled",
      size: "medium",
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        gap: tokens.spacing[100],
        "& .MuiButton-endIcon": {
          marginLeft: 0,
        },
      },
    },
    variants: [
      {
        props: { variant: "filled" },
        style: {
          color: tokens.primary.contrast,
          background: tokens.surface.primaryResting,
          "&:hover": {
            background: tokens.surface.primaryHover,
          },
          "&:active": {
            background: tokens.surface.primaryPressed,
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
            background: tokens.component.control.surfaceDisabled,
          },
        },
      },
      {
        props: { variant: "filled", color: "secondary" },
        style: {
          color: tokens.primary.main,
          background: tokens.surface.main,
          "&:hover": {
            color: tokens.primary.main,
            background: tokens.surface.mainHover,
          },
          "&:active": {
            color: tokens.primary.main,
            background: tokens.component.control.surfacePressed,
          },
          "&:focus-visible": {
            color: tokens.primary.main,
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
            background: tokens.component.control.surfaceDisabled,
          },
        },
      },
      {
        props: { variant: "outlined" },
        style: {
          color: tokens.primary.main,
          border: `1px solid ${tokens.primary.main}`,
          "&:hover": {
            background: tokens.surface.primaryTintHover,
          },
          "&:active": {
            background: tokens.surface.primaryTintPressed,
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px ${tokens.primary.main}`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
            background: tokens.component.control.surfaceDisabled,
          },
        },
      },
      {
        props: { variant: "outlined", color: "secondary" },
        style: {
          color: tokens.primary.contrast,
          border: `1px solid ${tokens.primary.contrast}`,
          "&:hover": {
            color: tokens.primary.contrast,
            border: `1px solid ${tokens.primary.contrast}`,
            background: tokens.surface.primaryHover,
          },
          "&:active": {
            color: tokens.primary.contrast,
            border: `1px solid ${tokens.primary.contrast}`,
            background: tokens.surface.primaryPressed,
          },
          "&:focus-visible": {
            color: tokens.primary.contrast,
            border: `1px solid ${tokens.primary.contrast}`,
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
            background: tokens.component.control.surfaceDisabled,
          },
        },
      },
      {
        props: { variant: "text" },
        style: {
          color: tokens.primary.main,
          "&:hover": {
            background: tokens.component.control.surfaceHover,
          },
          "&:active": {
            background: tokens.component.control.surfacePressed,
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
          },
        },
      },
      {
        props: { variant: "text", color: "secondary" },
        style: {
          color: tokens.primary.contrast,
          "&:hover": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryHover,
          },
          "&:active": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryPressed,
          },
          "&:focus-visible": {
            color: tokens.primary.contrast,
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
          },
        },
      },
      {
        props: { variant: "secondary" },
        style: {
          color: tokens.text.primary,
          "&:hover": {
            background: tokens.component.control.surfaceHover,
          },
          "&:active": {
            background: tokens.component.control.surfacePressed,
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
          },
        },
      },
      {
        props: { variant: "secondary", color: "secondary" },
        style: {
          color: tokens.primary.contrast,
          "&:hover": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryHover,
          },
          "&:active": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryPressed,
          },
          "&:focus-visible": {
            color: tokens.primary.contrast,
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
          },
        },
      },
      {
        props: { variant: "ghost" },
        style: {
          "&:hover": {
            background: "transparent",
          },
          "&:active": {
            background: "transparent",
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
          },
        },
      },
      {
        props: { variant: "tdt" },
        style: {
          color: "#181579",
          background:
            "linear-gradient(90deg, var(--button-background-1) 0%, var(--button-background-2) 100%)",
          transition: `
            --button-background-1 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            --button-background-2 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            color ${transitions.duration.short}ms ${transitions.easing.easeInOut}
          `,
          "&:hover": {
            "--button-background-1": "#F6D252",
            "--button-background-2": "#F5B831",
          },
          "&:active": {
            "--button-background-1": "#EECC50",
            "--button-background-2": "#EDB230",
          },
          "&:focus-visible": {
            boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px #FED955`,
          },
          "&:disabled": {
            "--button-background-1": "#FECF85",
            "--button-background-2": "#FDCB66",
          },
        },
      },
      {
        props: { variant: "tdtSuccess" },
        style: {
          "--button-background-1": tokens.colors.green[10],
          "--button-background-2": tokens.colors.green[10],
          color: tokens.colors.green[60],
          background:
            "linear-gradient(90deg, var(--button-background-1) 0%, var(--button-background-2) 100%)",
          transition: `
            --button-background-1 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            --button-background-2 ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            box-shadow ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            border-color ${transitions.duration.short}ms ${transitions.easing.easeInOut},
            color ${transitions.duration.short}ms ${transitions.easing.easeInOut}
          `,
          "&:disabled": {
            color: tokens.colors.green[60],
            "--button-background-1": tokens.colors.green[10],
            "--button-background-2": tokens.colors.green[10],
          },
        },
      },
      {
        props: { size: "large" },
        style: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
          borderRadius: tokens.cornerRadius.radiusXl,
          minHeight: "52px",
          ...typography.buttonLarge,
        },
      },
      {
        props: { size: "medium" },
        style: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[300]}px`,
          borderRadius: tokens.cornerRadius.radiusLg,
          minHeight: "44px",
          ...typography.buttonMedium,
        },
      },
      {
        props: { size: "small" },
        style: {
          padding: `${tokens.spacing[50]}px ${tokens.spacing[200]}px`,
          borderRadius: tokens.cornerRadius.radiusMd,
          minHeight: "34px",
          ...typography.buttonSmall,
        },
      },
      {
        props: { size: "link" },
        style: {
          padding: 0,
          color: tokens.text.link,
          ...typography.buttonLink,
        },
      },
      {
        props: { size: "search" },
        style: {
          [bp.down("tablet")]: {
            padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
            borderRadius: tokens.cornerRadius.radiusXl,
            minHeight: "52px",
            ...typography.buttonLarge,
          },
          [bp.up("tablet")]: {
            padding: `${tokens.spacing[100]}px ${tokens.spacing[250]}px`,
            borderRadius: "1000px",
            minHeight: "50px",
            maxHeight: "50px",
            minWidth: "50px",
            maxWidth: "100%",
            justifyContent: "flex-start",
            overflow: "hidden",
            "& svg": {
              width: "24px",
              height: "24px",
              flexShrink: 0,
            },
            "& :not(svg):not(svg *)": {
              opacity: 1,
              transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
            },
            transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
            transitionProperty:
              "max-width, padding, opacity, transform, background-color",
            ...typography.buttonLarge,
          },
        },
      },
      {
        props: { size: "searchCollapsed" },
        style: {
          [bp.down("tablet")]: {
            padding: `${tokens.spacing[100]}px ${tokens.spacing[400]}px`,
            borderRadius: tokens.cornerRadius.radiusXl,
            minHeight: "52px",
            ...typography.buttonLarge,
          },
          [bp.up("tablet")]: {
            padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
            borderRadius: "1000px",
            minHeight: "50px",
            maxHeight: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            justifyContent: "flex-start",
            overflow: "hidden",
            "& svg": {
              width: "24px",
              height: "24px",
              flexShrink: 0,
              transform: `translateX(5px)`,
              transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
            },
            "& :not(svg):not(svg *)": {
              opacity: 0,
              transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
            },
            [`& .${typographyClasses.root}`]: {
              visibility: "hidden",
            },
            transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
            transitionProperty:
              "max-width, padding, opacity, transform, background-color",
            ...typography.buttonLarge,
          },
        },
      },
      {
        props: { size: "searchCollapsedMini" },
        style: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
          borderRadius: "1000px",
          minHeight: "36px",
          maxHeight: "36px",
          minWidth: "36px",
          maxWidth: "36px",
          justifyContent: "flex-start",
          overflow: "hidden",
          "& svg": {
            width: "18px",
            height: "18px",
            flexShrink: 0,
            transform: `translateX(1px)`,
            transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          },
          "& :not(svg):not(svg *)": {
            opacity: 0,
            transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          },
          [`& .${typographyClasses.root}`]: {
            visibility: "hidden",
          },
          transition: `all ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
          transitionProperty:
            "max-width, padding, opacity, transform, background-color",
          ...typography.buttonLarge,
        },
      },
      {
        props: { size: "day" },
        style: {
          padding: `${tokens.spacing[150]}px ${tokens.spacing[100]}px`,
          borderRadius: tokens.cornerRadius.radiusMd,
          minHeight: "40px",
          minWidth: "40px",
          maxHeight: "40px",
          maxWidth: "40px",
          ...typography.labelMedium,
        },
      },
      {
        props: { size: "valueStepper" },
        style: {
          padding: `${tokens.spacing[100]}px ${tokens.spacing[100]}px`,
          minHeight: "32px",
          minWidth: "32px",
          borderRadius: "100px",
          border: `1px solid ${tokens.surface.borderDivider}`,
          backgroundColor: tokens.component.control.surfaceResting,
        },
      },
      {
        props: { size: "menu" },
        style: {
          justifyContent: "flex-start",
          paddingTop: `${tokens.spacing[250]}px`,
          paddingBottom: `${tokens.spacing[250]}px`,
          [bp.up("mobile")]: {
            paddingLeft: `${tokens.spacing[tokens.container.padding.mobile]}px`,
            paddingRight: `${
              tokens.spacing[tokens.container.padding.mobile]
            }px`,
          },
          [bp.up("tablet")]: {
            paddingLeft: `${tokens.spacing[tokens.container.padding.tablet]}px`,
            paddingRight: `${
              tokens.spacing[tokens.container.padding.tablet]
            }px`,
          },
          [bp.up("desktop")]: {
            paddingLeft: `${
              tokens.spacing[tokens.container.padding.desktop]
            }px`,
            paddingRight: `${
              tokens.spacing[tokens.container.padding.desktop]
            }px`,
          },
          ...typography.buttonMedium,
        },
      },
      {
        props: { size: "ghost" },
        style: {
          padding: 0,
          gap: 0,
          justifyContent: "stretch",
          alignItems: "stretch",
          minWidth: 0,
          borderRadius: 0,
        },
      },
      {
        props: { size: "large", variant: "secondary" },
        style: {
          paddingLeft: `${tokens.spacing[250]}px`,
          paddingRight: `${tokens.spacing[250]}px`,
        },
      },
      {
        props: { size: "medium", variant: "secondary" },
        style: {
          paddingLeft: `${tokens.spacing[200]}px`,
          paddingRight: `${tokens.spacing[200]}px`,
        },
      },
      {
        props: { size: "small", variant: "secondary" },
        style: {
          paddingLeft: `${tokens.spacing[150]}px`,
          paddingRight: `${tokens.spacing[150]}px`,
        },
      },
    ],
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      size: "medium",
    },
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: 0,
        gap: tokens.spacing[100],
        alignItems: "center",
      },
      label: {
        ...typography.labelMedium,
        flexGrow: 1,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...typography.titleMedium,
        color: tokens.text.primary,
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        gap: tokens.spacing[200],
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: `${tokens.spacing[100]}px`,
        color: tokens.text.primary,
        "&:hover": {
          background: tokens.component.control.surfaceHover,
        },
        "&:active": {
          background: tokens.component.control.surfacePressed,
        },
        "&:focus-visible": {
          boxShadow: `0px 0px 0px 2px ${tokens.primary.contrast}, 0px 0px 0px 4px rgba(0, 116, 218, 0.40);`,
        },
        "&:disabled": {
          color: tokens.text.disabled,
        },
      },
    },
    variants: [
      {
        props: { color: "secondary" },
        style: {
          color: tokens.primary.contrast,
          "&:hover": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryHover,
          },
          "&:active": {
            color: tokens.primary.contrast,
            background: tokens.surface.primaryPressed,
          },
          "&:focus-visible": {
            color: tokens.primary.contrast,
            boxShadow: `0px 0px 0px 2px ${tokens.primary.main}, 0px 0px 0px 4px rgba(255, 255, 255, 0.40)`,
          },
          "&:disabled": {
            color: tokens.text.disabled,
          },
        },
      },
    ],
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: tokens.cornerRadius.radiusMd,
        border: `1px solid ${tokens.component.input.borderResting}`,
        boxShadow: "none",
        marginTop: tokens.spacing[50],
      },
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        ...typography.inputValue,
        display: "flex",
        justifyContent: "space-between",
        color: tokens.text.secondary,
        padding: `${tokens.spacing[100]}px ${tokens.spacing[250]}px ${tokens.spacing[100]}px ${tokens.spacing[150]}px`,
        [bp.up("mobile")]: {
          minHeight: "58px",
        },
        whiteSpace: "normal",

        "&:hover": {
          backgroundColor: tokens.component.input.surfaceHover,
        },

        [`&.${menuItemClasses.selected}, &.${menuItemClasses.selected}:hover`]:
          {
            backgroundColor: tokens.component.input.surfacePressed,
            "&:after": {
              content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7703 5.84182C16.7011 5.67639 16.5998 5.52609 16.4721 5.3995C16.3444 5.27285 16.1928 5.17238 16.0259 5.10383C15.8591 5.03528 15.6803 5 15.4996 5C15.319 5 15.1402 5.03528 14.9733 5.10383C14.8065 5.17238 14.6549 5.27285 14.5272 5.3995L8.16434 11.7081L5.46911 9.03587C5.21088 8.7817 4.86159 8.63936 4.49772 8.64002C4.13386 8.64067 3.78509 8.78428 3.5278 9.03937C3.27051 9.29447 3.12567 9.64027 3.125 10.001C3.12434 10.3618 3.26791 10.7081 3.52426 10.9641L7.19191 14.6005C7.31959 14.7271 7.47118 14.8276 7.63804 14.8962C7.80489 14.9647 7.98373 15 8.16434 15C8.34495 15 8.52379 14.9647 8.69064 14.8962C8.85749 14.8276 9.00908 14.7271 9.13676 14.6005L16.4721 7.32776C16.5998 7.20117 16.7011 7.05087 16.7703 6.88544C16.8394 6.72001 16.875 6.5427 16.875 6.36363C16.875 6.18456 16.8394 6.00725 16.7703 5.84182Z' fill='${encodeURIComponent(
                tokens.text.secondary
              )}'/%3E%3C/svg%3E")`,
            },
          },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        width: "20px",
        height: "20px",
        right: tokens.spacing[250],
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      focusVisibleClassName: ".Mui-focusVisible",
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: tokens.primary.main,
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: tokens.colors.gray.white,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 22,
          height: 22,
        },
        "& .MuiSwitch-track": {
          borderRadius: 26 / 2,
          backgroundColor: "#E9E9EA",
          opacity: 1,
          transition: (theme) =>
            theme.transitions.create(["background-color"], {
              duration: 500,
            }),
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: tokens.cornerRadius.radiusMd,
        boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.25)",
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      size: "medium",
      variant: "filled",
      InputProps: {
        disableUnderline: true,
      },
    },
    styleOverrides: {
      root: {
        ...typography.inputValue,
        [`& .${inputLabelClasses.root}`]: {
          ...typography.inputValue,
          color: tokens.text.secondary,
          overflow: "visible",

          [`&.${inputLabelClasses.focused}`]: {
            color: tokens.text.secondary,
          },
        },

        [`& .${filledInputClasses.root}`]: {
          backgroundColor: tokens.component.input.surfaceResting,
          borderRadius: tokens.cornerRadius.radiusLg,
          border: `1px solid ${tokens.component.input.borderResting}`,
          overflow: "hidden",
          minHeight: "58px",
          [`& .${autocompleteClasses.endAdornment}`]: {
            right: tokens.spacing[250],
            [`& .${iconButtonClasses.root}`]: {
              padding: 0,
            },
          },
        },

        [`& .${filledInputClasses.input}`]: {
          textOverflow: "ellipsis",
        },

        [`&:hover .${filledInputClasses.root}:not(.${filledInputClasses.disabled})`]:
          {
            backgroundColor: tokens.component.input.surfaceHover,
          },

        [`&:focus-within .${filledInputClasses.root}:not(.${filledInputClasses.error}), & .${filledInputClasses.focused}.${filledInputClasses.root}:not(.${filledInputClasses.error})`]:
          {
            borderColor: tokens.component.input.borderFocused,
            borderWidth: "2px",

            // Compensate for border width
            margin: "-1px",
            caretColor: tokens.primary.main,
          },

        [`& .${filledInputClasses.disabled}`]: {
          color: tokens.text.disabled,
          backgroundColor: tokens.component.input.surfaceDisabled,
        },

        [`& .${formHelperTextClasses.root}`]: {
          ...typography.inputHelper,
          color: tokens.text.secondary,
          margin: 0,
          paddingTop: "3px",
          paddingLeft: tokens.spacing[150],
          paddingRight: tokens.spacing[150],
        },

        [`& .${filledInputClasses.error}`]: {
          [`&.${filledInputClasses.root}`]: {
            borderColor: tokens.component.input.borderError,
            backgroundColor: tokens.component.input.surfaceError,
          },
          [`&.${inputLabelClasses.root}, &.${formHelperTextClasses.root}`]: {
            color: tokens.error.main,
          },
          [`&.${inputLabelClasses.shrink}`]: {
            fontWeight: tokens.typography.fontWeight.bold,
          },
        },

        [`.${inputAdornmentClasses.root}`]: {
          display: "none",
          paddingRight: tokens.spacing[250],
          [bp.up("tablet")]: {
            paddingRight: tokens.spacing[400],
          },
          [`& .${iconButtonClasses.root}`]: {
            padding: 0,
          },
        },
        [`&:focus-within .${filledInputClasses.input}:not(:placeholder-shown) + .${inputAdornmentClasses.root}, &:hover .${filledInputClasses.input}:not(:placeholder-shown) + .${inputAdornmentClasses.root}`]:
          {
            display: "flex",
          },
      },
    },
    variants: [
      {
        props: { size: "small" },
        style: {
          [`& .${inputLabelClasses.shrink}`]: {
            transform: `translate(12px, 5px) scale(.875)`,
          },

          [`& .${filledInputClasses.root}`]: {
            minHeight: "48px",
          },
        },
      },
      {
        props: { size: "medium" },
        style: {
          [`& .${inputLabelClasses.shrink}`]: {
            transform: `translate(12px, 9px) scale(.875)`,
          },
        },
      },
      {
        props: { size: "search" },
        style: {
          [bp.down("tablet")]: {
            [`& .${inputLabelClasses.shrink}`]: {
              transform: `translate(12px, 9px) scale(.875)`,
            },
          },
          [bp.up("tablet")]: {
            ...searchFieldStyles(),
          },
        },
      },
      {
        props: { size: "searchLeading" },
        style: {
          [bp.down("tablet")]: {
            [`& .${inputLabelClasses.shrink}`]: {
              transform: `translate(12px, 9px) scale(.875)`,
            },
          },
          [bp.up("tablet")]: {
            ...searchFieldStyles(tokens.spacing[300]),
          },
        },
      },
    ],
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        ...typography.labelMedium,
        color: tokens.text.primary,
        padding: tokens.spacing[150],
        borderRadius: "10px",
        border: "none",
        backgroundColor: "transparent",
        [`&.${toggleButtonClasses.selected}`]: {
          backgroundColor: tokens.surface.main,
          boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.15)`,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        gap: tokens.spacing[50],
        padding: tokens.spacing[25],
        backgroundColor: tokens.background.main,
        borderRadius: tokens.cornerRadius.radiusLg,
        [`& .${toggleButtonGroupClasses.grouped}, & .${toggleButtonGroupClasses.firstButton}, & .${toggleButtonGroupClasses.middleButton}, & .${toggleButtonGroupClasses.lastButton},`]:
          {
            borderRadius: "10px",
            border: "0",
            margin: "0",
          },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variant: "bodyMedium",
      variantMapping: {
        displayLarge: "h1",
        displayMedium: "h2",
        displaySmall: "h3",
        headlineLarge: "h4",
        headlineMedium: "h5",
        headlineSmall: "h6",
        titleLarge: "p",
        titleMedium: "p",
        titleSmall: "p",
        bodyLarge: "p",
        bodyMedium: "p",
        bodySmall: "p",
        labelLarge: "p",
        labelMedium: "p",
        labelSmall: "p",
        labelSmallEmphasis: "p",
        labelExtraSmall: "p",
        buttonLarge: "p",
        buttonMedium: "p",
        buttonSmall: "p",
        inputValue: "p",
        inputHelper: "p",
        inputLabel: "p",
        inputLabelError: "p",
        priceLarge: "p",
        priceMedium: "p",
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        background:
          "linear-gradient(270deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.02) 49.5%, rgba(0, 0, 0, 0.03) 100%)",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        display: "flex",
        height: "32px",
        padding: `${tokens.spacing[50]}px calc(${tokens.spacing[150]}px - 4px) ${tokens.spacing[50]}px ${tokens.spacing[150]}px`,
        alignItems: "center",
        gap: tokens.spacing[50],
        borderRadius: tokens.cornerRadius.radiusMd,
        border: `1px solid ${tokens.component.input.borderFocused}`,
        background: tokens.surface.primaryTintResting,
        color: tokens.text.primary,
        fontSize: tokens.typography.fontSize[3],
        fontWeight: tokens.typography.fontWeight.regular,
        lineHeight: tokens.typography.fontLineHeight[3],
        letterSpacing: "0.14px",
        "& .MuiChip-label": {
          padding: 0,
        },
        "&:hover": {
          background: tokens.surface.primaryTintHover,
        },
        "&:active": {
          background: tokens.surface.primaryTintPressed,
        },
        "&:focus": {
          background: tokens.surface.primaryTintResting,
          outline: `2px solid ${tokens.component.input.borderFocused}`,
          outlineOffset: "2px",
        },
        "& .MuiChip-deleteIcon": {
          margin: 0,
          fontSize: "16px",
        },
      },
    },
  },
};

const hdsThemeOptions: ThemeOptions = {
  palette,
  breakpoints,
  spacing,
  typography,
  transitions,
  components,
  tokens,
  colorMode: "default",
};

export const hdsTheme = createTheme(hdsThemeOptions);
