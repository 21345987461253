import { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

export const CLIENT_REGION = "X-Client-Region";
export const LOWERCASE_CLIENT_REGION = "x-client-region";
export const B2B_USER_LOCATION_KEY = "b2bportal-user-location";

export const setUserLocation = (
  headers: AxiosResponseHeaders | RawAxiosResponseHeaders
) => {
  const userLocation =
    headers[CLIENT_REGION] || headers[LOWERCASE_CLIENT_REGION];

  if (userLocation) {
    localStorage.setItem(B2B_USER_LOCATION_KEY, userLocation);
  }
};
