import parse, {
  isValidPhoneNumber,
  CountryCode,
  AsYouType,
} from "libphonenumber-js";
import { uniquePhoneCodesWithCountryCode } from "./countryPhoneCode";

/**
 * Given this example phone number: +1 555 555 5555
 *
 * nationalPrefix – section before the national number including the "+" sign (e.g. "+1")
 * countryCallingCode – section between the "+" sign and the first digit of the national number (e.g. "1")
 * nationalNumber – the local phone number without the country calling code (e.g. "555 555 5555")
 * countryCode – the two-letter ISO country code (e.g. "US")
 */

// full validation of a phone number for a country using length and prefix information
// used before creating a session as this should catch invalid number such as 444-123-1234
// and we can display better FE validation message to users.
export const isPhoneNumberValidForCountry = (
  phone: string,
  nationalPrefix: string
) => {
  const countryCode = uniquePhoneCodesWithCountryCode.find(
    (code) => code.areaCode === nationalPrefix.substring(1)
  ).countryCode as CountryCode;

  try {
    return isValidPhoneNumber(phone, countryCode);
  } catch (e) {
    return false;
  }
};

type ParsedPhoneNumber = {
  nationalNumber: string;
  countryCallingCode: string;
  countryCode: string;
};
export const parsePhoneNumber = (phone?: string): ParsedPhoneNumber => {
  if (!phone) {
    return {
      nationalNumber: "",
      countryCallingCode: "",
      countryCode: "",
    };
  }

  const parsed = parse(phone);
  if (parsed?.isValid()) {
    return {
      nationalNumber: parsed.nationalNumber,
      countryCallingCode: parsed.countryCallingCode,
      countryCode: parsed.country,
    };
  } else {
    return {
      nationalNumber: "",
      countryCallingCode: "",
      countryCode: "",
    };
  }
};

export const formatPhoneNumber = (phone: string, nationalPrefix: string) => {
  // Remove non-digit characters
  const cleanedPhone = phone.replace(/\D/g, "");

  const countryCode = uniquePhoneCodesWithCountryCode.find(
    (code) => code.areaCode === nationalPrefix.substring(1)
  ).countryCode as CountryCode;

  // Get formatted phone number string
  const formatter = new AsYouType(countryCode);
  // AsYouTypeFormatter does not expose an way to get the formatted string after adding values in
  // On adding a digit, it returns the formatted string, so the last index will be used
  const formattedString = cleanedPhone
    .split("")
    .map((char) => formatter.input(char))
    .at(-1);

  return formattedString || "";
};
