"use client";

import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySlice,
  ScheduleChangeSeverity,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IPerson,
  SelectedSeatsSegment,
  getItinerarySummaryProps,
  bookedItineraryFlightSummaryAdapter,
  getRestrictionInfo,
  FareSliceDetails,
} from "@hopper-b2b/types";

import {
  DATE_FORMAT,
  getIsMixedClass,
  getPlusDaysOnSliceWithDates,
  sliceHasScheduleChange,
} from "@hopper-b2b/utilities";
import { TripSegment } from "@b2bportal/air-shopping-api";
import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { ActionButton } from "../ActionButton";

import { FlightDetailsSummary } from "../FlightDetailsSummary";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import { Restriction } from "../Restriction";
import { SelectedSeatsConfirmation } from "../SelectedSeatsConfirmation";
import { FlightVirtualInterlineDetailsSummary } from "../FlightVirtualInterlineDetailsSummary";

import styles from "./styles.module.scss";

export enum SliceToShow {
  /**
   * show just the original slice
   */
  original,
  /**
   * show just the updated slice (if available)
   */
  updated,
  /**
   * show both slices with as an inline diff
   */
  both,
}

export interface ISliceSkchDiffProps {
  airlineMap: { [key: string]: Airline | undefined };
  airportMap: { [key: string]: Airport | undefined };
  flight: BookedFlightItineraryWithDepartureTime;
  isOutgoing?: boolean;
  openScheduleChangeModal: () => void;
  passengerMap: { [key: string]: IPerson };
  seatSegments?: SelectedSeatsSegment[];
  showScheduleChangeTakeover?: boolean;
  slice: FlightItinerarySlice;
  sliceToShow?: SliceToShow;
  isVirtualInterlineItinerary?: boolean;
}

const defaultProps: Partial<ISliceSkchDiffProps> = {
  showScheduleChangeTakeover: false,
  sliceToShow: SliceToShow.original,
};

const SKCH_EXP_FORMAT = "ddd, MMM DD, h:mm A";

const SliceSkchDiff = (props: ISliceSkchDiffProps): JSX.Element => {
  const {
    airlineMap,
    airportMap,
    flight,
    isOutgoing,
    openScheduleChangeModal,
    seatSegments,
    showScheduleChangeTakeover,
    slice,
    sliceToShow,
    isVirtualInterlineItinerary = false,
  } = props;
  const { formatFiatCurrency, t } = useI18nContext();
  const [isMixedClass] = useState(getIsMixedClass(slice));
  const { scheduleChange } = flight.bookedItinerary;
  // TODO pass sliceIdx in as a prop to support multi-city itineraries (i.e. >2 slices)
  const sliceIdx = isOutgoing ? 0 : 1;
  let hasMajorScheduleChange = false;
  let hasMinorScheduleChange = false;

  if (scheduleChange) {
    const { severity } = scheduleChange;
    const sliceChanged = sliceHasScheduleChange(
      flight.bookedItinerary,
      isOutgoing
    );

    // only set if skch applies to this slice
    if (sliceChanged) {
      hasMajorScheduleChange = severity === ScheduleChangeSeverity.Major;
      hasMinorScheduleChange = severity === ScheduleChangeSeverity.Minor;
    }
  }

  const fareSlice = {
    fareDetails: {
      segments: slice.segments,
    },
  };

  const getItineraryDetailsHeader = () => {
    const { segments } = slice;
    const firstSegment = segments[0];
    const lastSegment = segments.at(-1);

    return t("itineraryDetailsHeaderSimple", {
      destination: airportMap[lastSegment.destination.locationCode]?.cityName,
      date: dayjs(firstSegment.scheduledDeparture).format(DATE_FORMAT),
    });
  };

  return (
    <Box
      className={clsx(styles.sliceDetails, {
        [styles.majorChange]:
          sliceToShow !== SliceToShow.updated && hasMajorScheduleChange,
        [styles.originalSlice]:
          sliceToShow === SliceToShow.original && hasMajorScheduleChange,
        [styles.showTakeover]: showScheduleChangeTakeover,
      })}
    >
      {showScheduleChangeTakeover && hasMajorScheduleChange && (
        <Box className={styles.majorSkchCtaContainer}>
          <Typography
            className={styles.majorSkchCopy}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("scheduleChange.majorBannerLabel", {
                context: isOutgoing ? "outbound" : "return",
                expDate: dayjs(scheduleChange.expiry).format(SKCH_EXP_FORMAT),
              }),
            }}
          />
          <ActionButton
            className={styles.majorSkchCta}
            fill="blue"
            message={t("scheduleChange.reviewChange")}
            onClick={openScheduleChangeModal}
            size="medium"
          />
        </Box>
      )}
      <Box className={styles.sliceDetailsContainer}>
        <Box className={styles.sliceDetailsTitle}>
          <Typography variant="subtitle2">
            <span className={styles.directionLabel}>
              {isOutgoing ? t("outbound") : t("return")}
            </span>{" "}
            {getItineraryDetailsHeader()}
            {isMixedClass && <MixedCabinToolTip />}
          </Typography>
        </Box>
        {isVirtualInterlineItinerary ? (
          <FlightVirtualInterlineDetailsSummary
            mobileLayout
            className={styles.fareDetailsVI}
            segments={bookedItineraryFlightSummaryAdapter({
              slice,
              airlineMap,
              airportMap,
            })}
            fareSlice={fareSlice as unknown as FareSliceDetails}
            plusDays={getPlusDaysOnSliceWithDates(
              slice.segments[0].scheduledDeparture,
              slice.segments[slice.segments.length - 1].scheduledArrival
            )}
          />
        ) : (
          <FlightDetailsSummary
            className={clsx(
              styles.tripsFlightCard,
              isOutgoing ? styles.outgoing : styles.return
            )}
            {...getItinerarySummaryProps(
              flight,
              isOutgoing,
              airportMap,
              airlineMap,
              sliceToShow === SliceToShow.original
            )}
            hasMajorScheduleChange={hasMajorScheduleChange}
            hasMinorScheduleChange={hasMinorScheduleChange}
            isMixedCabinClass={isMixedClass}
            renderAirlineIconSection={false}
            sliceToShow={sliceToShow}
            skchSlice={
              sliceToShow === SliceToShow.original
                ? undefined
                : scheduleChange?.next[sliceIdx]
            }
            showTitle={false}
          />
        )}
        {isVirtualInterlineItinerary ? null : (
          <>
            <Divider className={styles.divider} />
            <Typography
              className={styles.fareDetailsHeader}
              variant="subtitle1"
            >
              {t("fareDetails")}
            </Typography>
            <Box className={styles.tripItineraryRestrictions}>
              {getRestrictionInfo(flight, t, formatFiatCurrency).map(
                (restriction, index) => (
                  <Restriction
                    descriptionString={restriction.description}
                    key={`restriction-${index}`}
                    name={restriction.name}
                    symbol={restriction.symbol}
                  />
                )
              )}
            </Box>
          </>
        )}
        {seatSegments?.length ? (
          <>
            <Divider className={styles.divider} />
            <Typography
              className={styles.seatSelectionHeader}
              variant="subtitle1"
            >
              {t("seatSelection")}
            </Typography>
            <Box
              className={clsx(
                styles.seatSelection,
                isOutgoing ? styles.outgoing : styles.return
              )}
            >
              <SelectedSeatsConfirmation
                airports={airportMap}
                outboundOriginCode={slice.segments[0].origin.locationCode}
                outboundSeatSegments={seatSegments}
                returnOriginCode={""}
              />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

SliceSkchDiff.defaultProps = defaultProps;

export default SliceSkchDiff;
