"use client";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { PropsWithChildren, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

dayjs.extend(duration);

const SESSION_ID_KEY = "sessionId";
const TIME_TO_LIVE = dayjs.duration({ minutes: 15 });
const REFRESH_RATE = TIME_TO_LIVE.subtract(1, "minute");

function readFromCookie() {
  if (!globalThis.document) return undefined;
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(SESSION_ID_KEY))
    ?.split("=")[1];
}

function writeToCookie(sessionId: string) {
  if (!globalThis.document) return;
  document.cookie = `${SESSION_ID_KEY}=${sessionId}; expires=${dayjs()
    .add(TIME_TO_LIVE)
    .toDate()
    .toUTCString()}; path=/`;
}

export function startNewFeatureFlagsSession() {
  const sessionId = `${uuidv4()}/${dayjs().valueOf()}`;
  writeToCookie(sessionId);
  return sessionId;
}

export const getFeatureFlagsSessionId = () => {
  const sessionId = readFromCookie() ?? startNewFeatureFlagsSession();
  return sessionId;
};

const extendSession = () => {
  writeToCookie(getFeatureFlagsSessionId());
};

// Extends the LD Session as long as the user interacts with the app
export const FeatureFlagsSessionIdRefresher = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const isBrowser = !!globalThis.document;

  useEffect(() => {
    if (!isBrowser) return;

    const interval = setInterval(extendSession, REFRESH_RATE.asMilliseconds());
    return () => clearInterval(interval);
  }, [isBrowser]);

  return children as JSX.Element;
};
