import { Example, TripSlice } from "@b2bportal/air-shopping-api";
import { AirportRegion, Prices } from "../../common";
import { Amount } from "./payment-machine";
import { Offer } from "./price-freeze";

export enum Dealness {
  Fair = "fair",
  Good = "good",
  Great = "great",
  Wait = "wait",
}

export interface FareScore {
  comfort: number;
  value: number;
  isBest: boolean;
  isCheapest: boolean;
  isBestQuality: boolean;
  isFastest: boolean;
}

export interface FareShelf {
  outgoing: SegmentShelf;
  returning?: SegmentShelf;
}

export interface Interval {
  dates: string;
  copy: string;
}

type typicalObj<T> = {
  [key in string]?: T;
};

export interface Intervals extends typicalObj<Interval> {
  normal?: Interval;
  warning?: Interval;
  danger?: Interval;
}

export interface Prediction extends BasePrediction {
  dealness: Dealness;
  pricePrediction: PricePrediction;
  predictionCopy?: PredictionCopy;
  lowestPrice: Prices;
  priceDropProtection: PriceDropProtection;
  PredictionResult: PredictionResultEnum.Prediction;
}

export type PriceDropProtection = IsEligible | NotEligible;

export enum PriceDropProtectionEnum {
  IsEligible = "IsEligible",
  NotEligible = "NotEligible",
}

export interface IsEligible {
  PriceDropProtection: PriceDropProtectionEnum.IsEligible;
  candidateId: string;
  minimumRefund: DisplayAmount;
  maximumRefund: DisplayAmount;
  monitoringDuration: MonitoringDuration;
}

export interface MonitoringDuration {
  inSeconds: number;
}

export interface NotEligible {
  PriceDropProtection: PriceDropProtectionEnum.NotEligible;
  reason?: string;
}

export interface PricePrediction {
  minPrice?: DisplayAmount;
  maxPrice?: DisplayAmount;
}
export interface DisplayAmount {
  amount: Amount;
  display: string;
}

export interface PredictionCopy {
  origin: string;
  destination: string;
  travelDates: string;
  lowestPriceLabel: string;
  lowestPrice: string;
  carrier: string;
  dealness: string;
  recommendationTitle: string[];
  recommendationBody: string[];
  recommendationTitleWatching: string[];
  recommendationBodyWatching: string[];
  recommendationTitleJustWatched: string[];
  recommendationBodyJustWatched: string[];
  intervals: Intervals;
}

export type PredictionResultResponse = Prediction | UnavailablePrediction;

export enum PredictionResultEnum {
  Prediction = "Prediction",
  UnavailablePrediction = "UnavailablePrediciton",
}

export interface Route {
  origin: AirportRegion;
  destination: AirportRegion;
}

export interface SegmentShelf {
  rating: number;
  brandName: string;
  shortBrandName: string;
  opaqueMapperStep?: string;
}

export enum ShopFilter {
  ShortLayover = "ShortLayover",
  NonStopNoLCC = "NonStopNoLCC",
  NoLCC = "NoLCC",
  NonStop = "NonStop",
  ShortLayoverNoLCC = "ShortLayoverNoLCC",
  NoFilter = "NoFilter",
}

export enum Platform {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export interface ShopSummaryRequest {
  route: Route;
  departureDate: string;
  returnDate?: string;
  tripFilter: ShopFilter;
  passengers: { [key: string]: number };
  platform?: Platform;
}

export interface ShopSummaryResponse {
  tripSummaries: TripSummary[];
  prediction: PredictionResultResponse;
  priceFreezeOffer?: OfferWithSuggested;
}

export interface TripFare {
  fareShelf?: FareShelf;
  isMultiTicket: boolean;
  fareId: string;
  amount: Prices;
  fareScore: FareScore;
}

export interface TripSummary {
  tripId: string;
  outgoingSlice: TripSlice;
  returningSlice?: TripSlice;
  tripFares: TripFare[];
}

export interface UnavailablePrediction extends BasePrediction {
  reason: string;
  PredictionResult: PredictionResultEnum.UnavailablePrediction;
}

export interface BasePrediction {
  PredictionResult: PredictionResultEnum;
}

export interface OfferWithSuggested {
  offer: Offer;
  cheapestTrip: {
    fareId: string;
    tripId: string;
  };
}

export interface FlightTags {
  isBest: boolean;
  isCheapest: boolean;
  isBestQuality: boolean;
  isFastest: boolean;
}
export interface OutboundFares {
  fareSlice: string;
  amount: Prices;
  next?: string[];
  example: Example;
}
