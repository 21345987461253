export const URL_PARAM_KEYS = {
  FROM_DATE: "fromDate",
  UNTIL_DATE: "untilDate",
  ADULTS_COUNT: "adultsCount",
  CHILDREN_COUNT: "childrenCount",
  CHILDREN_AGES: "childrenAges",
  ROOMS_COUNT: "roomsCount",
  STAR_RATING: "starRating",
  USER_RATING: "userRating",
  AMENITIES: "amenities",
  FILTER_AMENITIES_AND: "filterAmenitiesAnd",
  FREE_CANCELLATION: "freeCancellation",
  MEALS: "meals",
  PRICE_RANGE_MIN: "priceMin",
  PRICE_RANGE_MAX: "priceMax",
  LAT_LNG: "latlng",
  SORT_BY: "sortBy",
  LODGING_IDS: "lodgingIds",
  PET_FRIENDLY: "pets",
  SHOW_MAP: "showMap",
  STAY_TYPES: "stayTypes",
  AUTOCOMPLETE_LABEL: "autocompleteLabel",
  AUTOCOMPLETE_SUBLABEL: "autocompleteSubLabel",
  DISCOUNT_PERCENTAGE: "discountPercentage",
  MAX_DISCOUNT_AMOUNT: "maxDiscountAmount",
  PLACE_ID: "placeId",
  QUERY: "query",
  CURRENCY_CODE: "currency",
} as const;
