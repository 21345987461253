import { Currency, isCurrency } from "@hopper-b2b/types";
import { isAtHotelBrand } from "@hopper-b2b/utilities";

const B2B_CURRENCY_PREFERENCE_KEY = "b2b-currency-preference";

export const getCurrency = (defaultCurrency?: Currency): Currency => {
  let fromStorage = null;
  try {
    fromStorage = localStorage?.getItem(B2B_CURRENCY_PREFERENCE_KEY);
  } catch (e) {
    //console.warn("storage unavailable")
  }

  return isAtHotelBrand() && isCurrency(fromStorage)
    ? fromStorage
    : defaultCurrency ?? Currency.USD;
};

export const setCurrency = (currency: Currency) => {
  localStorage.setItem(B2B_CURRENCY_PREFERENCE_KEY, currency);
};
