import { LoadingText } from "@hopper-b2b/ui";
import { RunningBunny, useImageSrc } from "@hopper-b2b/ui-core";
import "./styles.scss";

export const LoadingContent = ({ message }: { message: string }) => {
  const bunnySrc = useImageSrc(RunningBunny);
  return (
    <div className="auth-loading-content">
      <img className="loading-bunny" src={bunnySrc} alt="loading..." />
      <LoadingText className="auth-loading-message" message={message} />
    </div>
  );
};
