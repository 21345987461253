"use client";

import { createContext, useContext } from "react";

export type FeatureFlagsContextProps = {
  //  Flights
  enableAirShopV4?: boolean;
  enableB6Upsell?: boolean;
  enableIataLookup?: boolean;

  enableApplePay?: boolean;

  // CX Product
  enableAirExchange?: boolean;
  enableHFv2?: boolean;

  // Fintech Flags
  enablePriceWatch?: boolean;
  enablePriceFreeze?: boolean;
  enablePricePrediction?: boolean;
  enableScheduleChange?: boolean;
  enableAirTripInsurance?: boolean;
  enableLodgingTripInsurance?: boolean;
  enableMissedConnection?: boolean;
  enableCfar?: boolean;
  enableChfar?: boolean;
  enableVipSupport?: boolean;
  enableDisruptionExerciseRebooking?: boolean;
  enableDisruptionProtection?: boolean;
  enableDisruptionProtectionUniversalExercise?: boolean;
  enableDisruptionProtectionUniversalRebook?: boolean;
  enablePriceDrop?: boolean;
  hideCfarHackerFare?: boolean;
  hideChfarHackerFare?: boolean;
  hideDisruptionHackerFare?: boolean;

  // Virtual Interline
  enableMergeSortingDrodpdownWithFilters?: boolean;
  enableRemoveRecommendSort?: boolean;

  // Features
  enableDarkMode?: boolean;
  enableLoadingSteps?: boolean;
  showLodgingRooms?: boolean;

  // Experiments
  hideFareDetails?: boolean;
  hideSeats?: boolean;
  hideAirItineraryReview?: boolean;
  enableFintechSelection?: boolean;
  hideContactInfoScreen?: boolean;
  hideEmailOnContactInfoScreen?: boolean;
  enableSavePayment?: boolean;
  hideAirPriceFreezeHackerFare?: boolean;
  webLodgingCheckoutRedesigned?: boolean;

  // Growth
  enableWallet?: boolean;

  // trips
  enableAirTripSearch?: boolean;

  //Lodging
  enableLodging?: boolean;
  enableLodgingCfar?: boolean;
  enableLodgingSelfServe?: boolean;
  enableLodgingPriceChangeBanner?: boolean;
  enableLodgingPromotionsMerchandising?: boolean;
  enableRatePlanTypeMerchandising?: boolean;
  enableStays?: boolean;
  enableHomesInStaysSearch?: boolean;
  enableScrantonWebImprovedMerchandising?: boolean;
  enableHotelCugDeals?: boolean;
};
export const FeatureFlagsContext = createContext<FeatureFlagsContextProps>({});

export const useFeatureFlagsContext = () => {
  const ctx = useContext(FeatureFlagsContext);
  if (!ctx) {
    console.warn(`Must be used within a FeatureFlagsContext`);
  }

  return ctx;
};

// Fintech Flags

export const useEnablePriceWatch = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceWatch;
};

export const useEnableAirTripSearch = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableAirTripSearch;
};

export const useEnablePriceFreeze = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceFreeze;
};

export const useEnablePricePrediction = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePricePrediction;
};

export const useEnableScheduleChange = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableScheduleChange;
};

export const useEnableAirTripInsurance = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableAirTripInsurance;
};

export const useEnableMissedConnection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableMissedConnection;
};

export const useEnableCfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableCfar;
};

export const useEnableChfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableChfar;
};

export const useEnableWallet = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWallet;
};

export const useEnableVipSupport = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableVipSupport;
};

export const useEnableDisruptionExerciseRebooking = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionExerciseRebooking;
};

export const useEnableDisruptionProtection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtection;
};

export const useEnableDisruptionProtectionUniversalExercise = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtectionUniversalExercise;
};

export const useEnableDisruptionProtectionUniversalRebook = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtectionUniversalRebook;
};

export const useEnableCfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideCfarHackerFare;
};

export const useEnableChfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideChfarHackerFare;
};

export const useEnableDisruptionHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideDisruptionHackerFare;
};

// Features

export const useEnableDarkMode = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDarkMode;
};

export const useEnableLoadingSteps = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLoadingSteps;
};

export const useEnableAirShopV4 = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableAirShopV4;
};

export const useEnableB6Upsell = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableB6Upsell;
};

// Virtual Interline
export const useEnableMergeSortingDrodpdownWithFilters = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableMergeSortingDrodpdownWithFilters;
};

export const useEnableRemoveRecommendSort = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableRemoveRecommendSort;
};

// Experiments

export const useHideFareDetails = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideFareDetails;
};

export const useHideSeats = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideSeats;
};

export const useHideReviewItitinerary = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideAirItineraryReview;
};

export const useEnableAirExchange = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableAirExchange;
};

export const useEnableFintechSelection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableFintechSelection;
};

export const useEnableHFv2 = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableHFv2;
};

export const useHideContactInfoScreen = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideContactInfoScreen;
};

export const useHideEmailAddressOnContactInfo = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideEmailOnContactInfoScreen;
};

export const useEnableLodgingCfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingCfar;
};

export const useEnableLodgingSelfServe = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingSelfServe;
};

export const useEnableSavePayment = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableSavePayment;
};

export const useEnableIataLookup = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableIataLookup;
};

export const useHideAirPriceFreezeHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideAirPriceFreezeHackerFare;
};

export const useLodgingCheckoutRedesigned = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.webLodgingCheckoutRedesigned;
};

export const useEnableLodgingPriceChangeBanner = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingPriceChangeBanner;
};

export const useEnableApplePay = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableApplePay;
};

export const useEnableLodging = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodging;
};

export const useEnableLodgingPromotionsMerchandising = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return !!featureFlagsContext?.enableLodgingPromotionsMerchandising;
};

export const useEnableRatePlanTypeMerchandising = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return !!featureFlagsContext?.enableRatePlanTypeMerchandising;
};

export const useEnableStays = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableStays;
};

export const useLodgingTripInsuranceEnabled = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingTripInsurance;
};

export const useScrantonWebImprovedMerchandising = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableScrantonWebImprovedMerchandising;
};

export const useHotelCugDeals = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableHotelCugDeals;
};
