const HOPPER_BRAND = "hopper";
const ATHOTEL_BRAND = "athotel";
const HOPPER_APP_WEB_VIEW_USER_AGENT = "hopperapp";

export const currentBrand = (): string => {
  if (process.env.NEXT_PUBLIC_BRAND)
    return process.env.NEXT_PUBLIC_BRAND ?? HOPPER_BRAND;
  if (import.meta.env.VITE_BRAND)
    return import.meta.env.VITE_BRAND ?? HOPPER_BRAND;
  try {
    if (window.location.hostname.includes(ATHOTEL_BRAND)) {
      return ATHOTEL_BRAND;
    }
    return HOPPER_BRAND;
  } catch (e) {
    return HOPPER_BRAND;
  }
};

const ATTRIBUTION_PARAM_KEY = "attribution";
const ATTRIBUTION_COOKIE_NAME = "Hopper-Attribution";

export function getBrandAttribution() {
  const params = new URLSearchParams(window.location.search);
  return params.get(ATTRIBUTION_PARAM_KEY);
}

export function detectBrandAttribution() {
  const attribution = getBrandAttribution();
  if (attribution) {
    document.cookie = `${ATTRIBUTION_COOKIE_NAME}=${attribution}; path=/`;
  }
}

export const isAtHotelBrand = () => currentBrand() === ATHOTEL_BRAND;
export const isHopperBrand = () => currentBrand() === HOPPER_BRAND;
export const isBrandedSite = () => !isHopperBrand();
/**
 * @returns {boolean} true if the user agent is the Hopper App web view and the website is Hopper,
 *  meaning that the page is loaded in a web view within the iOS or Android Hopper App.
 */
export const isHopperAppWebView = (): boolean =>
  isHopperBrand() &&
  typeof window !== "undefined" &&
  navigator.userAgent.toLowerCase().includes(HOPPER_APP_WEB_VIEW_USER_AGENT);

export const getAtHotelBrandButtonStyling = ({ filled }: { filled: boolean }) =>
  isAtHotelBrand()
    ? { style: filled ? { background: "#17803C" } : { color: "#17803C" } }
    : {};
