import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AirRestrictionStatus } from "@hopper-b2b/types";
import { Box, Button, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { Icon, IconName } from "../../Icon";
import { IRestrictionProps } from "../FareDetailsCard";
import styles from "./Amenity.module.scss";

export type AmenityProps = {
  anchorEl: any;
  restriction: IRestrictionProps;
  fareId: string;
  index?: number;
  isLast?: boolean;
  count?: number;
  handleSeeMoreOnClick: (fareId: string) => void;
};

export const Amenity = ({
  anchorEl,
  restriction,
  fareId,
  index,
  isLast,
  count,
  handleSeeMoreOnClick,
}: AmenityProps) => {
  const { t } = useI18nContext();

  return (
    <Box my={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>{restrictionIcon[restriction.symbol]}</Grid>
        <Grid item xs>
          <Typography variant="body1">{restriction.description}</Typography>
        </Grid>
      </Grid>
      {isLast && (
        <Button
          aria-owns={
            anchorEl && anchorEl.id === `trip-fare-details-${index}`
              ? "mouse-over-popover"
              : undefined
          }
          aria-haspopup="true"
          id={`trip-fare-details-${index}`}
          size="small"
          color="primary"
          onClick={() => handleSeeMoreOnClick(fareId)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSeeMoreOnClick(fareId);
            }
          }}
          tabIndex={0}
        >
          <Typography variant="subtitle1" color="primary">{`+${t?.("more", {
            count,
          })}`}</Typography>
        </Button>
      )}
    </Box>
  );
};

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className={clsx(styles.icon, styles.green)}
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <Icon
      className={styles.icon}
      name={IconName.MoneyOutlineTransparentIcon}
      height={16}
      width={16}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <Icon
      className={styles.icon}
      name={IconName.NotAllowedSign}
      height={16}
      width={16}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <Icon
      className={styles.icon}
      name={IconName.Generic}
      height={16}
      width={16}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
};
