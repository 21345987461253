import { trackEvent } from "@hopper-b2b/api";
import { ActionButton, Image } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getStringifiedTransitions } from "@hopper-b2b/utilities";
import { useEffect } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthFailureEvent,
  AuthTrackingEvents,
} from "../../../../types/tracking";
import { AuthPromptProps } from "../../component";
import "./styles.scss";

export const AuthErrorContent = ({
  onClose,
}: Pick<AuthPromptProps, "onClose">) => {
  const { t } = useI18nContext();
  const [state, send] = useAuthState<AuthEvents, unknown>();
  const authType = useAuthSelector(getAuthType);
  const onTryAgain = () => {
    send(AuthMachineEventType.TRY_AGAIN);
  };
  const continueWithoutLogin = () => {
    onClose();
  };

  useEffect(() => {
    trackEvent({
      eventName: AuthTrackingEvents.Failure,
      properties: {
        auth_type: authType ? authType : undefined,
      },
    } as AuthFailureEvent);
    // Logs to dd logger
    console.error(
      JSON.stringify({
        currentState: state.value,
        transitions: getStringifiedTransitions(state.transitions),
      })
    );
  }, []);

  return (
    <div className="auth-error-content">
      <Image
        src="https://cdn.hopper.com/ds/Bunny/Sad@2x.png"
        width={"auto"}
        height={"142px"}
      />
      <h1 className="error-header">{t("loginErrorHeader")}</h1>
      <p className="error-message">{t("loginErrorSubheader")}</p>

      <ActionButton
        onClick={onTryAgain}
        message={t("tryAgain")} //TODO: Fix capitalize of Again
        className="retry-button"
        defaultStyle="h4r-primary"
      />
      <ActionButton
        onClick={continueWithoutLogin}
        message={t("signInContinue")}
        className="continue-button"
        defaultStyle="h4r-secondary"
      />
    </div>
  );
};
