import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useEffect, useState } from "react";
import "./styles.scss";
import { getAtHotelBrandButtonStyling } from "@hopper-b2b/utilities";

export const ResendSection = ({ onResend }: { onResend: () => void }) => {
  const { t } = useI18nContext();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 30000 /* Message says 30 seconds*/);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="resend-section">
      <p className="resend-description">{t("resendDescription")}</p>
      <ActionButton
        defaultStyle="h4r-secondary"
        onClick={onResend}
        message={t("resendCode")}
        className="resend-button"
        {...getAtHotelBrandButtonStyling({ filled: false })}
        disabled={disabled}
      />
    </div>
  );
};
