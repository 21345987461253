"use client";

import {
  Context,
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { AuthActions } from "./actions";
import { authReducer, UserInfoState } from "./reducer";

export type UserInfoContext = {
  state: UserInfoState;
  dispatch: Dispatch<AuthActions>;
};
export const UserContext: Context<UserInfoContext> = createContext({
  state: {
    sessionInfo: undefined,
  },
  dispatch: undefined,
} as UserInfoContext);

export const useAuthProvider = () => {
  const ctx = useContext(UserContext);
  if (ctx === undefined) {
    throw new Error(`must be used within a AuthProvider`);
  }
  return ctx;
};

interface AuthProviderProps extends PropsWithChildren {
  initState?: UserInfoState;
}
export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  initState,
}) => {
  const [state, dispatch] = useReducer(authReducer, initState);

  return (
    <UserContext.Provider value={{ state: state, dispatch: dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
