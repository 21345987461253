import {
  CategorizedResponse,
  LodgingSelectionEnum,
  PlaceIdEnum,
  Suggestion,
  lodgingApi,
} from "@b2bportal/lodging-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../../AxiosInterceptor";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchLodgingLocationAutocomplete = async (
  query: string,
  apiConfig?: IApiConfig
): Promise<CategorizedResponse[]> => {
  try {
    const res = await lodgingApi(axiosInstance).apiV0AutocompleteLodgingPost({
      query: { l: query, LocationQuery: "Label" },
    });
    return res.data.categories;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};

export const doAutocompleteSearch = async (searchTerm: string) => {
  const response = await fetchLodgingLocationAutocomplete(searchTerm);
  return response?.reduce(
    (acc: Suggestion[], categoryRes: CategorizedResponse) => {
      acc.push(...categoryRes.results);

      return acc;
    },
    []
  );
};

export const fetchPlace = async (
  query: string,
  apiConfig?: IApiConfig
): Promise<Suggestion | undefined> => {
  const locations = await fetchLodgingLocationAutocomplete(query, apiConfig);

  // Try to find a geo location
  const geoLocation = locations[0].results.find(
    (location: Suggestion) =>
      location.id.Id === PlaceIdEnum.Lodgings &&
      location.id.lodgingSelection.LodgingSelection ===
        LodgingSelectionEnum.Place &&
      location.id.lodgingSelection.placeTypes.includes("geocode")
  );

  // Return geo location or first location
  return geoLocation ?? locations?.[0].results.pop();
};
