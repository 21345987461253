import React from "react";

export const VipSupportIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6926 40C9.99346 39.9913 8.39471 39.1941 7.36537 37.8423C6.33603 36.4906 5.99283 34.7376 6.43643 33.0975L8.32575 26.195L3.26336 22.1019C1.55893 20.7172 0.85231 18.4447 1.47093 16.3377C2.07196 14.2153 3.90351 12.6716 6.09733 12.4384L12.2255 11.7361L15.7861 4.3492C16.6914 2.45549 18.604 1.25 20.7032 1.25C22.8023 1.25 24.7149 2.45549 25.6202 4.3492L29.1566 11.7361L35.1637 12.4384C37.35 12.695 39.17 14.2361 39.7831 16.3502C40.3963 18.4642 39.6833 20.7398 37.9734 22.1262L32.9595 26.195L34.8488 33.0975C35.45 35.301 34.6132 37.6455 32.7526 38.9705C30.892 40.2955 28.4025 40.3198 26.5164 39.0312L20.6305 35.0108L14.7688 39.0312C13.8667 39.6613 12.793 39.9994 11.6926 40ZM20.6305 4.88191C19.9312 4.87772 19.295 5.28596 19.0076 5.92345L15.0352 14.2064C14.765 14.7683 14.2255 15.1523 13.6061 15.2237L6.5091 16.0471C5.77974 16.1395 5.17267 16.6516 4.9589 17.355C4.75123 18.0565 4.99093 18.8139 5.56444 19.2683L11.5473 24.1121C12.0951 24.5734 12.3304 25.3075 12.1528 26.0012L9.94862 34.0178C9.73577 34.7619 10.0177 35.559 10.6511 36.0038C11.2615 36.4599 12.0994 36.4599 12.7099 36.0038L19.6132 31.3053C20.231 30.8719 21.0542 30.8719 21.672 31.3053L28.5511 36.0038C29.1717 36.4562 30.0135 36.4562 30.6342 36.0038C31.2675 35.559 31.5495 34.7619 31.3366 34.0178L29.1324 26.0012C28.9422 25.3072 29.1797 24.5663 29.7379 24.1121L35.7208 19.2683C36.3036 18.8203 36.5372 18.0514 36.3021 17.355C36.1087 16.648 35.5044 16.1301 34.7761 16.0471L27.6549 15.2237C27.0372 15.1479 26.4995 14.7653 26.2258 14.2064L22.2776 5.92345C21.9817 5.28213 21.3368 4.87435 20.6305 4.88191Z"
        fill="#1875EC"
      />
    </svg>
  );
};
