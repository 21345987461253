import {
  Facebook,
  Instagram,
  LinkedIn,
  Medium,
  TikTok,
  Twitter,
  Youtube,
} from "../assets/icons/social-media";

const iconMap = {
  // social media
  facebook: Facebook,
  instagram: Instagram,
  linkedin: LinkedIn,
  medium: Medium,
  tiktok: TikTok,
  twitter: Twitter,
  youtube: Youtube,
};

const getIcon = (key: string) => {
  const Icon = iconMap[key as keyof typeof iconMap];
  if (Icon !== undefined) {
    // icon exists, send it back
    return <Icon />;
  }
  return null;
};

export default getIcon;
