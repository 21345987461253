const UTM_SOURCE_PARAM = "utm_source";
const UTM_MEDIUM_PARAM = "utm_medium";
const UTM_CAMPAIGN_PARAM = "utm_campaign";

type UtmParameters = {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
};

export const getUtmParameters = (): UtmParameters => {
  const utmSource = new URLSearchParams(window.location.search).get(
    UTM_SOURCE_PARAM
  );
  const utmMedium = new URLSearchParams(window.location.search).get(
    UTM_MEDIUM_PARAM
  );
  const utmCampaign = new URLSearchParams(window.location.search).get(
    UTM_CAMPAIGN_PARAM
  );

  return {
    utmSource,
    utmMedium,
    utmCampaign,
  };
};
