import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  borderRadius: "8px",
  colors: {
    black: "#000",
    white: "#fff",
    grey: {
      100: "#F6F6F6",
      200: "#EDEDED",
      300: "#D9D9D9",
      400: "#AAAAAA",
      500: "#9D9D9D",
      600: "#878787",
      700: "#606060",
      800: "#505050",
      900: "#111111",
    },
    coral: "#fa6866",
    purple: "#A17CCD",
    beige: "#F7F4EA",
    skyblue: "#1875EC",
    lightsky: "#01B3E7",
    timewarp: "#A57FD3",
    green: "#79C96C",
    pink: "#EA74B0",
    yellow: "#F5B60D",
    orange: "#F58A52",
    teal: "#49C9D1",
    tan: "#CEC7BD",
    shellpink: "#FF8482",
    pixie: "#C89BB4",
    goldenstraw: "#E8BE80",
    gulfstream: "#94CEE0",
    // ---
    calendar: {
      red: "#C20A21",
      orange: "#E36624",
      yellow: "#F0A02A",
      green: "#6FC28B",
    },
    alert: {
      red: "#DF374B",
      yellow: "#D99422",
      green: "#63B67F",
      slate: "#5A7F8C",
    },
  },
  placeholder: {
    dark: "rgba(#E7EEF5, 0.65)",
    light: "rgba(#ebeef0, 0.65)",
  },
  input: {
    border: "#b9bbbe",
  },
  shadows: {
    basic: "0px 5px 25px rgba(0, 0, 0, 0.1)",
  },
  dimensions: {
    tap: 48,
    navigation: "11rem",
    navigationWithMargin: "17rem",
    navigationWithMarginMobile: "10.5rem",
    navigationSlideIn: "6rem",
    narrowContainer: "78rem",
    sectionWidth: "124rem",
    sectionPadding: "5rem",
  },
  fontfamily: {
    sansSerif:
      "'proxima-nova', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
    serif: "'ff-meta-serif-web-pro', serif",
  },
  breakpoints: [
    ["phone_small", 320],
    ["phone", 376],
    ["phone_large", 480],
    ["phablet", 540],
    ["phablet_large", 620],
    ["tablet", 768],
    ["tablet_large", 991],
    ["desktop", 1024],
    ["desktop_large", 1440],
    ["desktop_xl", 1441],
  ],
};

export default theme;
