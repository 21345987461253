"use client";

import { BrandNames } from "@hopper-b2b/types";
import { useI18nextContext } from "../I18nContext";
import { setLang } from "../utils/getLang";
import { useI18nCurrency } from "./useI18nCurrency";

export function useI18nContext() {
  const ctx = useI18nextContext();
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  const {
    language,
    options: { resources, fallbackLng },
  } = ctx;

  let brand = resources?.[language]?.["brand"] as BrandNames;

  const supported = brand?.supportedLanguages?.some((l) => l.key === language);
  if (!supported) {
    // Use the i18n fallback if tenant does not support this language
    const fallback = fallbackLng?.[0];
    brand = resources?.[fallback]?.["brand"] as BrandNames;
    setLang(fallback);
  }

  const {
    currencyCode,
    currencyNameOf,
    currencySymbol,
    formatFiatCurrencyForShopping,
    formatFiatCurrency,
    formatZeroAmount,
  } = useI18nCurrency();

  return {
    ...ctx,
    brand,
    currencyCode,
    currencyNameOf,
    currencySymbol,
    formatFiatCurrencyForShopping,
    formatFiatCurrency,
    formatZeroAmount,
  };
}
