import { B2B_LOCALE_PREFERENCE_KEY, LANGUAGE_SEARCH_PARAM } from "../constants";

/**
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, or undefined
 */
export function getLangFromStorage(useNavigator = true): string | undefined {
  let fromStorage;
  let fromCookie;
  try {
    fromCookie = readCookie();
    fromStorage = localStorage?.getItem(B2B_LOCALE_PREFERENCE_KEY);
  } catch (e) {
    //console.warn("storage unavailable")
  }

  return (
    fromCookie ||
    fromStorage ||
    (useNavigator &&
      typeof window !== "undefined" &&
      navigator &&
      navigator?.language) ||
    undefined
  );
}

/**
 * Google Flights will put the language in the search params when linking to our flights.
 */
function getLangFromSearchParam(): string | undefined {
  // make sure we do not break SSR when trying to access window
  if (typeof window === "undefined") return undefined;
  return new URLSearchParams(window?.location?.search).get(
    LANGUAGE_SEARCH_PARAM
  );
}

/**
 * Get full language with country, if exists. Check local storage first since
 * some tenants (Nequi or Volaris) can select their language. If there's nothing in local
 * storage, check the navigator, then defaultLng, fallback to en
 */
export const getLang = (lang?: string, useNavigator = true): string => {
  const languageFromCookie = readCookie();
  const languageFromStorage = getLangFromStorage(useNavigator);
  const languageFromSearchParam = getLangFromSearchParam();

  return (
    languageFromSearchParam ||
    languageFromCookie ||
    languageFromStorage ||
    lang ||
    "en"
  );
};

/**
 * Rewrites query param when setting a new language.
 */
const updateSearchParam = (lang: string) => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    if (url.searchParams?.get(LANGUAGE_SEARCH_PARAM)) {
      url.searchParams.set(LANGUAGE_SEARCH_PARAM, lang);
      window.history.replaceState({}, "", url);
    }
  }
};

export const setLang = (lang: string) => {
  const languageFromCookie = readCookie();
  const language =
    new Intl.Locale(lang).language ||
    new Intl.Locale(languageFromCookie).language;
  updateSearchParam(lang);

  if (language) {
    writeCookie(language);
  }
};

export const updateLang = () => {
  setLang(getLang());
};

export const readCookie = () => {
  let cookieValue;
  try {
    cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith(B2B_LOCALE_PREFERENCE_KEY))
      ?.split("=")[1];
  } catch (e) {
    //console.warn("cookie unavailable")
  }

  return cookieValue;
};

export const writeCookie = (lang: string) => {
  try {
    document.cookie = `${B2B_LOCALE_PREFERENCE_KEY}=${lang}; path=/`;
  } catch (e) {
    //console.warn("cookie unavailable")
  }
};
