import * as React from "react";
import clsx from "clsx";
import "./styles.scss";

export interface IB2BButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "traveler-selector" | "b2b-shop-filter" | "b2b";
}

export const B2BButton = ({
  className,
  children,
  variant,
  ...props
}: IB2BButton) => {
  return (
    <button
      className={clsx("b2b-button", { [variant]: true }, className)}
      {...props}
    >
      {children}
    </button>
  );
};
