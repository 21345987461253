import React from "react";

export const FlightDisruptionGuaranteeIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1771)">
        <path
          d="M32.7994 3.89865L22.1756 0.351542C20.7617 -0.117181 19.2344 -0.117181 17.8206 0.351542L7.19676 3.89865C5.82811 4.35394 4.6377 5.22886 3.79457 6.39918C2.95145 7.56949 2.49849 8.97569 2.5 10.4181V22.5342C2.50858 27.175 4.36035 31.6223 7.64794 34.8978C10.9355 38.1732 15.3896 40.0085 20.0304 40C24.6712 39.9914 29.1185 38.1396 32.394 34.852C35.6694 31.5644 37.5047 27.1103 37.4961 22.4696V10.4181C37.4977 8.97569 37.0447 7.56949 36.2016 6.39918C35.3585 5.22886 34.168 4.35394 32.7994 3.89865ZM33.7466 22.4695C33.7551 26.1158 32.3149 29.6162 29.7426 32.2006C27.1703 34.785 23.6767 36.2418 20.0304 36.2503C16.3841 36.2589 12.8837 34.8186 10.2993 32.2464C7.71489 29.6741 6.25817 26.1805 6.24959 22.5342V10.418C6.24876 9.76266 6.45445 9.1237 6.83746 8.59189C7.22047 8.06009 7.76131 7.66249 8.38315 7.45558L19.007 3.90848C19.6505 3.69524 20.3457 3.69524 20.9893 3.90848L31.6131 7.45558C32.2349 7.66251 32.7758 8.06013 33.1587 8.59194C33.5417 9.12375 33.7474 9.76272 33.7466 10.4181V22.4695ZM28.8228 13.6727C28.9969 13.8468 29.135 14.0534 29.2293 14.2808C29.3235 14.5083 29.372 14.7521 29.372 14.9983C29.372 15.2444 29.3235 15.4882 29.2293 15.7157C29.135 15.9431 28.9969 16.1497 28.8228 16.3238L18.8239 26.3227C18.6498 26.4968 18.4432 26.6349 18.2158 26.7292C17.9883 26.8234 17.7445 26.8719 17.4984 26.8719C17.2522 26.8719 17.0084 26.8234 16.7809 26.7292C16.5535 26.6349 16.3469 26.4968 16.1728 26.3227L11.1734 21.3232C10.8239 20.9712 10.6282 20.4951 10.6291 19.9991C10.63 19.5031 10.8275 19.0277 11.1782 18.677C11.5289 18.3263 12.0043 18.1288 12.5003 18.1279C12.9963 18.127 13.4724 18.3227 13.8244 18.6722L17.4984 22.3461L26.1717 13.6727C26.3458 13.4986 26.5524 13.3605 26.7798 13.2662C27.0073 13.172 27.2511 13.1235 27.4972 13.1235C27.7434 13.1235 27.9872 13.172 28.2147 13.2662C28.4421 13.3605 28.6487 13.4986 28.8228 13.6727Z"
          fill="#1875EC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1771">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
