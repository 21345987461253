import {
  CreateSessionFailureReason,
  VerificationMethod,
} from "@b2bportal/auth-api";
import { getChildState } from "@hopper-b2b/checkout";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BTextField } from "@hopper-b2b/ui";
import { Box } from "@mui/material";
import { FormEvent, useState } from "react";
import { AdditionalOptionSection, ResendSection } from "..";
import {
  AuthEvents,
  AuthMachineEventType,
  getError,
  getFormattedPhoneNumber,
  getVerificationAlternatives,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import { AuthType } from "../../../../types/tracking";
import "./styles.scss";
import { getAtHotelBrandButtonStyling } from "@hopper-b2b/utilities";

export const VerifyPhoneModal = () => {
  const { t } = useI18nContext();
  const [state, send] = useAuthState<AuthEvents, unknown>();
  const childState = getChildState(state.value);
  const [code, setCode] = useState<string>("");

  const phoneNumber = useAuthSelector(getFormattedPhoneNumber);
  const verificationAlternatives = useAuthSelector(getVerificationAlternatives);
  const error = useAuthSelector(getError);
  const hasAlternateMethod = verificationAlternatives?.redactedEmail;
  const redactedPhone = verificationAlternatives?.redactedPhone;

  const errorMessage =
    error === CreateSessionFailureReason.InvalidCode
      ? t("auth.errors.invalidCode")
      : null;

  const onContinue = (e: FormEvent<HTMLFormElement>) => {
    send({
      type: AuthMachineEventType.VERIFY_CODE,
      code,
      auth_type: AuthType.Phone,
    });
    e.preventDefault();
  };

  const resendCode = () => {
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByPhone,
    });
  };

  const onAdditionalOptionClick = () => {
    send(AuthMachineEventType.OTHER_OPTIONS);
  };

  return childState === "additionalOptions" ? (
    <AdditionalOption />
  ) : (
    <>
      <div className="title-container">
        <h1 className="title">{t("verifyPhoneTitle")}</h1>
        <p className="subtitle">
          {t("verifyCodeSubtitle", {
            emailOrPhone: phoneNumber || redactedPhone,
          })}
        </p>
      </div>
      <div className="verify-code-container">
        <form onSubmit={onContinue} noValidate>
          <B2BTextField
            onChange={(newCode: string) => setCode(newCode)}
            disabled={false}
            inputMode="numeric"
            className="verify-code-input-field"
            placeholder={t("verifyCodePlaceholder")}
            autoFocus
            error={!!error}
            errorHelper={errorMessage}
            allowInputSizeOverride
          />
          <Box mt={3}>
            <ActionButton
              fullWidth
              className="continue-button"
              type="submit"
              message={t("verify")}
              {...getAtHotelBrandButtonStyling({ filled: true })}
            />
          </Box>
        </form>
        <ResendSection onResend={resendCode} />
        <AdditionalOptionSection
          onClick={onAdditionalOptionClick}
          show={!!hasAlternateMethod}
        />
      </div>
    </>
  );
};

export const AdditionalOption = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();

  const sendCode = () =>
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByEmail,
    });

  const redactedEmail = useAuthSelector(
    getVerificationAlternatives
  )?.redactedEmail;

  return (
    <div className="phone-additional-option-content">
      <div className="title-container">
        <h1 className="title">{t("additionalModalTitle")}</h1>
        <p className="subtitle">{t("additionalModalSubtitle")}</p>
      </div>
      <div className="additional-method-card">
        <div className="labels">
          <p className="label">{t("email")}</p>
          <p className="redacted">{redactedEmail}</p>
        </div>
        <ActionButton onClick={sendCode} message={t("sendCode")} />
      </div>
    </div>
  );
};
