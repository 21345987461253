export const installColorConfig = (config: ColorConfig): void =>
  Object.keys(config).forEach((key) => {
    document.body.style.setProperty(`--${key}`, (config as any)[key]);
  });

export type ColorConfig = {
  primary: string;
  "primary-disabled": string;
  "secondary-blue": string;
  "tertiary-blue": string;
  "button-border": string;
  background: string;
  "background-secondary": string;
  "button-background": string;
  "button-background-disabled": string;
  "button-background-hover": string;
  "halfsheet-border-radius": string;
  "button-border-radius": string;
  "border-radius": string;
  "map-bubble-border": string;
  "blue-2": string;
  "blue-3": string;
  "blue-4": string;
  "blue-5": string;
  "blue-5-transparent": string;
  "blue-9": string;
  "blue-10": string;
  "blue-11": string;
  "blue-12": string;
  "blue-13": string;
  "blue-14": string;
  "blue-50": string;
  "blue-60": string;
  "user-icon-purple": string;
  "user-name-purple": string;
  "anchor-link-blue": string;
  white: string;
  black: string;
  "primary-text": string;
  "secondary-text": string;
  "tertiary-text": string;
  "black-text": string;
  "inverse-text": string;
  "green-1": string;
  "green-2": string;
  "green-3": string;
  "green-4": string;
  "green-5": string;
  "green-6": string;
  "green-50": string;
  "grey-3": string;
  "grey-4": string;
  "grey-5": string;
  "grey-6": string;
  "grey-7": string;
  "grey-8": string;
  "grey-9": string;
  "grey-10": string;
  "grey-11": string;
  "grey-11-transluscent": string;
  "grey-11-transparent": string;
  "grey-12": string;
  "grey-13": string;
  "grey-14": string;
  "grey-15": string;
  "inactive-grey": string;
  "inactive-button-icon-grey": string;
  "action-button-inactive-background": string;
  "action-button-inactive-text": string;
  "action-button-inactive-border": string;
  "action-button-active-background": string;
  "action-button-active-text": string;
  "filter-background-selected": string;
  "action-link-active-text": string;
  "info-details-popup-background": string;
  "info-details-popup-border": string;
  "popup-card-header-border": string;
  "calendar-green": string;
  "calendar-yellow": string;
  "calendar-orange": string;
  "calendar-red": string;
  "slider-bar-highlighted": string;
  "slider-bar-inactive": string;
  "badge-green-background": string;
  "text-green": string;
  "text-green-2": string;
  "text-green-2-transparent": string;
  "success-color": string;
  "alert-background-success": string;
  divider: string;
  "logout-link-text": string;
  "mobile-banner": string;
  "banner-background": string;
  "loading-popup": string;
  "link-text": string;
  "hamburger-menu-color": string;
  "icon-color": string;
  "disabled-icon-color": string;
  "card-tag-text": string;
  "card-tag-background": string;
  "secondary-button-background": string;
  "secondary-button-background-disabled": string;
  "secondary-button-background-hover": string;
  "input-background": string;
  "input-label-text": string;
  "card-border-color": string;
  "card-border": string;
  "price-freeze-card-color": string;
  "price-freeze-card-button": string;
  "disruption-background-color": string;
  confirm: string;
  "trips-list-background-color": string;
  "trips-selected-filter-color": string;
  "trips-link-color": string;
  "disabled-input-background-color": string;
  "flight-card-icon-color": string;
  "red-1": string;
  "red-2": string;
  "red-3": string;
  "red-4": string;
  "red-40": string;
  "yellow-1": string;
  "yellow-2": string;
  "yellow-3": string;
  "emphasis-blue": string;
  // CALENDAR COLORS
  "bucket-0-color": string;
  "bucket-1-color": string;
  "bucket-2-color": string;
  "bucket-3-color": string;
  "grey-font-color": string;
  "date-hover-color": string;
  "date-selected-color": string;
  "date-range-limit-color": string;
  "date-disabled-color": string;
  "price-freeze-text": string;
  "toggle-grey": string;
  "white-transparent-border": string;
  "breakpoint-mobile-min": string;
  "breakpoint-mobile-max": string;
  "breakpoint-tablet-min": string;
  "breakpoint-tablet-max": string;
  "breakpoint-laptop-min": string;
  "breakpoint-laptop-max": string;
  "breakpoint-desktop-min": string;
  "breakpoint-desktop-max": string;
  "breakpoint-large-screen-min": string;
  "breakpoint-macbook-air": string;
  "z-modal": string;
  "z-float": string;
};

export interface B2BColorConfig extends ColorConfig {
  "blue-14-transparent": string;
  "box-shadow": string;
  "backdrop-shadow": string;
  "white-transparent": string;
  "algomerch-airline": string;
  "algomerch-fareclass": string;
  "algomerch-price": string;
  "algomerch-duration": string;
  "algomerch-stops": string;
  "price-prediction-great": string;
  "price-prediction-good": string;
  "price-prediction-fair": string;
  "price-prediction-wait": string;
  "price-prediction-gradient-wait": string;
  "price-prediction-gradient-fair": string;
  "price-prediction-gradient-good": string;
  "price-prediction-gradient-great": string;
  "price-prediction-gradient-great-transparent": string;
  "tag-background-grey": string;
  "red-error-color": string;
  "red-error-color-transparent": string;
  "warning-error-color": string;
  "warning-error-color-transparent": string;
  "light-spinner-blue": string;
  "ftux-background-blue": string;
  "ftux-background-blue-transparent": string;
  "takeover-background-blue": string;
  "price-freeze-purchase-banner-background-color": string;
  "price-freeze-purchase-banner-text-color": string;
  "spinner-border-color": string;
  "spinner-top-border-color": string;
  "wallet-offer-banner-background-color": string;
  "wallet-offer-banner-color": string;
  "checkout-banner-background-color": string;
}

const colors = {
  white: "#ffffff",
  black: "#141414",
  "white-transparent-98": "rgba(255, 255, 255, .98)",
  "white-transparent": "rgba(255, 255, 255, 0.48)",
  coral: "#fa6866",
  skyblue: "#1875EC",
  "skyblue-disabled": "rgba(1, 170, 228, 0.5)",
  darkblue: "#008ac6",
  "blue-2": "#EFF3FE",
  "blue-3": "#D4E2FC",
  "blue-4": "rgba(0, 179, 241, 0.1)",
  "blue-5": "rgb(36, 96, 130)",
  "blue-5-transparent": "rgba(36, 96, 130, 0.1)",
  "blue-9": "rgb(49, 190, 199)",
  "blue-10": "rgba(228, 246, 253, 0.25)",
  "blue-11": "#122741",
  "blue-12": "#00132B",
  "blue-13": "#F0FAFF",
  "blue-14": "#10253F",
  "blue-14-transparent": "rgba(16, 37, 63, 0.9)",
  "blue-20": "#DFF5FF",
  "blue-50": "#1875EC",
  "blue-60": "#008ecc",
  "grey-3": "#E8E8E8",
  "grey-4": "#979797",
  "grey-5": "#d1d5db",
  "grey-6": "#aaaaaa",
  "grey-7": "#5a5a5a",
  "grey-8": "#f1f1f1",
  "grey-9": "rgb(80,80,80)",
  "grey-10": "#f6f6f6",
  "grey-11": "rgb(224, 224, 224)",
  "grey-11-transluscent": "rgba(224, 224, 224, 0.3)",
  "grey-11-transparent": "rgba(224, 224, 224, 0.1)",
  "grey-12": "rgb(80,80,80)",
  "grey-13": "#f8f7fa",
  "grey-14": "#636363",
  "grey-15": "#f3f3f3",
  "grey-20": "#d9d9d9",
  "grey-100": "#505050",
  "grey-200": "#878787",
  "grey-300": "#929292",
  "grey-400": "#D8D8D8",
  "grey-500": "#E2E2E2",
  "grey-600": "#606a77",
  "grey-6000": "#545454",
  "green-3": "rgb(99, 182, 127)",
  "green-4": "rgb(224, 240, 229)",
  "green-5": "rgb(0, 129, 64)",
  "green-5-transparent": "rgb(0, 129, 64, 0.1)",
  "green-6": "#eaf6ed",
  "green-50": "#60b955",
  "red-1": "#fa6866",
  "red-2": "#e02020",
  "red-3": "#cf212b",
  "red-4": "#D03228",
  "red-40": "#CC2427",
  "yellow-1": "#d99422",
  "yellow-2": "#f7b500",
  "yellow-3": "#e69100",
  "green-1": "#38b97a",
  "green-2": "#008140",
  "calendar-green": "#6fc28b",
  "calendar-yellow": "#f0a02a",
  "calendar-orange": "#e36624",
  "calendar-red": "#d92c2c",
  "orange-1": "#f58908",
  "orange-2": "#fdf3e7",
};

export const defaultColors: ColorConfig = {
  ...colors,
  primary: colors.skyblue,
  "primary-disabled": colors["skyblue-disabled"],
  "secondary-blue": colors.darkblue,
  "tertiary-blue": colors.skyblue,
  background: colors.white,
  "background-secondary": "#f3f3f3",
  "button-background": colors.skyblue,
  "button-background-disabled": colors["skyblue-disabled"],
  "button-background-hover": colors.darkblue,
  "button-border": "2px solid #ededed",
  "halfsheet-border-radius": "1rem",
  "button-border-radius": "0.5rem",
  "border-radius": "0.5rem",
  "map-bubble-border": colors["grey-20"],
  "user-icon-purple": "rgb(98, 54, 255)",
  "user-name-purple": "rgba(98, 54, 255, 0.2)",
  "anchor-link-blue": "#00b4ff",
  "primary-text": colors["grey-100"],
  "secondary-text": colors["grey-200"],
  "tertiary-text": colors["grey-300"],
  "black-text": colors.black,
  "inverse-text": colors.white,
  "emphasis-blue": "rgb(0, 195, 203)",
  "inactive-grey": colors["grey-10"],
  "inactive-button-icon-grey": colors["grey-20"],
  "action-button-inactive-background": colors["grey-10"],
  "action-button-inactive-text": colors["grey-6"],
  "action-button-inactive-border": colors["grey-20"],
  "action-button-active-background": colors.white,
  "action-button-active-text": colors.skyblue,
  "filter-background-selected": colors["blue-5-transparent"],
  "action-link-active-text": colors.skyblue,
  "popup-card-header-border": "rgba(0, 0, 0, 0.12)",
  "badge-green-background": "rgb(240, 245, 243)",
  "info-details-popup-background": "rgb(251, 251, 251)",
  "info-details-popup-border": colors["grey-400"],
  "slider-bar-highlighted": colors["skyblue"],
  "slider-bar-inactive": "#e0e0e0",
  "text-green": "#63b67f",
  "text-green-2": colors["green-5"],
  "text-green-2-transparent": colors["green-5-transparent"],
  "success-color": colors["green-5"],
  "alert-background-success": colors["green-5-transparent"],
  divider: colors["grey-20"],
  "logout-link-text": colors["red-4"],
  "mobile-banner": colors.coral,
  "banner-background": colors["blue-9"],
  "loading-popup": colors.coral,
  "link-text": colors["grey-200"],
  "hamburger-menu-color": "var(--primary)",
  "icon-color": "var(--primary)",
  "disabled-icon-color": colors["grey-400"],
  "card-tag-text": colors["blue-9"],
  "card-tag-background": colors["blue-10"],
  "secondary-button-background": colors["white"],
  "secondary-button-background-disabled": colors["grey-3"],
  "secondary-button-background-hover": colors["grey-3"],
  "input-background": colors["grey-3"],
  "input-label-text": colors["grey-200"],
  "card-border-color": colors["grey-500"],
  "card-border": "2px solid var(--card-border-color)",
  "price-freeze-card-color": colors["blue-2"],
  "price-freeze-card-button": colors["blue-3"],
  "disruption-background-color": colors["green-6"],
  confirm: colors["green-50"],
  "trips-list-background-color": colors.white,
  "trips-selected-filter-color": "var(--primary-disabled)",
  "trips-link-color": "var(--primary)",
  "disabled-input-background-color": colors["grey-10"],
  "flight-card-icon-color": colors["grey-6"],
  // CALENDAR COLORS
  "bucket-0-color": colors["green-5"],
  "bucket-1-color": colors["calendar-yellow"],
  "bucket-2-color": colors["calendar-orange"],
  "bucket-3-color": "#cc2427",
  "grey-font-color": colors["grey-100"],
  "date-hover-color": colors.skyblue,
  "date-selected-color": colors.skyblue,
  "date-range-limit-color": colors.skyblue,
  "date-disabled-color": colors["grey-11-transluscent"],
  "price-freeze-text": "#31bec7",
  "toggle-grey": "#ebebeb",
  "white-transparent-border": "rgba(255, 255, 255, 0.25)",
  // Responsive design variables
  "breakpoint-mobile-min": "320px",
  "breakpoint-mobile-max": "480px",
  "breakpoint-tablet-min": "480px",
  "breakpoint-tablet-max": "768px",
  "breakpoint-laptop-min": "768px",
  "breakpoint-laptop-max": "960px",
  "breakpoint-desktop-min": "960px",
  "breakpoint-desktop-max": "1200px",
  "breakpoint-large-screen-min": "1200px",
  "breakpoint-macbook-air": "1440px",
  "z-modal": "1300",
  "z-float": "500",
};

export const defaultB2BColors: B2BColorConfig = {
  ...colors,
  ...defaultColors,
  "box-shadow": "rgba(0, 0, 0, 0.05)",
  "backdrop-shadow": "rgba(0, 0, 0, 0.25)",
  // ALGOMERCH COLORS
  "algomerch-airline": "rgba(204, 36, 39, 1.0)",
  "algomerch-fareclass": "rgba(0, 118, 193, 1.0)",
  "algomerch-price": "rgba(0, 129, 64, 1.0)",
  "algomerch-duration": "rgba(240, 160, 42, 1.0)",
  "algomerch-stops": "rgba(1, 61, 88, 1.0)",
  // PRICE PREDICTION COLORS
  "price-prediction-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-fair": "rgba(236, 130, 65, 1.0)",
  "price-prediction-good": "rgba(249, 209, 0, 1.0)",
  "price-prediction-great": "rgba(0, 129, 64, 1.0)",
  // PRICE PREDICTION GRADIENT COLORS
  "price-prediction-gradient-wait": "rgba(199, 1, 22, 1.0)",
  "price-prediction-gradient-fair": "rgba(236, 130, 65, 1.0)",
  "price-prediction-gradient-good": "rgba(249, 209, 0, 1.0)",
  "price-prediction-gradient-great": "rgba(0, 129, 64, 1.0)",
  "price-prediction-gradient-great-transparent": "rgba(0, 129, 64, 0.05)",
  "tag-background-grey": "rgba(216, 216, 216, 0.2)",
  // SCHEDULE CHANGE COLORS
  "red-error-color": "rgba(204, 36, 39, 1.0)",
  "red-error-color-transparent": "rgba(252, 213, 209, 1.0)",
  "warning-error-color": "rgba(249, 198, 6, 1.0)",
  "warning-error-color-transparent": "rgba(255, 241, 208, 1.0)",
  "light-spinner-blue": "rgba(58, 188, 253, 1.0)",
  "ftux-background-blue": "rgba(18, 39, 65, 1)",
  "ftux-background-blue-transparent": "rgba(18, 39, 65, 0.95)",
  "takeover-background-blue": "rgba(225, 245, 255, 1)",
  "price-freeze-purchase-banner-background-color": colors.black,
  "price-freeze-purchase-banner-text-color": colors.white,
  "spinner-border-color": colors["grey-10"],
  "spinner-top-border-color": colors["blue-10"],
  // WALLET STEP COLORS
  "wallet-offer-banner-background-color": colors["blue-60"],
  "wallet-offer-banner-color": colors["white"],
  "checkout-banner-background-color": colors["blue-9"],
};

export enum COLOR_THEMES {
  LIGHT = "light",
  DARK = "dark",
}
