import {
  CalendarBuckets,
  CalendarBucketsResponse,
  DepartureDateReport,
  airShoppingApi,
} from "@b2bportal/air-shopping-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../../AxiosInterceptor";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchCalendar = async (
  body: CalendarBuckets
): Promise<DepartureDateReport> => {
  try {
    const res = await airShoppingApi(axiosInstance).apiV0CalendarPost(
      body as CalendarBuckets
    );

    const response: CalendarBucketsResponse = res.data;
    return response.departureDateReport;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};
