"use client";

import { COLOR_THEMES, installColorConfig } from "@hopper-b2b/types";
import {
  getDarkModePreferred,
  useFlashSaleContext,
} from "@hopper-b2b/utilities";
import {
  Box,
  BoxProps,
  CssBaseline,
  ScopedCssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import {
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { hopperDarkVariables, muiDarkTheme } from "./darkTheme";
import { hopperVariables, muiTheme } from "./defaultTheme";
import { hdsTheme } from "./hdsTheme";
import { tokens } from "./tokens";
import dayjs from "dayjs";

export type HopperThemingProviderProps = {
  enableDarkMode?: boolean;
  enableHDSTheme?: boolean;
  isOnPrimary?: boolean;
};

export const HopperThemingProvider = forwardRef(
  (
    {
      children,
      enableDarkMode,
      enableHDSTheme,
      isOnPrimary = false,
      ...rest
    }: PropsWithChildren<HopperThemingProviderProps> & BoxProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { sale } = useFlashSaleContext();
    const isFlashSaleActive = useMemo(
      () =>
        dayjs().unix() < sale?.activeTimeline.presale.seconds ||
        dayjs().unix() > sale?.activeTimeline.end.seconds,
      [sale]
    );
    let defaultTheme = { ...hdsTheme };
    if (isOnPrimary) {
      defaultTheme = {
        ...defaultTheme,
        colorMode: "onPrimary",
        palette: {
          ...defaultTheme.palette,
          text: {
            ...defaultTheme.palette.text,
            primary: tokens.primary.contrast,
          },
        },
        components: {
          ...defaultTheme.components,
          MuiButton: {
            ...defaultTheme.components.MuiButton,
            defaultProps: {
              ...defaultTheme.components.MuiButton.defaultProps,
              color: "secondary",
            },
            ...(isFlashSaleActive && {
              variants: defaultTheme.components.MuiButton.variants.concat([
                {
                  props: { variant: "outlined", color: "secondary" },
                  style: {
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.03)",
                    },
                    "&:active": {
                      background: "rgba(0, 0, 0, 0.06)",
                    },
                  },
                },
                {
                  props: { variant: "text", color: "secondary" },
                  style: {
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.03)",
                    },
                    "&:active": {
                      background: "rgba(0, 0, 0, 0.06)",
                    },
                  },
                },
                {
                  props: { variant: "secondary", color: "secondary" },
                  style: {
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.03)",
                    },
                    "&:active": {
                      background: "rgba(0, 0, 0, 0.06)",
                    },
                  },
                },
              ]),
            }),
          },
          MuiCheckbox: {
            ...defaultTheme.components.MuiCheckbox,
            defaultProps: {
              ...defaultTheme.components.MuiCheckbox.defaultProps,
              color: "secondary",
            },
          },
          MuiIconButton: {
            ...defaultTheme.components.MuiIconButton,
            defaultProps: {
              ...defaultTheme.components.MuiIconButton.defaultProps,
              color: "secondary",
            },
            ...(isFlashSaleActive && {
              variants: defaultTheme.components.MuiIconButton.variants.concat([
                {
                  props: { color: "secondary" },
                  style: {
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.03)",
                    },
                    "&:active": {
                      background: "rgba(0, 0, 0, 0.06)",
                    },
                  },
                },
              ]),
            }),
          },
          MuiRadio: {
            ...defaultTheme.components.MuiRadio,
            defaultProps: {
              ...defaultTheme.components.MuiRadio.defaultProps,
              color: "secondary",
            },
          },
        },
      };
    } else {
      defaultTheme = {
        ...defaultTheme,
        colorMode: "default",
        palette: {
          ...defaultTheme.palette,
          text: {
            ...defaultTheme.palette.text,
            primary: tokens.text.primary,
          },
        },
        components: {
          ...defaultTheme.components,
          MuiButton: {
            ...defaultTheme.components.MuiButton,
            defaultProps: {
              ...defaultTheme.components.MuiButton.defaultProps,
              color: "primary",
            },
          },
          MuiCheckbox: {
            ...defaultTheme.components.MuiCheckbox,
            defaultProps: {
              ...defaultTheme.components.MuiCheckbox.defaultProps,
              color: "primary",
            },
          },
          MuiIconButton: {
            ...defaultTheme.components.MuiIconButton,
            defaultProps: {
              ...defaultTheme.components.MuiIconButton.defaultProps,
              color: "primary",
            },
          },
          MuiRadio: {
            ...defaultTheme.components.MuiRadio,
            defaultProps: {
              ...defaultTheme.components.MuiRadio.defaultProps,
              color: "primary",
            },
          },
        },
      };
    }
    const [theme, setTheme] = useState(
      enableHDSTheme ? defaultTheme : muiTheme
    );
    const [isDarkMode, setIsDarkMode] = useState(false);

    const isDarkModePreferred = useMemo(() => {
      const colorScheme = getDarkModePreferred()
        ? COLOR_THEMES.DARK
        : COLOR_THEMES.LIGHT;
      return isDarkMode && colorScheme === COLOR_THEMES.DARK;
    }, [isDarkMode]);

    useEffect(() => {
      if (enableDarkMode) {
        setIsDarkMode(true);
      }
    }, [enableDarkMode]);

    useEffect(() => {
      if (isDarkModePreferred) {
        setTheme(muiDarkTheme);
      }
    }, [isDarkMode, isDarkModePreferred]);

    useEffect(() => {
      installColorConfig(
        isDarkModePreferred ? hopperDarkVariables : hopperVariables
      );
    }, [isDarkModePreferred]);

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {enableHDSTheme ? (
            <Box component={ScopedCssBaseline} {...rest} ref={ref}>
              {children}
            </Box>
          ) : (
            <>
              <CssBaseline />
              {children}
            </>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
);
