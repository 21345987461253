"use client";

import dayjs from "dayjs";
import { createContext, PropsWithChildren, useContext } from "react";

export type IFlashSaleContextProps = {
  // placeholder until we build real TS models from bff
  sale?: {
    activeTimeline: {
      presale: {
        seconds: number;
      };
      start: {
        seconds: number;
      };
      end: {
        seconds: number;
      };
    };
  };
};

export interface IFlashSaleContextProvider {
  flashSaleContext: IFlashSaleContextProps;
}

export const defaultFlashSaleContext: IFlashSaleContextProps = {
  // placeholder until we dynamically call endpoint from bff
  // sale: {
  //   activeTimeline: {
  //     presale: {
  //       seconds: 1732579200,
  //     },
  //     start: {
  //       seconds: 1733184000,
  //     },
  //     end: {
  //       seconds: 1733270400,
  //     },
  //   },
  // },
};

export const FlashSaleContext = createContext<IFlashSaleContextProps>(
  defaultFlashSaleContext
);

export const useFlashSaleContext = () => {
  const ctx = useContext(FlashSaleContext);
  if (ctx === undefined)
    throw new Error(`must be used within a FlashSaleContextProvider`);
  return { ...ctx };
};

export const FlashSaleContextProvider = ({
  children,
  flashSaleContext,
}: PropsWithChildren<IFlashSaleContextProvider>) => {
  return (
    <FlashSaleContext.Provider value={flashSaleContext}>
      {children}
    </FlashSaleContext.Provider>
  );
};

export const getTimeLeft = (targetEpoch: number) => {
  const now = dayjs();
  const targetTime = dayjs.unix(targetEpoch);
  return dayjs.duration(targetTime.diff(now));
};

export const formatTimeLeft = (duration: plugin.Duration) => {
  const days = String(duration?.days() || 0).padStart(2, "0");
  const hours = String(duration?.hours() || 0).padStart(2, "0");
  const minutes = String(duration?.minutes() || 0).padStart(2, "0");
  const seconds = String(duration?.seconds() || 0).padStart(2, "0");
  return `${days} : ${hours} : ${minutes} : ${seconds}`;
};
