export const colors = {
  success: {
    main: "#2E7D32",
  },
  gray: {
    white: "#FFFFFF",
    10: "#F6F6F6",
    20: "#EDEDED",
    30: "#D9D9D9",
    40: "#AAAAAA",
    50: "#9D9D9D",
    60: "#878787",
    70: "#606060",
    80: "#505050",
    90: "#333333",
    100: "#111111",
  },
  blue: {
    5: "#E7F8FF",
    10: "#C0E8FF",
    20: "#90CEFE",
    30: "#5BB0FD",
    40: "#3892FC",
    50: "#1878EC",
    60: "#135FC3",
    70: "#0E4799",
    80: "#0A3372",
    90: "#042050",
    100: "#021130",
  },
  coral: {
    5: "#FFF8F5",
    10: "#FEE2D9",
    20: "#FECBBD",
    30: "#FCA18F",
    40: "#F98678",
    50: "#FA6866",
    60: "#F24E4C",
    70: "#E94249",
    80: "#D73848",
    90: "#AC2B3E",
    100: "#47121D",
  },
  red: {
    5: "#FFF8F6",
    10: "#FFE3DD",
    20: "#FFBCAC",
    30: "#FD8A70",
    40: "#E05F40",
    50: "#C33916",
    60: "#9F2006",
    70: "#811302",
    80: "#6B0B01",
    90: "#570801",
    100: "#380500",
  },
  green: {
    5: "#F4FAF1",
    10: "#E9F7E4",
    20: "#C3F2B9",
    30: "#8FE489",
    40: "#68CC6F",
    50: "#49AF5C",
    60: "#298C42",
    70: "#0F6629",
    80: "#024919",
    90: "#00350F",
    100: "#002508",
  },
};

const fontSize = {
  1: 10,
  2: 12,
  3: 14,
  4: 16,
  5: 18,
  6: 20,
  7: 24,
  8: 28,
  9: 32,
  10: 36,
  11: 40,
  12: 44,
  13: 48,
  14: 56,
};

export const typography = {
  fontFamily: {
    main: "Proxima Nova, sans-serif",
  },
  fontWeight: {
    regular: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  fontSize,
  fontLineHeight: {
    1: 16 / fontSize[1],
    2: 16 / fontSize[2],
    3: 20 / fontSize[3],
    4: 24 / fontSize[4],
    5: 24 / fontSize[5],
    6: 28 / fontSize[6],
    7: 32 / fontSize[7],
    8: 36 / fontSize[8],
    9: 40 / fontSize[9],
    10: 44 / fontSize[10],
    11: 48 / fontSize[11],
    12: 52 / fontSize[12],
    13: 56 / fontSize[13],
    14: 64 / fontSize[14],
  },
};

export const spacing = {
  25: 2,
  50: 4,
  100: 8,
  150: 12,
  200: 16,
  250: 20,
  300: 24,
  350: 28,
  400: 32,
  450: 36,
  500: 40,
  600: 48,
  800: 64,
  1000: 80,
  1200: 96,
};
