import { UserInfoField } from "@b2bportal/auth-api";
import { I18nNamespace, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { useTrackEvent } from "@hopper-b2b/tracking";
import { B2BTextField, PhoneInputField } from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui-core";
import { emailRegex, isAtHotelBrand, phoneRegex } from "@hopper-b2b/utilities";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  getMissingFields,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthTrackingEvents,
  ViewedFinalizeEvent,
} from "../../../../types/tracking";
import "./styles.scss";

export const FinalizeForm = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();
  const [missingInfo, setMissingInfo] = useState<
    Record<UserInfoField | string, string>
  >({});
  const trackEvent = useTrackEvent();

  const authType = useAuthSelector(getAuthType);
  const missingFields = useAuthSelector(getMissingFields);
  const isFirstNameMissing = missingFields.includes(UserInfoField.FirstName); // Always required
  const isLastNameMissing = missingFields.includes(UserInfoField.LastName); // Always required
  const isEmailMissing = missingFields.includes(UserInfoField.Email);
  const isPhoneMissing = missingFields.includes(UserInfoField.PhoneNumber);

  const onContinue = () => {
    send({
      type: AuthMachineEventType.FINALIZE_USER,
      missingFields: {
        Email: missingInfo["Email"],
        FirstName: missingInfo["FirstName"],
        LastName: missingInfo["LastName"],
        PhoneNumber: missingInfo["PhoneNumber"],
      },
    });
  };

  const enabled = useMemo(() => {
    const hasAllFields = missingFields.every((field) => !!missingInfo[field]);
    const emailCheck = missingFields.includes("Email")
      ? missingInfo["Email"] === missingInfo["ConfirmEmail"] &&
        emailRegex.test(missingInfo["Email"])
      : true;
    const phoneCheck = missingFields.includes("PhoneNumber")
      ? phoneRegex.test(missingInfo["PhoneNumber"])
      : true;
    return hasAllFields && emailCheck && phoneCheck;
  }, [missingFields, missingInfo]);

  useEffect(() => {
    trackEvent({
      eventName: AuthTrackingEvents.ViewedFinalize,
      properties: {
        auth_type: authType,
      },
    } as ViewedFinalizeEvent);
  }, []);

  return (
    <>
      <div className="title-container">
        <h1 className="title">
          {isAtHotelBrand() ? t("newAtHotelUserTitle") : t("newUserTitle")}
        </h1>
        <p className="subtitle">{t("newUserSubtitle")}</p>
      </div>
      <div className="finalize-form-container">
        <div className="name-input-container">
          {isFirstNameMissing ? (
            <B2BTextField
              onChange={(newFirstName: string) =>
                setMissingInfo({
                  ...missingInfo,
                  [UserInfoField.FirstName]: newFirstName,
                })
              }
              className={"firstname-input"}
              label={t("firstName")}
            />
          ) : null}
          {isLastNameMissing ? (
            <B2BTextField
              onChange={(newLastName: string) =>
                setMissingInfo({
                  ...missingInfo,
                  [UserInfoField.LastName]: newLastName,
                })
              }
              className={"lastname-input"}
              label={t("lastName")}
            />
          ) : null}
        </div>
        {isEmailMissing ? (
          <>
            <B2BTextField
              onChange={(newEmail: string) =>
                setMissingInfo({
                  ...missingInfo,
                  [UserInfoField.Email]: newEmail,
                })
              }
              className={"email-input"}
              label={t("email")}
            />
            <B2BTextField
              onChange={(newConfirmEmail: string) =>
                setMissingInfo({
                  ...missingInfo,
                  ConfirmEmail: newConfirmEmail,
                })
              }
              className={"confirm-email-input"}
              label={"Confirm Email"} //TODO I18n
            />
          </>
        ) : null}
        {isPhoneMissing ? (
          <PhoneInputField
            label={t("phoneNumber")}
            countryCode={"+1"}
            onChange={(phoneNumber, countryCode) => {
              setMissingInfo({
                ...missingInfo,
                [UserInfoField.PhoneNumber]: `${countryCode}${phoneNumber}`,
              });
            }}
            disabled={false}
          />
        ) : null}
        <span className="terms-text">
          <Trans
            i18nKey="createAccountAgreeToTermsAndPolicy"
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={t(`${[I18nNamespace.brand]}:urls.termsAndConditions`)}
              />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={t(`${[I18nNamespace.brand]}:urls.privacyPolicy`)} />,
            ]}
          />
        </span>
        <Box mt={3}>
          <ActionButton
            fullWidth
            onClick={onContinue}
            message={t("createAccount")}
            className="continue-button"
            disabled={!enabled}
          />
        </Box>
      </div>
    </>
  );
};
