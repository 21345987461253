import { Currency } from "@hopper-b2b/types";

export function defaultCurrency(
  shouldDefaultCurrency: boolean,
  userBackendDefaultCurrency?: string
): Currency | undefined {
  const result = shouldDefaultCurrency
    ? (userBackendDefaultCurrency as Currency)
    : undefined;

  return result;
}
