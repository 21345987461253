"use client";

import { createContext, useContext, PropsWithChildren } from "react";
import { Amenity } from "@b2bportal/lodging-api";

export interface ITenantContextProps {
  icons?: IIconSet;
  images?: IImageSet;
}

export interface IImageSet {
  flightBookingConfirmationImage?: string;
  ViBookingConfirmationImage?: string;
  placeHolder?: {
    room?: string;
  };
}

export interface IIconSet {
  calendar?: string;
  calendarStartIcon?: string;
  calendarEndIcon?: string;
  flights?: string;
  guest?: string;
  hotels?: string;
  location?: string;
  airplane?: string;
  airplaneArrive?: string;
  airplaneDepart?: string;
  airplaneFintech?: string;
  airplaneDiagonal?: string;
  airplaneCalendar?: string;
  airplaneRight?: string;
  airplaneLeft?: string;
  avatar?: string;
  bell?: string;
  card?: string;
  creditCards?: {
    visa?: string;
    masterCard?: string;
    americanExpress?: string;
    discover?: string;
    default?: string;
  };
  applePay?: string;
  cfar?: string;
  check?: string;
  clock?: string;
  error?: string;
  checkmarkFilled?: string;
  checkmarkLightGreen?: string;
  checkmarkSimple?: string;
  chevron?: string;
  chevronLeft?: string;
  filter?: string;
  flightDelay?: string;
  info?: string;
  infoQuestion?: string;
  locationAutocompleteMarker?: string;
  locationMarker?: string;
  lock?: string;
  blackLock?: string;
  oneway?: string;
  roundtrip?: string;
  passenger?: string;
  priceFreeze?: string;
  missedConnection?: string;
  money?: {
    solid?: string;
    transparent?: string;
  };
  phone?: {
    solid?: string;
  };
  shield?: string;
  carrotCoin?: string;
  wallet?: string;
  walletIcon?: string;
  logoSmall?: string;
  fareRatings?: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
  };
  amenities?: {
    [key in keyof typeof Amenity]?: string;
  };
  star?: string;
  bunnyWithSuitcase?: string;
  bunnyHappy?: string;
  bed?: string;
  close?: string;
  closeWhite?: string;
  grayGuest?: string;
  grayShieldX?: string;
  infoWhite?: string;
  logoIcon?: string;
  greenShieldCheck?: string;
  copy?: string;
  vipIcon?: string;
  headset?: string;
  radio?: string;
  radioSelected?: string;
  loading?: string;
  pencil?: string;
  trashCan?: string;
  noTrips?: string;
  logoWhite?: string;
  multipleGuests?: string;
  back?: string;
  oneCircle?: string;
  twoCircle?: string;
  threeCircle?: string;
  fourCircle?: string;
  fiveCircle?: string;
  alertGray?: string;
  infoGrayOutline?: string;
  systemCheckGreen?: string;
  systemDismissRed?: string;
  bunnyFallback?: string;
  bunniesClapping?: string;
  silverCoin?: string;
  missedConnectionBunny?: string;
  selfTransferLayover?: string;
  tripMcpProtection?: string;
  virtualInterlineBunny?: string;
  accordionArrow?: string;
  baggageIcon?: string;
  customerSupportIcon?: string;
  quote?: string;
  seatIcon?: string;
  baggageCheckedIcon?: string;
  flightOutlineIcon?: string;
  segmentIcon?: string;
  locationSolidSlate60?: string;
}

export interface ITenantContextProvider {
  tenantContext: ITenantContextProps;
}

const TenantContext = createContext<ITenantContextProps>({});

export function useTenantContext(): ITenantContextProps {
  const ctx = useContext(TenantContext);
  if (ctx === undefined)
    throw new Error(`must be used within a TenantContextProvider`);
  return ctx;
}

export const useTenantIcons = (): IIconSet => {
  const ctx = useContext(TenantContext);
  if (ctx === undefined)
    throw new Error(`must be used within a TenantContextProvider`);
  return ctx.icons ?? {};
};

export const useTenantImages = (): IImageSet => {
  const ctx = useContext(TenantContext);
  if (ctx === undefined)
    throw new Error(`must be used within a TenantContextProvider`);
  return ctx.images ?? {};
};

export const TenantContextProvider = ({
  children,
  tenantContext,
}: PropsWithChildren<ITenantContextProvider>) => {
  return (
    <TenantContext.Provider value={tenantContext}>
      {children}
    </TenantContext.Provider>
  );
};
