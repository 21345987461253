import { XIcon } from "@marketing-site/static/icons";
import { media } from "@marketing-site/styles";
import { EventActionName } from "@marketing-site/types";
import { IImage } from "@marketing-site/types/shared.model";
import {
  getAppsFlyerParams,
  getSanitizedDeepLinkUrl,
} from "@marketing-site/utils/helpers";
import { LocaleContext } from "@marketing-site/utils/hooks/localeContext";
import { useTrackMarketingSiteEvent } from "@marketing-site/utils/hooks";
import { Dialog } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "./styles.scss";

export interface ITakeoverImage {
  experimentIdentifier: string;
  image: IImage;
  downloadUrl: string;
}

export function TakeoverImage(props: ITakeoverImage) {
  const currLocale = useContext(LocaleContext);
  const appsFlyerParams = getAppsFlyerParams(props.downloadUrl);
  const [open, setOpen] = useState<boolean>(true);

  const trackEvent = useTrackMarketingSiteEvent();

  useEffect(() => {
    trackEvent({
      eventName: EventActionName.ViewedCta,
      properties: appsFlyerParams,
    });
  }, []);

  const handleDismiss = useCallback(() => {
    setOpen(false);
    trackEvent({
      eventName: EventActionName.ClosedCta,
      properties: appsFlyerParams,
    });
  }, []);

  const trackClick = () => {
    trackEvent({
      eventName: EventActionName.ClickedCta,
      properties: appsFlyerParams,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleDismiss}
      PaperProps={{
        style: {
          boxShadow: "unset",
          borderRadius: 12,
          backgroundColor: "transparent",
          margin: "0 auto",
          overflow: "hidden",
          position: "relative",
        },
      }}
      className={"takeover-image-container"}
    >
      <Close onClick={() => handleDismiss()} id="takeover_close_btn">
        <XIcon />
      </Close>
      <a
        href={getSanitizedDeepLinkUrl(props.downloadUrl, currLocale)}
        onClick={trackClick}
      >
        <img
          className={"takeover-image"}
          src={props.image.url}
          alt={props.experimentIdentifier}
        />
      </a>
    </Dialog>
  );
}

const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 0px;
  right: 0px;
  ${media.tablet`
    padding: 0px;
  `}
  padding: 2px;
  color: #1875ec;
  border: none;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
`;
