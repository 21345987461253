import common_translations from "../common.json";
import app from "./app.json";
import athotel from "./athotel.json";
import checkout from "./checkout.json";
import checkoutV2 from "./checkoutv2.json";
import exchange from "./exchange.json";
import fintech from "./fintech.json";
import flights from "./flights.json";
import homes from "./homes.json";
import hotels from "./hotels.json";
import seo from "./seo.json";
import trips from "./trips.json";
import ui from "./ui.json";
import wallet from "./wallet.json";
import hopper from "./hopper.translation.json";

const bundle = {
  ...common_translations,
  ...app,
  ...athotel,
  ...flights,
  ...homes,
  ...hotels,
  ...ui,
  ...checkout,
  ...checkoutV2,
  ...seo,
  ...trips,
  ...fintech,
  ...exchange,
  ...wallet,
};
export default bundle;

export const en_hopperTranslations = hopper;
