import React from "react";

export const CarrotCashIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1878)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8886 3.37061C12.1776 1.17298 16.0444 0 20 0C25.3025 0.00606532 30.386 2.11515 34.1354 5.86456C37.8848 9.61397 39.9939 14.6975 40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65492 36.9392 5.85787 34.1421C3.06082 31.3451 1.15601 27.7814 0.384303 23.9018C-0.387401 20.0222 0.00866578 16.0009 1.52242 12.3463C3.03617 8.69181 5.59962 5.56823 8.8886 3.37061ZM10.7405 33.8578C13.4813 35.6892 16.7036 36.6667 20 36.6667C24.4188 36.6617 28.6551 34.9042 31.7797 31.7797C34.9042 28.6551 36.6618 24.4188 36.6667 20C36.6667 16.7036 35.6892 13.4813 33.8578 10.7405C32.0265 7.99968 29.4235 5.86347 26.3781 4.60201C23.3326 3.34055 19.9815 3.01049 16.7485 3.65358C13.5155 4.29666 10.5458 5.88401 8.21489 8.21488C5.88402 10.5458 4.29667 13.5155 3.65359 16.7485C3.0105 19.9815 3.34056 23.3326 4.60202 26.3781C5.86348 29.4235 7.99968 32.0265 10.7405 33.8578ZM23.5437 14.4202L25.0757 12.8882C25.8081 12.1558 25.8081 10.9692 25.0757 10.2368C24.3432 9.50438 23.1567 9.50438 22.4243 10.2368L21.875 10.7861V9.375C21.875 8.33985 21.0351 7.5 20 7.5C18.9648 7.5 18.125 8.33985 18.125 9.375V10.7861L17.5756 10.2368C16.8432 9.50438 15.6567 9.50438 14.9243 10.2368C14.1918 10.9692 14.1918 12.1558 14.9243 12.8882L16.4562 14.4202C15.2122 15.1967 14.3749 16.572 14.3749 18.1494C14.3749 18.2849 14.3896 18.4204 14.4189 18.5522L16.9482 30.0464C17.2656 31.4917 18.5205 32.5 19.9999 32.5C21.4794 32.5 22.7343 31.4917 23.0517 30.0464L25.581 18.5522C25.6103 18.4204 25.6249 18.2849 25.6249 18.1494C25.6249 16.572 24.7877 15.1967 23.5437 14.4202Z"
          fill="#1875EC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1878">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
