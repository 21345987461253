import React from "react";

export const HomesIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1915)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8886 3.37061C12.1776 1.17298 16.0444 0 20 0C25.3025 0.00606532 30.386 2.11515 34.1354 5.86456C37.8848 9.61397 39.9939 14.6975 40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65492 36.9392 5.85787 34.1421C3.06082 31.3451 1.15601 27.7814 0.384303 23.9018C-0.387401 20.0222 0.0086658 16.0009 1.52242 12.3463C3.03617 8.69181 5.59962 5.56823 8.8886 3.37061ZM10.7405 33.8578C13.4813 35.6892 16.7036 36.6667 20 36.6667C24.4188 36.6617 28.6551 34.9042 31.7797 31.7797C34.9042 28.6551 36.6618 24.4188 36.6667 20C36.6667 16.7036 35.6892 13.4813 33.8578 10.7405C32.0265 7.99968 29.4235 5.86347 26.3781 4.60201C23.3326 3.34055 19.9815 3.01049 16.7485 3.65358C13.5155 4.29666 10.5458 5.88401 8.21489 8.21488C5.88402 10.5458 4.29667 13.5155 3.65359 16.7485C3.0105 19.9815 3.34056 23.3326 4.60202 26.3781C5.86348 29.4235 7.99968 32.0265 10.7405 33.8578ZM22.2325 9.82878L29.2304 15.8056C29.9347 16.4067 30.1825 17.3562 29.8615 18.2251C29.5417 19.094 28.7361 19.6554 27.8096 19.6554H27.5093V25.5633C27.5093 27.458 25.9677 29 24.072 29H15.9341C14.0385 29 12.4968 27.458 12.4968 25.5633V19.6554H12.1904C11.2639 19.6554 10.4583 19.094 10.1385 18.2251C9.81749 17.3562 10.0653 16.4067 10.7696 15.8056L17.7675 9.82878C19.0613 8.72313 20.9386 8.72435 22.2325 9.82878ZM17.5 26.8125H22.5V21.8125C22.5 21.1221 21.9404 20.5625 21.25 20.5625H18.75C18.0596 20.5625 17.5 21.1221 17.5 21.8125V26.8125Z"
          fill="#1875EC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1915">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
