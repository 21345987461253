"use client";

import { useI18nContext } from "@hopper-b2b/i18n";
import { useScript } from "@hopper-b2b/utilities";
import { Box, Link, Stack, Typography } from "@mui/material";
import { AtHotelLogo } from "../../../assets";

const ATHOTEL_PHONE_NUMBER = "+1 (800) 416-0983";
const ATHOTEL_EMAIL = "support@athotel.com";

export const AtHotelFooter = () => {
  const { t } = useI18nContext();
  const scriptEl = useScript({
    src: "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
    onLoad: () => void 0,
    defer: true,
  });

  return (
    <Stack
      px={({ tokens }) => tokens.container.padding}
      py={0}
      alignItems="center"
      alignSelf="stretch"
    >
      <Stack
        alignSelf="stretch"
        width="100%"
        gap={100}
        paddingTop={{
          mobile: 300,
          tablet: 600,
        }}
        paddingBottom={{
          mobile: 300,
          tablet: 850,
        }}
      >
        <Stack
          direction={{
            tablet: "column",
            desktop: "row",
          }}
          minHeight="150px"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={{
            mobile: 600,
            desktop: 0,
          }}
        >
          <Stack>
            <Box
              component={AtHotelLogo}
              sx={{
                width: {
                  mobile: "101px",
                  desktop: "130px",
                },
                height: {
                  mobile: "28px",
                  desktop: "36px",
                },
              }}
            />
          </Stack>
          <Stack gap={100} alignItems="flex-start">
            <Typography variant="titleSmall" pb={200}>
              {t("athotel.footer.aboutHeader")}
            </Typography>

            <Link
              variant="bodyMedium"
              color={({ tokens }) => tokens.text.secondary}
              href={"https://athotel.com/about"}
              target="_blank"
            >
              {t("athotel.footer.about")}
            </Link>
            <Link
              variant="bodyMedium"
              color={({ tokens }) => tokens.text.secondary}
              href={"https://athotel.com/faq"}
              target="_blank"
            >
              {t("athotel.footer.faq")}
            </Link>
          </Stack>
          <Stack>
            <Typography variant="titleSmall" pb={200}>
              {t("athotel.footer.contactUs")}
            </Typography>

            <Link
              variant="bodyMedium"
              color={({ tokens }) => tokens.text.secondary}
              href={`mailto:${ATHOTEL_EMAIL}`}
            >
              {ATHOTEL_EMAIL}
            </Link>
            <Link
              variant="bodyMedium"
              color={({ tokens }) => tokens.text.secondary}
              href={`tel:+${ATHOTEL_PHONE_NUMBER.replace(/\D/g, "")}`}
            >
              {ATHOTEL_PHONE_NUMBER}
            </Link>
          </Stack>
          {scriptEl}
          <Box
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="63db854a683a659a5db03ae8"
            data-theme="light"
            data-tags="W"
            data-stars="1,2,3,4,5"
            data-review-languages="en"
          >
            <a
              href="https://www.trustpilot.com/review/athotel.com?utm_medium=trustbox&utm_source=Mini"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Trustpilot"}
            </a>
          </Box>
        </Stack>
        <Stack
          direction={{ tablet: "column", desktop: "row" }}
          justifyContent={{ desktop: "center", tablet: "flex-start" }}
          gap={50}
        >
          <Stack>
            <Typography variant="bodySmall">
              {t("athotel.footer.copyright")}
            </Typography>
          </Stack>

          <Stack direction="row" gap={50}>
            <Link
              href={"https://athotel.com/privacy-policy"}
              variant="bodySmall"
              color={({ tokens }) => tokens.text.primary}
              target="_blank"
            >
              {t("athotel.footer.privacyPolicy")}
            </Link>
            <Typography variant="bodySmall">{"-"}</Typography>
            <Link
              href={"https://athotel.com/terms"}
              variant="bodySmall"
              color={({ tokens }) => tokens.text.primary}
              target="_blank"
            >
              {t("athotel.footer.termsOfUse")}
            </Link>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent={{ desktop: "center", tablet: "flex-start" }}
          gap={50}
        >
          <Typography variant="bodySmall">
            {t("athotel.footer.companyAddress")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
