import { URL_PARAM_KEYS } from "./constants";
import { default as dayjs, Dayjs } from "dayjs";

export const URL_DATE_FORMAT = "YYYY-MM-DD" as const;

type BuildSearchParamsArgs = {
  [URL_PARAM_KEYS.FROM_DATE]?: string;
  [URL_PARAM_KEYS.UNTIL_DATE]?: string;
  [URL_PARAM_KEYS.ADULTS_COUNT]?: number;
  [URL_PARAM_KEYS.CHILDREN_COUNT]?: number[];
  [URL_PARAM_KEYS.ROOMS_COUNT]?: number;
  [URL_PARAM_KEYS.PET_FRIENDLY]?: number;
  [URL_PARAM_KEYS.STAY_TYPES]?: string[];
  [URL_PARAM_KEYS.PLACE_ID]?: string;
  [URL_PARAM_KEYS.QUERY]?: string;
  enableRooms?: boolean;
};

function defined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const buildSearchParams = ({
  fromDate,
  untilDate,
  adultsCount = 0,
  childrenCount = [],
  roomsCount = 0,
  enableRooms = false,
  pets = 0,
  stayTypes = [],
  placeId,
  query,
}: BuildSearchParamsArgs) => {
  const searchParams = new URLSearchParams(
    [
      [URL_PARAM_KEYS.FROM_DATE, formatDateForUrl(fromDate)],
      [URL_PARAM_KEYS.UNTIL_DATE, formatDateForUrl(untilDate)],
      adultsCount > 0 ? [URL_PARAM_KEYS.ADULTS_COUNT, `${adultsCount}`] : null,
      childrenCount?.length > 0
        ? [URL_PARAM_KEYS.CHILDREN_COUNT, `${childrenCount.length}`]
        : null,
      ...(childrenCount?.length > 0
        ? childrenCount.map((age) => [URL_PARAM_KEYS.CHILDREN_AGES, `${age}`])
        : []),
      pets ? [URL_PARAM_KEYS.PET_FRIENDLY, `${pets}`] : null,
      enableRooms && roomsCount > 0
        ? [URL_PARAM_KEYS.ROOMS_COUNT, `${roomsCount}`]
        : null,
      placeId ? [URL_PARAM_KEYS.PLACE_ID, placeId] : null,
      query ? [URL_PARAM_KEYS.QUERY, query] : null,
    ].filter(defined)
  );
  return searchParams.toString();
};

export const formatDateForUrl = (
  dateString?: string | Dayjs
): (string & WithFormat<typeof URL_DATE_FORMAT>) | "" =>
  dateString
    ? (dayjs(dateString).format(URL_DATE_FORMAT) as string &
        WithFormat<typeof URL_DATE_FORMAT>)
    : "";

export const unifyChildrenAges = (
  childrenAges: number[],
  childrenCount: number
): number[] => {
  return childrenCount > 0
    ? new Array(childrenCount)
        .fill(1)
        .map((_, index) =>
          Number.isInteger(childrenAges[index]) && childrenAges[index] >= 0
            ? childrenAges[index]
            : 1
        )
    : [];
};

export interface WithFormat<TFormat extends string> {
  __format: TFormat;
}

function initPriceRange(urlMin: number, urlMax: number, currencyCode: string) {
  const min = isNaN(urlMin) ? 0 : urlMin;
  const max = isNaN(urlMax) || urlMax === 0 ? Infinity : urlMax;
  return {
    min,
    max,
    lowest: 0,
    highest: Infinity,
    currencyCode: currencyCode,
  };
}

const VALID_SORT_OPTIONS = [
  "starRating",
  "userRating",
  "price",
  "mostRecommended",
] as const;

export type SortOption = typeof VALID_SORT_OPTIONS[number];

export function isValidSort(sort: unknown): sort is SortOption {
  return VALID_SORT_OPTIONS.includes(sort as SortOption);
}

export const parseLodgingParams = (
  searchParams: URLSearchParams
): {
  adults: number;
  children: number[];
  rooms: number;
  fromDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  untilDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  map:
    | {
        centroid: { lat: number; lng: number };
        zoom: number;
        autocompleteLabel: string;
        autocompleteSubLabel: string;
      }
    | undefined;
  lodgingIds: string[];
  filters: {
    starRating: number[];
    userRating: number;
    amenities: string[];
    filterAmenitiesAnd: boolean;
    freeCancellation: boolean;
    priceRange: {
      min: number;
      max: number;
      lowest: number;
      highest: number;
      currencyCode: string;
    };
    meals: boolean;
    stayTypes: string[];
  };
  sortBy: SortOption;
  pets: number;
  crossSellVoucher: {
    discountPercentage: number;
    maxDiscountAmount: number;
  };
} => {
  const count = Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT)) || 0;
  const ages = searchParams.getAll(URL_PARAM_KEYS.CHILDREN_AGES).map(Number);
  const children = unifyChildrenAges(ages, count);
  const latlng =
    searchParams
      .get(URL_PARAM_KEYS.LAT_LNG)
      ?.split(",")
      .map(Number)
      .filter((_) => !Number.isNaN(_)) || [];

  const filters = {
    starRating:
      searchParams.get(URL_PARAM_KEYS.STAR_RATING)?.split(",").map(Number) ||
      [],
    userRating: Number(searchParams.get(URL_PARAM_KEYS.USER_RATING)) || 0,
    amenities: searchParams.get(URL_PARAM_KEYS.AMENITIES)?.split(",") || [],
    filterAmenitiesAnd:
      searchParams.get(URL_PARAM_KEYS.FILTER_AMENITIES_AND) === null,
    freeCancellation:
      searchParams.get(URL_PARAM_KEYS.FREE_CANCELLATION) !== null,
    priceRange: initPriceRange(
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MIN)),
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MAX)),
      searchParams.get(URL_PARAM_KEYS.CURRENCY_CODE)
    ),
    stayTypes: searchParams.get(URL_PARAM_KEYS.STAY_TYPES)?.split(",") || [],
    meals: false,
  };

  const sortBy = searchParams.get(URL_PARAM_KEYS.SORT_BY);

  const pets =
    Number(searchParams.get(URL_PARAM_KEYS.PET_FRIENDLY)) == 1 ? 1 : 0 || 0;

  return {
    adults: Number(searchParams.get(URL_PARAM_KEYS.ADULTS_COUNT)) || 2,
    children,
    rooms: Number(searchParams.get(URL_PARAM_KEYS.ROOMS_COUNT)) || 1,
    fromDate: formatDateForUrl(searchParams.get(URL_PARAM_KEYS.FROM_DATE)),
    untilDate: formatDateForUrl(searchParams.get(URL_PARAM_KEYS.UNTIL_DATE)),
    map:
      latlng.length === 2 || latlng.length === 3
        ? {
            centroid: {
              lat: latlng[0] || 0,
              lng: latlng[1] || 0,
            },
            zoom: Math.round(latlng[2] || 13),
            autocompleteLabel: searchParams.get(
              URL_PARAM_KEYS.AUTOCOMPLETE_LABEL
            ),
            autocompleteSubLabel: searchParams.get(
              URL_PARAM_KEYS.AUTOCOMPLETE_SUBLABEL
            ),
          }
        : undefined,
    lodgingIds:
      searchParams
        .get(URL_PARAM_KEYS.LODGING_IDS)
        ?.split(",")
        .filter(Boolean) || [],
    filters,
    sortBy: isValidSort(sortBy) ? sortBy : "mostRecommended",
    pets: pets || 0,
    crossSellVoucher: {
      discountPercentage: Number(
        searchParams.get(URL_PARAM_KEYS.DISCOUNT_PERCENTAGE)
      ),
      maxDiscountAmount: Number(
        searchParams.get(URL_PARAM_KEYS.MAX_DISCOUNT_AMOUNT)
      ),
    },
  };
};
